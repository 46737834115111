import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {}
const actions = {
  searchOrder: ({ commit }, { payload, params, success, failed }) => {
    Vue.http
      .post(`${config.api.order}/search` + serializeQueryParams(params), payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getOrderByOrderId: ({ commit }, { orderId, success, failed }) => {
    Vue.http
      .get(`${config.api.order}/${orderId}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getUserByUserId: ({ commit }, { userId, success, failed }) => {
    Vue.http
      .get(`${config.api.user.user}/${userId}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getClientList: ({ commit }, { success, failed }) => {
    Vue.http
      .get(config.api.client, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}
const mutations = {}
const getters = {}

export default {
  state,
  getters,
  actions,
  mutations
}
