<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.60146 22.5C3.28265 22.4985 2.97744 22.3707 2.75275 22.1445C2.52806 21.9183 2.40224 21.6123 2.40286 21.2934C2.40565 21.1723 2.42457 21.052 2.45911 20.9358L3.32255 17.8125C3.35864 17.6972 3.31786 17.5809 3.26489 17.4891L3.25458 17.4703C3.25083 17.4642 3.23302 17.4394 3.22036 17.4211C3.20771 17.4028 3.19318 17.3831 3.18005 17.3639L3.17114 17.3513C2.0809 15.6988 1.49984 13.7627 1.50005 11.783C1.49161 9.08344 2.54771 6.53297 4.47474 4.59797C6.4688 2.60016 9.13599 1.5 11.9916 1.5C14.4141 1.4988 16.7648 2.3228 18.6563 3.83625C20.5055 5.32453 21.7908 7.39875 22.2755 9.68063C22.4247 10.3738 22.4998 11.0809 22.4996 11.79C22.4996 14.5523 21.4383 17.1469 19.5108 19.0964C17.5665 21.0652 14.9555 22.1461 12.1594 22.1461C11.175 22.1461 9.91552 21.8948 9.31083 21.7242C8.58333 21.5208 7.8938 21.2555 7.81786 21.2273C7.7375 21.1966 7.6522 21.1807 7.56614 21.1805C7.47281 21.1799 7.38033 21.1982 7.29427 21.2344L7.25442 21.2498L4.0913 22.3927C3.93594 22.4581 3.76992 22.4944 3.60146 22.5Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
