import Vue from 'vue'

const converter = {
  date: when => {
    return Vue.prototype.moment(when).format('YYYY-MM-DD')
  },
  datePlusDay: (when, days) => {
    return Vue.prototype
      .moment(when)
      .add(days, 'days')
      .format('YYYY-MM-DD')
  },
  dateMinusDay: (when, days) => {
    return Vue.prototype
      .moment(when)
      .subtract(days, 'days')
      .format('YYYY-MM-DD')
  },
  dateTimeRequest: when => {
    return Vue.prototype.moment(when).format('YYYY-MM-DD HH:mm:ss')
  },
  dateTimeRequestModelT: when => {
    return Vue.prototype.moment(when).format('YYYY-MM-DDTHH:mm:ss.SSS')
  },
  dateTimeRequestModelTPlus7: when => {
    return Vue.prototype.moment(when).format('YYYY-MM-DDTHH:mm:ss+0700')
  },
  dateTimeView: when => {
    return Vue.prototype.moment(when).format('DD MMM yyyy HH:mm')
  },
  rupiahCurrency: amount => {
    return 'Rp ' + amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') + ',-'
  },
  time: when => {
    return Vue.prototype.moment(when).format('HH:mm')
  }
}

export default converter
