import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {}
const actions = {
  reIndex: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(config.api.seoReindexing(payload.projectId) + serializeQueryParams(payload.params), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getSeoList: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .get(config.api.seo + serializeQueryParams(payload.params), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getSeoById: ({ commit }, { id, success, failed }) => {
    Vue.http
      .get(`${config.api.seo}/${id}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  putSeo: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .put(`${config.api.seo}/${payload.id}`, payload.data, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  deleteSeo: ({ commit }, { id, success, failed }) => {
    Vue.http
      .delete(`${config.api.seo}/${id}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  postSeo: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(config.api.seo, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}

const mutations = {}
const getters = {}

export default {
  state,
  getters,
  actions,
  mutations
}
