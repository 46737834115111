const validator = {
  email: text => {
    let regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(text) ? false : true
  },
  phone: number => {
    let regex = /^(\+62|62|0)8[1-9][0-9]{6,11}$/
    return regex.test(number) ? false : true
  }
}

export default validator
