import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
// import { serializeQueryParams } from '@/util/query'
Vue.use && Vue.use(VueResource)

const state = {
  testimonyList: [],
  testimonyDetail: {}
}
const actions = {
  getTestimonyList: ({ commit }, { success }) => {
    Vue.http.get(`${config.api.testimony}/all`).then(response => {
      success && success(response.body.data)
      commit('setTestimonyList', response.body.data)
    })
  },
  createTestimony: ({ commit }, { payload, success }) => {
    Vue.http
      .post(config.api.testimony, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  },
  getTestimonyDetail: ({ commit }, { id, success }) => {
    Vue.http
      .get(config.api.testimony + '/' + id, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setTestimonyDetail', response.body.data)
      })
  },
  editTestimony: ({ commit }, { id, payload, success }) => {
    Vue.http
      .put(config.api.testimony + '/' + id, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  },
  deleteTestimony: ({ commit }, { id, success }) => {
    Vue.http
      .delete(config.api.testimony + '/' + id, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  }
}
const mutations = {
  setTestimonyList(state, value) {
    state.testimonyList = value
  },
  setTestimonyDetail(state, value) {
    state.testimonyDetail = value
  }
}
const getters = {
  testimonyList: state => {
    return state.testimonyList
  },
  testimonyDetail: state => {
    return state.testimonyDetail
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
