import VueSingleSelect from 'vue-single-select'
import ImageUploadMixin from '@/mixins/image-upload'

export default {
  name: 'scholarship-partner',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    statusOptions: ['PUBLISH', 'NONACTIVE'],
    buttonOptions: ['PRIMARY', 'SECONDARY'],
    iconOptions: [
      'Registrasi',
      'Orientasi',
      'Prabootcamp',
      'Bootcamp',
      'Job Placement'
    ],
    descriptionMaxLength: 3000,
    prasyaratMaxLength: 3000,
    aboutMaxLength: 3000
  }),
  computed: {
    sequenceOptions() {
      return Array.from({ length: this.payload.items.length }, (_, i) => i + 1)
    }
  },
  methods: {
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item, number, idx) {
      this.$refs['mitraUrl' + number + idx][0].click()
    },
    addMoreBanner() {
      const newItem = {
        seqNo: 0,
        title: '',
        name: '',
        code: 'partner',
        url: '',
        description: '',
        prasyarat: '',
        tentangmitra: '',
        url2: '',
        statusId: 'PUBLISH',
        buttonCta: '',
        buttonType: '',
        lokasiPelatihan: '',
        lokasiProyek: '',
        waktu: '',
        registrationProcesses: [
          {
            icon: 'Registrasi',
            text: ''
          }
        ]
      }
      this.payload.items.push(newItem)
    },
    deleteBanner(idx) {
      this.payload.items.splice(idx, 1)
    },
    addMoreProcess(idx) {
      const item = {
        icon: 'Registrasi',
        text: ''
      }
      this.payload.items[idx].registrationProcesses.push(item)
    },
    deleteProcess(idx, indexProcess) {
      this.payload.items[idx].registrationProcesses.splice(indexProcess, 1)
    }
  }
}
