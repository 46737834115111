import { isNotEmptyError } from '@/util/form-validator'
import { mapGetters, mapActions } from 'vuex'
const CONFIG = {
  email: 'Email',
  password: 'Password'
}
export default {
  name: 'login',
  data() {
    return {
      payload: {
        email: null,
        password: null
      },
      errors: [],
      generalError: null,
      typeInput: 'password',
      isTyping: false
    }
  },
  mounted() {
    this.hideLoading()
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userData']),
    inputPass() {
      return this.payload.password
    }
  },
  watch: {
    inputPass: function(val) {
      val.length > 0 ? (this.isTyping = true) : (this.isTyping = false)
    }
  },
  methods: {
    ...mapActions(['login', 'showLoading', 'hideLoading']),
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    userIsTyping(e) {
      if (e?.target?.value?.length > 0) {
        this.isTyping = true
      }
      this.isTyping = false
    },
    doLogin() {
      if (this.validForm()) {
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!regex.test(this.payload.email)) {
          this.errors['email'] = 'Your email format is invalid!'
        } else {
          const requestBody = {
            email: this.payload.email,
            password: this.payload.password
          }
          this.showLoading()
          this.login({
            reqBody: requestBody,
            success: async res => {
              const checkRoles = new Promise(resolve => {
                res.roles.includes('CMS_ADMIN') ? resolve(true) : resolve(false)
              })
              if ((await checkRoles) === false) return (this.generalError = "You don't have permission to access the site! Please contact admin.")

              let team = (await res.team) ? res.team : 'Anonymous'
              this.generalError = await null
              await localStorage.setItem('auth_id', 'nothing')
              await localStorage.setItem('access_token', res.token)
              await localStorage.setItem('user_name', res.name)
              await localStorage.setItem('user_email', res.email)
              await localStorage.setItem('user_role', res.roles)
              await localStorage.setItem('user_team', team)
              await localStorage.setItem('user_id', res.id)
              await localStorage.setItem('user', JSON.stringify(res))
              this.hideLoading()
              this.$router.push('/')
            },
            failed: res => {
              this.hideLoading()
              this.generalError = res.errors.error[0]
            }
          })
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payload)
      this.errors = error.reduce(function(map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
