<template>
  <div class="h-5 px-6 text-sm flex justify-center">Copyright © {{ getYear() }}. PT Generasi Teknologi Buana</div>
</template>
<script>
export default {
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
