import VueSingleSelect from 'vue-single-select'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
const ClassDescription = () => import(/* webpackChunkName: "ClassDescription" */ '@/components/ClassDescription')
const CurrencyInput = () => import(/* webpackChunkName: "CurrencyInput" */ '@/components/forms/CurrencyInput')
const ClassSyllabus = () => import(/* webpackChunkName: "ClassSyllabus" */ '@/components/ClassSyllabus')
export default {
  name: 'create-class',
  components: {
    VueSingleSelect,
    ClassDescription,
    CurrencyInput,
    ClassSyllabus,
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown')
  },
  data: () => ({
    maxResults: 100,
    classType: '',
    layoutOptions: [
      {
        name: 'Event',
        value: 'EVENT_LAYOUT'
      },
      {
        name: 'Activity',
        value: 'CLASS_LAYOUT'
      }
    ],
    payload: {
      name: '',
      location: '',
      type: '',
      price: '',
      slug: '',
      schedule: '',
      scheduleFrom: '',
      scheduleTo: '',
      durationText: '',
      shortDescriptionText: '',
      techStacksText: '',
      benefitText: '',
      categoryText: '',
      layoutType: ''
    },
    descriptionPayload: {
      classValue: '',
      description: '',
      prasyarat: '',
      note: '',
      url1: '',
      url2: '',
      knowledgeCompetence: '',
      skillCompetence: '',
      attitudeCompetence: '',
      targetParticipant: '',
      purpose: '',
      capacity: '',
      learningMethod: '',
      certificateClarification: '',
      activity: ''
    },
    syllabusDocument: '',
    syllabusPayload: [],
    historyAlumniClasses: [],
    scheduledPrices: [],
    instructorClasses: [],
    customerTypeSelected: {},
    promoPriceValue: false,
    promoPriceTime: false,
    promoPriceDate: false,
    customerType: []
  }),
  computed: {
    ...mapGetters(['instructorList', 'testimonyList', 'programs']),
    classTypeSelected() {
      return this.payload?.type?.code
    }
  },
  watch: {
    scheduledPrices: {
      handler: function () {
        if (this.scheduledPrices.length > 0) {
          this.scheduledPrices.forEach(item => {
            if (item.withTime && (item.startDate === '' || item.endDate === '')) {
              this.promoPriceTime = true
            } else {
              this.promoPriceTime = false
            }
            if ((item.price && !this.payload.price) || item.price >= this.payload.price) {
              this.promoPriceValue = true
            } else {
              this.promoPriceValue = false
            }
            if (moment(item.startDate).isBefore(new Date()) || moment(item.startDate).isAfter(item.endDate)) {
              this.promoPriceDate = true
            } else {
              this.promoPriceDate = false
            }
          })
        } else {
          this.promoPriceTime = false
          this.promoPriceValue = false
          this.promoPriceDate = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getInstructor()
    this.getTestimony()
    this.getProgramList()
    this.getCustomerTypeList()
  },
  methods: {
    ...mapActions(['getInstructorList', 'getTestimonyList', 'createClass', 'getPrograms', 'showLoading', 'hideLoading', 'getCustomerType']),
    minimalstartDate(index) {
      if (index === 0) {
        return moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD')
      } else {
        return moment(this.scheduledPrices[index - 1].endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    forDisableStartDate(index) {
      return index === 0 ? false : this.scheduledPrices[index - 1].endDate === ''
    },
    getProgramList() {
      this.getPrograms({
        success: () => { }
      })
    },
    getInstructor() {
      this.getInstructorList({
        success: () => { }
      })
    },
    getTestimony() {
      this.getTestimonyList({
        success: () => { }
      })
    },
    getCustomerTypeList() {
      this.getCustomerType({
        success: res => {
          res.body.data.map(customer => {
            this.customerType.push(customer)
          })
        }
      })
    },
    addMoreItem(objContent, type) {
      const obj = objContent
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      let item = {}
      if (type === 'prices') {
        item = {
          coreClassId: this.classId,
          price: 0,
          startDate: '',
          endDate: '',
          active: true,
          withTime: false
        }
      } else {
        item = {
          seqNo: lastObj.seqNo + 1
        }
        item[type] = ''
      }
      obj.push(item)
    },
    deleteItem(objContent, idx) {
      objContent.splice(idx, 1)
    },
    changeCheck(idx) {
      this.scheduledPrices[idx].withTime = !this.scheduledPrices[idx].withTime
      if (this.scheduledPrices[idx].withTime === false) {
        this.scheduledPrices[idx].startDate = ''
        this.scheduledPrices[idx].endDate = ''
      }
    },
    goBack() {
      this.$router.push('/daftar-kelas')
    },
    save() {
      this.showLoading()
      if (this.promoPriceTime) {
        this.$toast.error('Please fill the required field!', { position: 'top' })
      } else if (this.promoPriceValue) {
        this.$toast.error('Promo price higher or equal than listing price!', { position: 'top' })
      } else if (this.promoPriceDate) {
        this.$toast.error('Please set the date of promo price correctly!', { position: 'top' })
      } else {
        const requestBody = this.constructPayload()
        this.createClass({
          requestBody: requestBody,
          success: res => {
            if (res.code === 200) {
              this.$toast.success('Class is created successfully!', { position: 'top' })
              this.goBack()
            } else {
              this.$toast.error('Please fill the required field!', { position: 'top' })
            }
          }
        })
      }
      setTimeout(() => this.hideLoading(), 3500)
    },
    constructPayload() {
      let testimoni = JSON.parse(JSON.stringify(this.historyAlumniClasses))
      testimoni.map(function (item) {
        item.testimonyId = item.testimonyId.id
        return item
      })
      let prices = JSON.parse(JSON.stringify(this.scheduledPrices))
      prices.map(function (item) {
        item.startDate = item.startDate !== '' ? moment(item.startDate + ' ' + '00:00:00').format('YYYY-MM-DDTHH:mm:ss+0700') : ''
        item.endDate = item.endDate !== '' ? moment(item.endDate + ' ' + '23:59:59').format('YYYY-MM-DDTHH:mm:ss+0700') : ''
        delete item.withTime
        return item
      })
      let instructor = JSON.parse(JSON.stringify(this.instructorClasses))
      instructor.map(function (item) {
        item.expertId = item.expertId.id
        return item
      })
      const formattedSlug = this.payload.slug.toLowerCase().trim()

      const constructedPayload = {
        customerId: this.customerTypeSelected.id,
        classValue: this.descriptionPayload.classValue,
        description: this.descriptionPayload.description,
        historyAlumniClasses: testimoni,
        scheduledPrices: prices,
        instructorClasses: instructor,
        name: this.payload.name,
        note: this.descriptionPayload.note,
        prasyarat: this.descriptionPayload.prasyarat,
        price: parseInt(this.payload.price),
        schedule: this.payload.schedule,
        scheduleFrom: this.payload.scheduleFrom,
        scheduleTo: this.payload.scheduleTo,
        location: this.payload.location,
        type: this.payload.type.code,
        layoutType: this.payload.layoutType.value,
        seqNo: 0,
        slug: formattedSlug,
        durationText: this.payload.durationText,
        shortDescriptionText: this.payload.shortDescriptionText,
        techStacksText: this.payload.techStacksText,
        benefitText: this.payload.benefitText,
        categoryText: this.payload.categoryText,
        syllabus: this.syllabusDocument,
        sylabusClasses: this.syllabusPayload,
        url1: this.descriptionPayload.url1,
        url2: this.descriptionPayload.url2,
        knowledgeCompetence: this.descriptionPayload.knowledgeCompetence,
        skillCompetence: this.descriptionPayload.skillCompetence,
        attitudeCompetence: this.descriptionPayload.attitudeCompetence,
        targetParticipant: this.descriptionPayload.targetParticipant,
        purpose: this.descriptionPayload.purpose,
        capacity: this.descriptionPayload.capacity,
        learningMethod: this.descriptionPayload.learningMethod,
        certificateClarification: this.descriptionPayload.certificateClarification,
        activity: this.descriptionPayload.activity,
        layoutType : this.payload.layoutType.value

      }
      return constructedPayload
    }
  }
}
