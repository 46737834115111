<template>
  <div class="w-64 bg-neutral-500 text-white overflow-y-auto">
    <div v-for="(nav, index) in navItems" :key="'nav-' + index">
      <router-link v-if="!nav.children" :to="nav.url" class="flex items-center gap-2 transition-all duration-150 py-3 px-4 hover:bg-yellow hover:text-white hover:no-underline">
        <component :is="nav.icon" />
        <span class="font-medium text-base">{{ nav.name }}</span>
      </router-link>
      <template v-else>
        <template v-if="roleValidation(nav)">
          <input type="checkbox" :id="'checkbox' + index" v-model="nav.active" class="hidden" />
          <label :for="'checkbox' + index" class="m-0 cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-4 hover:bg-yellow">
            <div class="flex items-center gap-2">
              <component :is="nav.icon" />
              <span class="font-medium text-base">{{ nav.name }} </span>
            </div>
            <Chevron :direction="nav.active ? 'up' : 'down'" width="12" color="white" />
          </label>
          <div class="bg-neutral" v-if="nav.active">
            <router-link
              :to="childNav.url"
              v-for="(childNav, idx) in nav.children"
              :key="'child-nav-' + idx"
              class="pl-10 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow hover:text-white hover:no-underline"
            >
              <component :is="childNav.icon" />
              <span class="font-medium text-sm">{{ childNav.name }}</span>
            </router-link>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Chevron')
  },
  data() {
    return {
      navItems: [
        {
          name: 'Homepage',
          icon: () => import('@/components/icons/Home'),
          active: false,
          roles: [],
          url: '/homepage'
        },
        {
          name: 'Marketing Center',
          icon: () => import('@/components/icons/Mail'),
          active: false,
          id: 'prompt',
          roles: ['MKT_ADMIN', 'DATA_USER'],
          children: [
            {
              name: 'Pop Up',
              url: '/homepage-popup',
              icon: () => import('@/components/icons/ChatBubble')
            },
            {
              name: 'SEO',
              url: '/seo',
              icon: () => import('@/components/icons/Seo')
            },
            {
              name: 'Campaign',
              url: '/campaign',
              icon: () => import('@/components/icons/Campaign')
            }
          ]
        },
        {
          name: 'Clients',
          icon: () => import('@/components/icons/Business'),
          active: false,
          id: 'clients',
          roles: [],
          children: [
            {
              name: 'Active',
              url: '/active-clients',
              icon: () => import('@/components/icons/Active')
            },
            {
              name: 'Archived',
              url: '/archived-clients',
              icon: () => import('@/components/icons/Archive')
            }
          ]
        },
        {
          name: 'Static Page',
          icon: () => import('@/components/icons/Globe'),
          active: false,
          id: 'static-page',
          roles: [],
          children: [
            {
              name: 'Beasiswa',
              url: '/beasiswa',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'Pemerintah',
              url: '/pemerintah',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'Perusahaan',
              url: '/perusahaan',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'Prakerja',
              url: '/prakerja',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'Eduverse',
              url: '/marketplace',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'Short Course',
              url: '/short-course',
              icon: () => import('@/components/icons/Reader')
            },
            {
              name: 'All Programs',
              url: '/all-programs',
              icon: () => import('@/components/icons/Reader')
            }
          ]
        },
        {
          name: 'Class Page',
          icon: () => import('@/components/icons/Student'),
          active: false,
          id: 'static-page',
          roles: [],
          children: [
            {
              name: 'Daftar Kelas',
              url: '/daftar-kelas',
              icon: () => import('@/components/icons/Book')
            },
            {
              name: 'Daftar Instruktur',
              url: '/daftar-instruktur',
              icon: () => import('@/components/icons/Person')
            },
            {
              name: 'Daftar Testimoni',
              url: '/daftar-testimoni',
              icon: () => import('@/components/icons/People')
            }
          ]
        },
        {
          name: 'Order',
          icon: () => import('@/components/icons/Cart'),
          active: false,
          id: 'order',
          roles: [],
          children: [
            {
              name: 'All Order',
              url: '/all-order',
              icon: () => import('@/components/icons/BagAdd')
            }
          ]
        },
        {
          name: 'Marketplace',
          icon: () => import('@/components/icons/BagMarketplace'),
          active: false,
          id: 'marketplace',
          roles: [],
          children: [
            {
              name: 'All Marketplace',
              url: '/all-marketplace',
              icon: () => import('@/components/icons/BagAdd')
            }
          ]
        }
      ]
    }
  },
  created() {
    let self = this
    this.navItems.forEach(item => {
      if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].url === self.$route.path || self.$route.path.includes(item.children[i].url)) {
            item.active = true
          }
        }
      }
    })
  },
  computed: {
    ...mapGetters(['userData']),
    customerRoles() {
      return this.userData.user.customerRoles[0].roles
    }
  },
  watch: {
    $route: function() {
      let self = this
      this.navItems.forEach(item => {
        if (item.children) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].url === self.$route.path || self.$route.path.includes(item.children[i].url)) {
              item.active = true
            }
          }
        }
      })
    }
  },
  methods: {
    roleValidation(nav) {
      let valid = false
      if (nav.roles.length < 1) {
        valid = true
      } else {
        nav.roles.forEach(item => {
          if (this.customerRoles.includes(item)) {
            valid = true
          }
        })
      }
      return valid
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.router-link-active,
.active {
  background-color: #f7931e;
  box-shadow: inset 0px 10px 2px -10px #000, inset 0px -10px 0px -10px #000;
}
</style>
