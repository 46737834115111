import { mapGetters, mapActions } from 'vuex'
const PageHeader = () =>
  import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'instructor',
  components: {
    PageHeader
  },
  data: () => ({
    keyword: '',
    searchKeyword: ''
  }),
  mounted() {
    this.getInstructor()
  },
  computed: {
    ...mapGetters(['instructorList']),
    allInstructors() {
      let temp = this.instructorList
      if (this.keyword !== '' && this.keyword) {
        temp = temp.filter(item => {
          return item.name.toUpperCase().includes(this.keyword.toUpperCase())
        })
      }
      return temp
    }
  },
  methods: {
    ...mapActions([
      'getInstructorList',
      'deleteInstructor',
      'showLoading',
      'hideLoading'
    ]),
    getInstructor() {
      this.showLoading()
      this.getInstructorList({
        success: res => {
          this.hideLoading()
        }
      })
    },
    search() {
      this.keyword = this.searchKeyword
    },
    edit(id) {
      this.$router.push('/daftar-instruktur/edit/' + id)
    },
    deleteIntructorById(id) {
      this.$confirm({
        message: `Are you sure want to delete this instructor?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.deleteInstructor({
              id: id,
              success: () => {
                this.getInstructor()
              }
            })
          }
        }
      })
    }
  }
}
