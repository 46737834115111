<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3.35C2 2.60442 2.60442 2 3.35 2H20.65C21.3956 2 22 2.60442 22 3.35V4.15C22 4.89558 21.3956 5.5 20.65 5.5H3.35C2.60442 5.5 2 4.89558 2 4.15V3.35Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 7C4.22386 7 4 7.22386 4 7.5V19.5C4 20.6046 4.89543 21.5 6 21.5H18C19.1046 21.5 20 20.6046 20 19.5V7.5C20 7.22386 19.7761 7 19.5 7H4.5ZM12.1451 9.77305C11.738 9.77957 11.4077 10.1149 11.4072 10.522L11.4022 15.2473L9.604 13.4572C9.30698 13.1615 8.83066 13.1667 8.54011 13.4687C8.24955 13.7707 8.25479 14.2553 8.55181 14.5509L11.5288 17.5145C11.5391 17.5257 11.5505 17.536 11.5616 17.5467C11.8575 17.8305 12.3315 17.8248 12.6203 17.5338L15.6232 14.509C15.9121 14.218 15.9064 13.752 15.6105 13.4682C15.3147 13.1844 14.8407 13.1902 14.5518 13.4811L12.8763 15.1689L12.8813 10.4984C12.8817 10.0913 12.5521 9.76653 12.1451 9.77305Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
