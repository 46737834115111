import { render, staticRenderFns } from "./CampaignForm.vue?vue&type=template&id=005cb6fa&scoped=true&"
import script from "./CampaignForm.vue?vue&type=script&lang=js&"
export * from "./CampaignForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005cb6fa",
  null
  
)

export default component.exports