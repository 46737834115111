import { mapActions } from 'vuex'

const GroupingClass = () => import(/* webpackChunkName: "grouping-class" */ '@/components/GroupingClass')
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'short-course',
  components: {
    GroupingClass,
    PageHeader
  },
  data: () => ({
    programName: 'Short Course',
    classGroups: [],
    classList: [],
    isEditContent: true,
    isFetching: false,
    page: 0,
    size: 100
  }),
  mounted() {
    this.getContent()
  },

  methods: {
    ...mapActions(['createProgramContent', 'editProgramContent', 'homePageProgram', 'getClassList', 'showLoading', 'hideLoading']),
    getContent() {
      this.showLoading()
      const params = {
        page: 0,
        size: 100
      }

      this.homePageProgram({
        programName: this.programName,
        params,
        success: res => {
          if (res.content.length > 0) {
            res.content.map((classItem, index) => {
              this.classGroups.push(classItem)
              this.classGroups[index].classes.map((item, itemIdx) => {
                delete this.classGroups[index].classes[itemIdx].imageUrl
                delete this.classGroups[index].classes[itemIdx].slug
              })
            })
          } else {
            this.isEditContent = false
          }
          this.hideLoading()
        },
        failed: () => { }
      })
    },
    selectedClass(classItem, classGroupsIndex, classIndex) {
      this.classGroups[classGroupsIndex].classes[classIndex] = classItem
    },
    removeDuplicate(index) {
      const activeClass = this.classGroups[index].classes
      const uniqueItem = Array.from(new Set(activeClass.map(a => a.id))).map(id => {
        return activeClass.find(a => a.id === id)
      })
      this.classGroups[index].classes = []
      this.classGroups[index].classes = uniqueItem
    },
    searchClass(keyword, classGroupsIndex, classIndex) {
      this.classList = []
      this.getClasses(keyword)
      this.classGroups[classGroupsIndex].classes[classIndex].optionVisible = true
    },
    mouseLeave(classGroupsIndex, classIndex) {
      this.classGroups[classGroupsIndex].classes[classIndex].optionVisible = false
      if (this.classGroups[classGroupsIndex].classes[classIndex].id === null) {
        this.classGroups[classGroupsIndex].classes[classIndex].name = ''
      }
    },
    getClasses(name) {
      this.isFetching = true
      this.getClassList({
        params: {
          page: this.page,
          size: this.size,
          program: this.programName,
          name
        },
        success: res => {
          this.classList = res.data
          this.isFetching = false
        }
      })
    },

    addNewGroup() {
      if (this.checkTitleIsEmpty() && this.classGroups.length !== 0) {
        return this.$toast.success('Title Cant Be Empty !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }

      const item = {
        classes: [],
        seqNo: this.classGroups.length + 1,
        title: ''
      }
      this.classGroups.push(item)
    },

    deleteGroup(index) {
      this.classGroups.splice(index, 1)
    },
    removeDuplicate(index) {
      const activeClass = this.classGroups[index].classes
      const uniqueItem = Array.from(new Set(activeClass.map(a => a.id))).map(id => {
        return activeClass.find(a => a.id === id)
      })
      this.classGroups[index].classes = []
      this.classGroups[index].classes = uniqueItem
    },
    isDuplicate(arrayItem) {
      let valueArr = arrayItem.map(function (item) {
        return item.id
      })
      let isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      })
      return isDuplicate
    },

    checkTitleIsEmpty() {
      let status = []
      this.classGroups.map(item => {
        item.title !== '' ? status.push('false') : status.push('true')
      })
      return status.includes('true')
    },

    checkClassIsEmpty() {
      let status = []
      this.classGroups.map(classes => {
        status.push(this.isEmptyValue(classes.classes))
      })
      return status.includes('true')
    },

    isEmptyValue(arrayItem) {
      let status = 'false'
      if (arrayItem.length > 0) {
        arrayItem.map(item => {
          item.id === null ? (status = 'true') : (status = 'false')
        })
      } else {
        status = 'true'
      }

      return status
    },

    checkClassDuplicate() {
      let status = false
      this.classGroups.map(item => {
        this.isDuplicate(item.classes) ? (status = true) : (status = false)
      })
      return status
    },
    async saveData() {
      if ((await this.classGroups.length) === 0) {
        return this.$toast.success('Data Cant Be Empty !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }

      if (await this.checkClassDuplicate()) {
        this.classGroups.map((item, index) => {
          this.removeDuplicate(index)
        })

        return this.$toast.success('Duplicate Class !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }

      if (await this.checkTitleIsEmpty()) {
        return this.$toast.success('Title Cant Be Empty !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }

      if (await this.checkClassIsEmpty()) {
        return this.$toast.success('Choose Class Correctly!', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }

      this.showLoading()
      const payload = {
        programName: this.programName,
        classGroups: this.classGroups
      }

      this.isEditContent ? this.editContent(payload) : this.saveContent(payload)
    },

    showToast(position, type, text) {
      this.$toast.success(text, {
        position: position,
        type: type,
        queue: true
      })
    },
    saveContent(payload) {
      this.createProgramContent({
        payload,
        success: () => {
          this.isEditContent = true
          this.hideLoading()
          this.$toast.success('Your Short Course Data is Successfully Created!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
        },
        failed: () => {
          this.$toast.success('Failed!', {
            position: 'top',
            type: 'error',
            queue: true
          })
        }
      })
    },

    editContent(payload) {
      this.editProgramContent({
        payload,
        success: () => {
          this.hideLoading()
          this.$toast.success('Your Short Course Data is Successfully Updated!', {
            position: 'top',
            type: 'success',
            queue: true
          })
        },
        failed: () => {
          this.hideLoading()
          this.$toast.success('Failed!', {
            position: 'top',
            type: 'error',
            queue: true
          })
        }
      })
    }
  }
}
