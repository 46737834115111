import { mapActions } from 'vuex'
export default {
  name: 'server-down',
  mounted() {
    this.checking()
  },
  methods: {
    ...mapActions(['checkServerWithResponse']),
    checking() {
      this.checkServerWithResponse({
        success: () => this.$router.push('/login'),
        failed: () => {}
      })
    }
  }
}
