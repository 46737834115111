<template>
  <div class="flex flex-col">
    <label for="input-with-label" class="text-sm pl-2" :class="[inputLabelFocus, inputLabelError]">{{ labelText }} <span v-if="required" class="text-red">*</span></label>
    <input
      name="input-with-label"
      class="h-10 text-sm rounded-md p-2 mt-1 placeholder-neutral-300 border  focus:placeholder-neutral-300 focus:border-yellow focus:outline-none"
      :class="[inputFocus, errorValue ? inputError : 'border-neutral-300', inputDisabled]"
      :type="type"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :maxlength="maxlength"
      :minlength="minlength"
      :autofocus="autofocus"
      :errorValue="errorValue"
      :disabled="disabled"
      :value="value"
      :customValuePlus="customValuePlus"
      :customValueMinus="customValueMinus"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      @keypress.enter="forAction"
      v-model="modelValue"
    />
    <span v-if="showErrorValue && errorValue" class="text-red text-xs mt-1">{{ errorValue }}</span>
  </div>
</template>
<script>
import { converter } from '@/util'
export default {
  name: 'input-with-label',
  props: {
    required: { type: Boolean, default: false },
    labelText: { type: String, required: true },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    max: { required: false },
    min: { required: false },
    maxlength: { required: false },
    minlength: { required: false },
    autofocus: { type: Boolean, default: false },
    errorValue: { type: [Boolean, String], default: false },
    showErrorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: String },
    customValuePlus: { type: Number },
    customValueMinus: { type: Number },
    forAction: { type: Function, default: () => {} },
    readValue: { type: String, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false,
    inputFocusClass: 'placeholder-neutral-300 border-yellow'
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    inputLabelFocus() {
      return this.isFocus ? 'text-yellow' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : ''
    },
    inputError() {
      return this.errorValue ? 'border-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-grey-e' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      if (this.customValuePlus) {
        this.writeValue(converter.datePlusDay(this.modelValue, this.customValuePlus))
      } else if (this.customValueMinus) {
        this.writeValue(converter.dateMinusDay(this.modelValue, this.customValueMinus))
      } else {
        this.writeValue(this.modelValue)
      }
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
