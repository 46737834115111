export default {
  name: 'hiring-partner',
  data: () => ({
    title: '',
    banners: []
  }),
  methods: {
    addMoreBanner() {
      const newItem = {
        imageUrl: '',
        title: '',
        slug: ''
      }
      this.banners.push(newItem)
    },
    deleteBanner(idx) {
      this.banners.splice(idx, 1)
    }
  }
}
