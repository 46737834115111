import VueSingleSelect from 'vue-single-select'
import ImageUploadMixin from '@/mixins/image-upload'
export default {
  name: 'page-core',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    statusOptions: ['PUBLISH', 'NOT PUBLISH'],
    subtitleMaxLength: 255,
    disabledStatus: true
  }),
  methods: {
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage() {
      this.$refs.fileInput.click()
    }
  }
}
