import { mapActions, mapGetters } from 'vuex'
import { converter, notification } from '@/util'
import axios from 'axios'
export default {
  name: 'active-clients-new',
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/others/Modal'),
    Modal2: () => import(/* webpackChunkName: "modal2" */ '@/components/modal/Modal'),
    ButtonsGroup: () => import(/* webpackChunkName: "page-title" */ '@/components/button/ButtonsGroup'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/components/forms/TextareaWithLabel'),
    PageTitle: () => import(/* webpackChunkName: "page-title" */ '@/components/others/PageTitle'),
    FindingData: () => import(/* webpackChunkName: "finding-data" */ '@/components/others/FindingData'),
    TableList: () => import(/* webpackChunkName: "table-list" */ '@/components/others/TableList'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/button/Button')
  },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    keyword: '',
    id: '',
    note: '',
    isModal: false,
    isModalApprove: false,
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    mitraList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Source',
        options: []
      },
      {
        label: 'Status',
        options: []
      },
      // {
      //   label: 'Cooperation Type',
      //   options: [
      //     { id: 'MANPOWER', name: 'Manpower' },
      //     { id: 'LMS', name: 'LMS & Prakerja' },
      //     { id: 'MARKETPLACE', name: 'Marketplace (Eduverse)' },
      //     { id: 'PROJECT', name: 'Project' },
      //     { id: 'EDUCATION', name: 'Education' },
      //     { id: 'LP_CURATION', name: 'Curation' }
      //   ]
      // },
      // {
      //   label: 'Role',
      //   options: [
      //     { id: '', name: 'All' },
      //     { id: 'ADMIN', name: 'LMS Admin' },
      //     { id: 'REGISTRANT', name: 'Talent Partner' },
      //     { id: 'LP_CURATION', name: 'LP Curation' }
      //   ]
      // },
      {
        label: 'Sort By',
        options: [
          { id: 'createdDate', name: 'Register Date' },
          { id: 'updatedDate', name: 'Last Update' }
        ]
      },
      {
        label: 'Order',
        options: [
          { id: 'DESC', name: 'Latest' },
          { id: 'ASC', name: 'Oldest' }
        ]
      }
    ],
    mitraSource: [],
    mitraStatus: [],
    visibleImportClient: false,
    fileList: [],
    fileName: '',
    typeFileOrImage: '',
    response: { name: '' },
    tmp: ''
  }),
  computed: {
    ...mapGetters(['userData']),
    customerId() {
      return this.userData?.user?.customerRoles[0]?.customer?.id
    },
    forTextarea: function() {
      return {
        label: 'Note',
        resize: 'resize-none',
        maxlength: 100,
        readValue: this.note,
        writeValue: value => (this.note = value)
      }
    },
    modalButtons: function() {
      return [
        {
          action: () => this.resetNote(),
          text: 'Cancel',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.archiveClient(),
          text: 'Archive',
          type: 'tertiary',
          size: 'small',
          disabled: this.note === ''
        }
      ]
    },
    modalApproveButtons: function() {
      return [
        {
          action: () => this.hideModalApprove(),
          text: 'Cancel',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.approve(),
          text: 'Approve',
          type: 'tertiary',
          size: 'small'
        }
      ]
    },
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: i < 2 ? [] : this.forOptions[i]?.options[0]?.id || ''
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.mitraSource,
          mode: 'multiple-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0]?.value : this.forFilterSelect[0]?.value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.mitraStatus,
          mode: 'multiple-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1]?.value : this.forFilterSelect[1]?.value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        },
        // {
        //   label: this.forOptions[2].label,
        //   options: this.forOptions[2].options,
        //   mode: 'multiple-select',
        //   readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
        //   writeValue: response => (this.forFilterSelect[2].value = response)
        // },
        {
          label: this.forOptions[2].label,
          options: this.forOptions[2].options,
          mode: 'v-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
          writeValue: response => (this.forFilterSelect[2].value = response)
        },
        {
          label: this.forOptions[3].label,
          options: this.forOptions[3].options,
          mode: 'v-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[3].value : this.forFilterSelect[3].value,
          writeValue: response => (this.forFilterSelect[3].value = response)
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchMitra(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        for (let i = 0; i < this.filterSelect.length; i++) {
          if (typeof this.filterSelect[i].value === 'object' && this.filterSelect[i].value.length > 0) {
            this.hide = false
            break
          }
        }
        // this.filterSelect[0].value.length > 0 ? (this.hide = false) : (this.hide = true)
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.getMitraList()
    this.getMitraSource()
    this.getMitraStatus()
  },
  methods: {
    ...mapActions(['getAllMitra', 'getAllSource', 'getAllStatus', 'importClient', 'approveClient', 'updateStatusClient', 'showLoading', 'hideLoading']),
    toggleImportClient() {
      this.fileList = []
      this.visibleImportClient = !this.visibleImportClient
    },
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getMitraList()
    },
    toAddClient() {
      this.$router.push('/active-clients/add')
    },
    getMitraSource() {
      this.getAllSource({
        params: {
          sortBy: 'sourceName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraSource.push({
              id: item.source_name,
              name: item.source_name
            })
          })
          this.forOptions[0].options = this.mitraSource
        }
      })
    },
    getMitraStatus() {
      this.getAllStatus({
        params: {
          sortBy: 'stageName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraStatus.push({
              id: item.stage_name,
              name: item.stage_name
            })
          })
          this.forOptions[1].options = this.mitraStatus
        }
      })
    },
    getMitraList() {
      this.showLoading()
      this.getAllMitra({
        type: 'ACTIVE',
        params: {
          page: this.currentPage,
          size: this.size,
          query: this.keyword,
          core_source_names: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
          core_stage_names: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
          // types: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
          types: '',
          // roles: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
          sortBy: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
          direction: this.filterSelect.length > 0 ? this.filterSelect[3].value : ''
        },
        success: res => this.distributePayload(res),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.clearSearch()
          this.hideLoading()
        }
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getMitraList()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.mitraList = []
      res.data.forEach(mitra => {
        this.mitraList.push({
          buttonCondition: mitra.needApproval,
          firstQuery: mitra.mitra_id,
          firstColumn: mitra.company_name,
          firstColumnDesc: '',
          secondColumn: mitra.pic_name,
          thirdColumn: mitra.pic_phone,
          fourthColumn: mitra.pic_email,
          fifthColumn: mitra.status,
          sixthColumn: mitra.sources,
          seventhColumn: converter.dateTimeView(mitra.created_date),
          eighthColumn: converter.dateTimeView(mitra.updated_date)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    showModalApprove(id) {
      this.id = id
      this.isModalApprove = true
    },
    hideModalApprove() {
      this.isModalApprove = false
    },
    approve() {
      this.showLoading()
      this.approveClient({
        id: this.id,
        success: () => {
          notification.successMessage('Approve client successfully!')
          this.hideModalApprove()
          this.getMitraList()
        },
        failed: () => notification.errorMessage('Approve client failed, please try again later!')
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    resetNote() {
      this.note = ''
      this.hideModal()
    },
    showModal(id) {
      this.id = id
      this.isModal = true
    },
    hideModal() {
      this.isModal = false
    },
    archiveClient() {
      this.showLoading()
      this.updateStatusClient({
        id: this.id,
        params: { status: false },
        requestBody: { notes: this.note },
        success: () => {
          notification.successMessage('Archive client successfully!')
          this.resetNote()
          this.$router.go()
        },
        failed: () => notification.errorMessage('Archive client failed, please try again later!')
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    toUserFriendly(type, data) {
      let forReturn = '-'
      let array = []
      data.forEach(element => {
        switch (type) {
          case 'cooperationType':
            for (let j = 0; j < this.forOptions[2].options.length; j++) {
              if (this.forOptions[2].options[j].id === element.name) array.push(this.forOptions[2].options[j].name)
            }
            break
          case 'roles':
            for (let j = 0; j < this.forOptions[3].options.length; j++) {
              if (this.forOptions[3].options[j].id === element) array.push(this.forOptions[3].options[j].name)
            }
            break
        }
      })
      if (array.length > 0) forReturn = array.join(', ')
      return forReturn
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    searchMitra(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getMitraList()
    },
    filter() {
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getMitraList()
    },
    clearFilter() {
      this.hide = true
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        i < 2 ? (this.forFilterSelect[i].value = []) : (this.forFilterSelect[i].value = this.forOptions[i].options[0].id)
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getMitraList()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      // this.getMitraList()
    },
    toPage() {
      this.isLoading = true
      this.getMitraList()
    },
    toDetailMitra(id) {
      this.$router.push({ path: `/active-clients/detail-client/${id}` })
    },
    createFile(file) {
      this.fileName = file.name
      this.typeFileOrImage = file.type
      let reader = new FileReader()
      reader.onload = e => {
        this.response.name = e.target.result
      }
      reader.readAsDataURL(file)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-yellow-200')
      }
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      this.createFile(this.$refs.file.files[0])
    },
    remove(i) {
      this.fileList.splice(i)
    },
    dragFile(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      this.fileList.push(droppedFiles[0])
      this.createFile(this.fileList[0])
    },
    cancelChangeFile() {
      this.fileList = []
    },
    async saveChangeFile() {
      if (!this.fileList.length) {
        alert('File harus di isi')
        return
      }
      this.showLoading()
      if (this.typeFileOrImage === 'text/csv') {
        await this.upladImageControl(this.response.name)
        this.modalEmployee = false
        this.fileList = []
        return
      }
      setTimeout(() => {
        this.hideLoading()
        this.fileList = []
        notification.errorMessage('Document must be in csv format')
      }, 3000)
    },
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeFileOrImage}`
      })
      this.apiImg = data
      let binary = atob(url.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileOrImage })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      }).then(() => {
        const payload = {
          temporaryFileUrl: `${this.apiImg.uploadUrl}/${this.apiImg.key}`
        }
        this.importClient({
          payload: payload,
          success: () => {
            this.hideLoading()
            notification.successMessage('Import Client Successfully!, Data are imported in the background process. Refresh the page to see the update.', 5000)
            this.toggleImportClient()
            this.getMitraList()
          },
          failed: () => {
            this.hideLoading()
            notification.errorMessage('Import Client Failed')
            this.toggleImportClient()
            this.getMitraList()
          }
        })
      })

      // await axios
      //   .post(`${baseUrl}/v2/api/cms/mitra/ACTIVE/import`, payload)
      //   .then(res => {
      //     this.hideLoading()
      //     notification.successMessage('Import Document Success')
      //   })
      //   .catch(() => {
      //     this.hideLoading()
      //     notification.errorMessage('Import Document Failed')
      //   })
    }
  }
}
