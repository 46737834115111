/* eslint-disable no-unused-expressions */
import { mapActions, mapGetters } from 'vuex'
import { currencyFormat } from '../../util/currency'

const GroupingClass = () => import(/* webpackChunkName: "grouping-class" */ '@/components/GroupingClass')
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'all-order',
  components: {
    GroupingClass,
    PageHeader,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    Search: () => import(/* webpackChunkName: "search" */ '@/components/icons/Search')
  },
  data: () => ({
    isTableVisible: true,
    isReload: false,
    activeParams: '',
    selectedDP: null,
    options: {
      limit: 11,
      page: 1,
      sort: '-id',
      nama: ''
    },
    keyword: '',
    keywordStatus: '',
    perPage: 10,
    showProgress: true,
    counterProgress: 0,
    modeForm: '',
    currentPage: 1,
    locked: false,
    unlockPassword: '',
    stillLoading: true,
    testingName: '',
    payload: {
      id: '',
      paymentType: [],
      status: [],
      transactionTimeEnd: '',
      transactionTimeStart: '',
      userEmail: '',
      userId: '',
      customerId: null
    },
    filterSelected: {
      customerId: null,
      sort: null,
      status: null,
      paymentType: null,
      transactionTimeStart: '',
      transactionTimeEnd: ''
    },
    isKeywordValid: false,
    isSearchKeyword: false,
    isSearchButtonClicked: false,
    warning: '',
    params: {
      page: 0,
      size: 10,
      sort: ''
    },
    dataOfTable: [],
    totalRecords: 0,
    clientList: [],
    sortByList: [
      {
        id: 'createdDate,Desc',
        name: 'Last Updated'
      },
      {
        id: 'createdDate,Asc',
        name: 'From Oldest'
      }
    ],
    paymentTypeOptions: [
      {
        id: 'bank_transfer',
        name: 'Transfer'
      },
      {
        id: 'voucher',
        name: 'Voucher'
      }
    ],
    statusOptions: [
      {
        id: 'PAID',
        name: 'Sukses'
      },
      {
        id: 'PENDING',
        name: 'Menunggu Pembayaran'
      },
      {
        id: 'WAITING_FOR_PAYMENT',
        name: 'Lanjutkan Pembayaran'
      },
      {
        id: 'FAILED',
        name: 'Gagal'
      }
    ]
  }),

  watch: {
    keyword(val) {
      if (val === '') {
        this.payload.id = ''
      }
      if (!this.isSearchKeyword) return
      if (val.includes('@')) {
        this.payload.userEmail = val
        this.isKeywordValid = true
        this.payload.id = ''
        return
      } else {
        if (isNaN(val) === true) {
          this.isKeywordValid = false
          this.warning = 'Order id not valid'
          return
        }
        if (val.length !== 15) {
          this.isKeywordValid = false
          this.warning = 'Order id must be 15 numbers !'
          return
        }
      }
      this.payload.userEmail = ''
      this.payload.id = val
      this.isKeywordValid = true
      // this.payload.id = val
    },
    refreshPage() {
      this.clearParameter()
    },
    filterType(val) {
      if (val === 'voucher') {
        this.statusOptions = [
          {
            id: 'PAID',
            name: 'Sukses'
          },
          {
            id: 'FAILED',
            name: 'Gagal'
          }
        ]
        return
      }
      this.statusOptions = [
        {
          id: 'PAID',
          name: 'Sukses'
        },
        {
          id: 'PENDING',
          name: 'Menunggu Pembayaran'
        },
        {
          id: 'WAITING_FOR_PAYMENT',
          name: 'Lanjutkan Pembayaran'
        },
        {
          id: 'FAILED',
          name: 'Gagal'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['refreshPage']),
    isClearBtnActive() {
      if (
        this.filterSelected.customerId !== null ||
        this.filterSelected.sort !== null ||
        this.filterSelected.status !== null ||
        this.filterSelected.paymentType !== null ||
        this.payload.transactionTimeStart !== '' ||
        this.payload.transactionTimeEnd !== '' ||
        this.keyword !== ''
      ) {
        return true
      }
      return false
    },
    filterStatus() {
      if (this.filterSelected.status === null) {
        return null
      }
      return this.filterSelected.status.id
    },
    filterType() {
      if (this.filterSelected.paymentType === null) {
        return null
      }
      return this.filterSelected.paymentType.id
    },
    columns() {
      return [
        { field: 'id', label: 'Order Id' },
        { field: 'transactionTime', label: 'Order Date' },
        { field: 'customer_name', label: 'Client' },
        { field: 'userName', label: 'Customer Name' },
        { field: 'userEmail', label: 'Email' },
        { field: 'status', label: 'Status' },
        { field: 'programType', label: 'Program Type' },
        { field: 'voucher', label: 'Voucher' },
        { field: 'voucherAmount', label: 'Voucher Amount' },
        { field: 'paidAmount', label: 'Paid Amount' },
        { field: 'timestamp', label: 'Status Timestamp' },
        { field: 'btn', label: 'Action' }
      ]
    }
  },
  created() {
    this.getData()
    this.getClient()
  },

  methods: {
    ...mapActions(['searchOrder', 'getClientList', 'getUserByUserId', 'showLoading', 'hideLoading']),
    toDetail(orderId) {
      this.$router.push({ name: 'Order Details', params: { orderId } })
    },
    onPageChange(params) {
      this.params.page = params.currentPage - 1
      this.currentPage = params.currentPage
      this.params.size = params.currentPerPage
      this.getData()
    },

    async onSearch() {
      if (this.isSearchKeyword) {
        this.isSearchButtonClicked = true
        setTimeout(() => {
          this.isSearchButtonClicked = false
        }, 500)
      }

      if (this.isSearchKeyword && !this.isKeywordValid && this.keyword !== '') {
        return this.$toast.success(this.warning, {
          position: 'top',
          type: 'warning',
          duration: 1000,
          queue: true
        })
      }
      this.params.page = 0
      this.currentPage = 1
      this.checkParams(this.filterSelected)
      this.getData()
    },

    onFocus() {
      this.isSearchKeyword = true
    },

    onBlur() {
      if (this.keyword === '' && !this.isKeywordValid) {
        this.isSearchKeyword = false
      }
    },

    async clearParameter() {
      this.isReload = !this.isReload
      this.keyword = ''
      this.filterSelected = {
        customerId: null,
        sort: null,
        status: null,
        paymentType: null,
        transactionTimeStart: '',
        transactionTimeEnd: ''
      }
      this.payload = {
        id: '',
        paymentType: [],
        status: [],
        transactionTimeEnd: '',
        transactionTimeStart: '',
        userEmail: '',
        userId: '',
        customerId: null
      }

      this.params = {
        page: 0,
        size: 10,
        sort: ''
      }

      this.getData()
    },

    checkParams(data) {
      for (const property in data) {
        switch (property) {
          case 'sort':
            this.params.sort = this.isObjectData(data['sort'], '')
            break
          case 'customerId':
            this.payload.customerId = this.isObjectData(data['customerId'], null)
            break
          case 'status':
            this.filterSelected['status'] === null ? '' : (this.payload.status[0] = this.isObjectData(data['status'], []))
            break
          case 'paymentType':
            this.filterSelected['paymentType'] === null ? '' : (this.payload.paymentType[0] = this.isObjectData(data['paymentType'], []))
            break
          default:
            break
        }
      }

      return true
    },

    isObjectData(data, defaultValue) {
      if (typeof data === 'object' && data !== null) {
        return data.id
      }
      return defaultValue
    },

    isVoucher(payment) {
      if (this.paymentType(payment).isVoucher) return 'Yes'
      return 'No'
    },
    paymentType(payment) {
      const result = { label: '', voucherPrice: 0, transferPrice: 0, isVoucher: false }

      switch (payment.paymentType) {
        case 'bank_transfer':
          payment.bank ? (result.label = `${payment.bank.toUpperCase()} `) : (result.label = 'Not Found')
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        case 'echannel':
          result.label = 'Mandiri'
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        case 'csstore':
          result.label = `${payment.store.toUpperCase()}`
          result.transferPrice = currencyFormat(payment.totalPrice)
          break
        default:
          result.label = 'Voucher'
          payment.status === 'WAITING_FOR_PAYMENT' ? '' : (result.isVoucher = true)
          payment.status === 'WAITING_FOR_PAYMENT' ? (result.transferPrice = currencyFormat(payment.totalPrice)) : (result.voucherPrice = currencyFormat(payment.totalDiscount))
          break
      }

      if (payment.status === 'WAITING_FOR_PAYMENT' || payment.status === 'FAILED') result.isVoucher = false
      return result
    },
    translateStatus(status) {
      let result = { label: '', style: '' }
      switch (status) {
        case 'PAID':
          result.label = 'Sukses'
          result.style = 'background: #D5F6E3; color:#27AE60'
          break
        case 'PENDING':
          result.label = 'Menunggu Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break

        case 'WAITING_FOR_PAYMENT':
          result.label = 'Lanjutkan Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result.label = 'Gagal'
          result.style = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    customStyle(status) {
      let result = ''
      switch (status) {
        case 'Sukses':
          result = 'background: #D5F6E3; color:#27AE60'
          break
        case 'Menunggu Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break

        case 'Lanjutkan Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    getClient() {
      this.getClientList({
        success: res => {
          this.clientList = res.data
        }
      })
    },

    getUserById(userId, index) {
      this.getUserByUserId({
        userId,
        success: res => {
          this.dataOfTable[index].userName = res.name
        },
        failed: () => {}
      })
    },

    async getData() {
      this.isTableVisible = false
      this.dataOfTable = []
      this.showLoading()
      this.searchOrder({
        payload: this.payload,
        params: this.params,
        success: res => {
          this.totalRecords = res.totalElements
          const isDataAvailable = new Promise(resolve => {
            this.dataOfTable = res.content.map((item, index) => {
              this.getUserById(item.userId, index)
              return {
                ...item,
                programType: item.orderItems[0].type,
                customer_name: item.orderItems[0].customerName,
                transactionTime: this.moment(`${item.createdDate}.000+0000`).format('DD MMM YYYY HH:mm'),
                voucher: this.isVoucher(item),
                voucherAmount: this.paymentType(item).voucherPrice,
                paidAmount: this.paymentType(item).transferPrice,
                status: this.translateStatus(item.status).label,
                timestamp: this.moment(`${item.transactionTime}.000+0000`).format('DD MMM YYYY HH:mm'),
                fullName: 'TES'
              }
            })
            resolve(true)
          })

          if (isDataAvailable) {
            setTimeout(() => {
              this.isTableVisible = true
              this.hideLoading()
            }, 1000)
          }
        },
        failed: () => {}
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    },

    showToast(position, type, text) {
      this.$toast.success(text, {
        position: position,
        type: type,
        queue: true
      })
    }
  }
}
