<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.48 3.46875C7.78641 2.66016 5.41922 2.26406 2.25 2.25C1.95111 2.24594 1.65809 2.3331 1.41 2.49985C1.20637 2.63748 1.03968 2.82305 0.924597 3.04023C0.809512 3.2574 0.749555 3.49953 0.750002 3.74531V17.1563C0.750002 18.0628 1.395 18.7467 2.25 18.7467C5.58141 18.7467 8.92313 19.058 10.9247 20.9498C10.9521 20.9758 10.9865 20.9932 11.0237 20.9998C11.0608 21.0064 11.0991 21.002 11.1338 20.987C11.1685 20.972 11.1979 20.9472 11.2186 20.9156C11.2393 20.884 11.2502 20.847 11.25 20.8092V5.00719C11.2501 4.90057 11.2273 4.79518 11.1831 4.69814C11.139 4.6011 11.0745 4.51467 10.9941 4.44469C10.5356 4.05274 10.0263 3.72446 9.48 3.46875V3.46875ZM22.59 2.49844C22.3418 2.33211 22.0488 2.24544 21.75 2.25C18.5808 2.26406 16.2136 2.65828 14.52 3.46875C13.9737 3.724 13.4643 4.05163 13.0055 4.44281C12.9252 4.51292 12.8609 4.59938 12.8168 4.69641C12.7728 4.79343 12.75 4.89876 12.75 5.00531V20.8083C12.75 20.8446 12.7607 20.88 12.7807 20.9103C12.8008 20.9405 12.8293 20.9641 12.8628 20.9782C12.8962 20.9923 12.9331 20.9962 12.9687 20.9894C13.0044 20.9826 13.0372 20.9654 13.0631 20.94C14.2664 19.7447 16.3781 18.7453 21.7519 18.7458C22.1497 18.7458 22.5312 18.5877 22.8125 18.3064C23.0938 18.0251 23.2519 17.6436 23.2519 17.2458V3.74578C23.2524 3.49951 23.1923 3.2569 23.0769 3.03936C22.9615 2.82182 22.7942 2.63604 22.59 2.49844V2.49844Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
