import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'

Vue.use && Vue.use(VueResource)

const state = {
  prakerjaResponse: {}
}
const actions = {
  getPrakerjaContent: ({ commit }, { success }) => {
    Vue.http.get(config.api.prakerja).then(response => {
      success && success(response.body.data)
      commit('setPrakerjaResponse', response.body.data)
    })
  },
  savePrakerjaContent: ({ commit }, { reqBody, success }) => {
    Vue.http
      .put(config.api.prakerja, reqBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setPrakerjaResponse', response.body.data)
      })
  }
}
const mutations = {
  setPrakerjaResponse(state, value) {
    state.prakerjaResponse = value
  }
}
const getters = {
  prakerjaResponse: state => {
    return state.prakerjaResponse
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
