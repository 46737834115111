import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'class-syllabus',
  data: () => ({
    option: {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['clean']]
      }
    }
  }),
  components: {
    quillEditor
  },
  props: {
    payload: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    addMoreItem() {
      const obj = this.payload
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      const item = {
        courseKit: '',
        description: '',
        name: '',
        seqNo: lastObj.seqNo + 1,
        title: ''
      }
      obj.push(item)
    },
    deleteSyllabus(idx) {
      this.payload.splice(idx, 1)
    }
  }
}
