import ImageUploadMixin from '@/mixins/image-upload'
export default {
  name: 'testimoni',
  mixins: [ImageUploadMixin],
  props: ['payload', 'youtubeLink'],
  data: () => ({
    testimonies: [
      {
        name: '',
        title: '',
        paragraph: '',
        kelas: ''
      }
    ]
  }),
  computed: {
    sequenceOptions() {
      return Array.from({ length: this.payload.length }, (_, i) => i + 1)
    }
  },
  methods: {
    addMoreTestimoni() {
      const item = {
        name: '',
        title: '',
        paragraph: '',
        kelas: '',
        url: '',
        seqNo: Math.max(...this.sequenceOptions) + 1
      }
      this.payload.push(item)
    },
    removeTestimoni(idx) {
      this.payload.splice(idx, 1)
    },
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage(item, idx) {
      this.$refs['filealumni' + idx][0].click()
    }
  }
}
