<template>
  <div>
    <div v-show="list.length > 0">
      <DataTable
        :tableHeader="tableHeader"
        :tableHeaderAlign="tableHeaderAlign"
        :checkbox="checkbox"
        :actionCheckbox="actionCheckbox"
        :textToAction="textToAction"
        :tooltip="tooltip"
        :secondTooltip="secondTooltip"
        :tooltipSize="tooltipSize"
        :list="list"
        :listAlign="listAlign"
        :actionButtons="actionButtons"
        :actionButtonConditions="actionButtonConditions"
        :counter="counter"
        :totalPages="totalPages"
        :totalItems="totalItems"
        :size="size"
      />
      <div class="flex justify-between items-center md:flex-col">
        <div class="flex justify-start">
          <ShowingOfData :currentPage="currentPage" :size="size" :totalItemsCurrentPage="list.length" :totalDatas="totalItems" />
        </div>
        <div>
          <PaginationNew :actualPage="currentPage" :counter="counter" :size="size" :totalPages="totalPages" :currentPage="changePage" />
        </div>
        <div class="flex justify-end">
          <RowsPerPage :changeSize="changeSize" />
        </div>
      </div>
    </div>
    <DataNotFound v-show="list.length === 0" :headerText="headerTextNotFound" :descriptionText="descriptionTextNotFound" :action="actionNotFound" />
  </div>
</template>
<script>
import PaginationNew from '@/components/pagination/PaginationNew.vue'
const DataNotFound = () => import(/* webpackChunkName: "data-not-found" */ '@/components/illustration/DataNotFound')
const DataTable = () => import(/* webpackChunkName: "data-table" */ '@/components/table/DataTable')
const ShowingOfData = () => import(/* webpackChunkName: "showing-of-data" */ '@/components/text/ShowingOfData')
const RowsPerPage = () => import(/* webpackChunkName: "rows-per-page" */ '@/components/text/RowsPerPage')
export default {
  name: 'table-list',
  components: { PaginationNew, DataNotFound, DataTable, ShowingOfData, RowsPerPage },
  props: {
    tableHeader: { type: Array },
    tableHeaderAlign: { type: Array },
    checkbox: { type: Boolean, default: false },
    actionCheckbox: { type: Function, default: () => {} },
    textToAction: { type: Array },
    tooltip: { type: Array },
    secondTooltip: { type: Array },
    tooltipSize: { required: false },
    list: { type: Array, required: true },
    listAlign: { type: Array },
    actionButtons: { type: Array },
    actionButtonConditions: { type: Array },
    totalPages: { required: false },
    totalItems: { required: true },
    currentPage: { required: true },
    size: { required: true },
    counter: { required: true },
    maxVisiblePages: { required: false, default: 5 },
    changePage: { type: Function, required: true },
    changeSize: { type: Function, required: true },
    headerTextNotFound: { type: String },
    descriptionTextNotFound: { type: String },
    actionNotFound: { type: Object }
  }
}
</script>
