<template>
  <div class="flex flex-col">
    <label for="input-icon-svg-with-label" class="text-sm pl-2 text-custom-black-1" :class="[inputLabelFocus, inputLabelError]">{{ labelText }}</label>
    <div class="flex rounded-md border my-1 p-2 h-10 focus:outline-none" :class="[inputFocus, inputError, inputDisabled]">
      <GeneralIcon class="mr-1" :class="[additionalIconStyle]" :action="action" :height="heightIcon" :width="widthIcon" :viewBox="viewBox" :paths="forPaths" />
      <input
        name="input-icon-svg-with-label"
        class="text-sm w-full h-full focus:outline-none"
        :type="type"
        :placeholder="placeholder"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        :autofocus="autofocus"
        :errorValue="errorValue"
        :disabled="disabled"
        :value="value"
        :customValuePlus="customValuePlus"
        :customValueMinus="customValueMinus"
        :readValue="readValue"
        :writeValue="writeValue"
        @focus="setFocusTrue()"
        @blur="setFocusFalse()"
        @keypress.enter="action"
        v-model="modelValue"
      />
    </div>
  </div>
</template>
<script>
import { converter } from '@/util'
const GeneralIcon = () => import(/* webpackChunkName: "general-icon" */ '@/components/icons/GeneralIcon')
export default {
  name: 'input-icon-svg-with-label',
  components: { GeneralIcon },
  props: {
    additionalIconStyle: { type: String, default: '' },
    iconAction: { type: Function, default: () => {} },
    heightIcon: { type: String, default: '20' },
    widthIcon: { type: String, default: '20' },
    viewBox: { type: String, default: '0 0 20 20' },
    paths: { type: Array, required: true },
    fillIconDefault: { type: String, default: '#000000' },
    fillIconFocus: { type: String, default: '#000000' },
    labelText: { type: String, required: true },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    max: { required: false },
    min: { required: false },
    maxlength: { required: false },
    minlength: { required: false },
    autofocus: { type: Boolean, default: false },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: String },
    customValuePlus: { type: Number },
    customValueMinus: { type: Number },
    readValue: { type: String, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false,
    inputFocusClass: 'placeholder-neutral-300 border-yellow'
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    forPaths() {
      let d = this.paths.length > 0 ? this.paths[0].d : ''
      return this.isFocus ? [{ d, fill: this.fillIconFocus }] : [{ d, fill: this.fillIconDefault }]
    },
    inputLabelFocus() {
      return this.isFocus ? 'text-yellow' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : 'border-neutral-300'
    },
    inputError() {
      return this.errorValue ? 'border-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-grey-e' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      if (this.customValuePlus) {
        this.writeValue(converter.datePlusDay(this.modelValue, this.customValuePlus))
      } else if (this.customValueMinus) {
        this.writeValue(converter.dateMinusDay(this.modelValue, this.customValueMinus))
      } else {
        this.writeValue(this.modelValue)
      }
    }
  },
  methods: {
    action() {
      this.iconAction()
    },
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
