import { mapActions } from 'vuex'
import { currencyFormat } from '../../util/currency'
export default {
  name: 'order-details',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    Search: () => import(/* webpackChunkName: "search" */ '@/components/icons/Search')
  },
  data: () => ({
    name: '',
    email: '',
    phone: '',
    orderDate: '',
    qty: '',
    totalPrice: '',
    status: '',
    history: [],
    orderItems: []
  }),

  filters: {
    currencyFormat
  },

  watch: {},
  computed: {
    orderId() {
      return this.$route.params.orderId
    }
  },
  created() {
    this.getData()
  },

  methods: {
    ...mapActions(['getOrderByOrderId', 'getUserByUserId', 'showLoading', 'hideLoading']),

    translateStatus(status) {
      let result = { label: '', style: '' }
      switch (status) {
        case 'PAID':
          result.label = 'Sukses'
          result.style = 'background: #D5F6E3; color:#27AE60'
          break
        case 'PENDING':
          result.label = 'Menunggu Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break

        case 'WAITING_FOR_PAYMENT':
          result.label = 'Lanjutkan Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result.label = 'Gagal'
          result.style = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },

    customStyle(status) {
      let result = ''
      switch (status) {
        case 'Sukses':
          result = 'background: #D5F6E3; color:#27AE60'
          break
        case 'Menunggu Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break

        case 'Lanjutkan Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    getData() {
      this.showLoading()
      this.getOrderByOrderId({
        orderId: this.orderId,
        success: res => {
          this.email = res.userEmail
          this.orderDate = this.moment(`${res.createdDate}.000+0000`).format('DD MMM YYYY')
          this.qty = res.orderItems.length
          this.totalPrice = currencyFormat(res.totalPrice + res.totalDiscount)
          this.status = this.translateStatus(res.status).label
          const history = [
            {
              label: this.translateStatus(res.status).label,
              date: this.moment(`${res.transactionTime}.000+0000`).format('DD MMM YYYY HH:mm')
            },
            {
              label: 'Order dibuat',
              date: this.moment(`${res.createdDate}.000+0000`).format('DD MMM YYYY HH:mm')
            }
          ]
          this.history = history
          this.orderItems = res.orderItems
          this.getUserByUserId({
            userId: res.userId,
            success: res => {
              this.name = res.name
              this.phone = res.mobilePhone
            },
            failed: () => {}
          })
          this.hideLoading()
        }
      })
    },

    showToast(position, type, text) {
      this.$toast.success(text, {
        position: position,
        type: type,
        queue: true
      })
    }
  }
}
