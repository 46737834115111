import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')

export default {
  name: 'class',
  components: {
    'v-select': vSelect,
    PageHeader,
    Pagination: () => import(/* webpackChunkName: "page-header" */ '@/components/pagination/Pagination'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Search')
  },
  data: () => ({
    keyword: '',
    searchKeyword: '',
    currentPage: 1,
    totalData: 0,
    pageSize: 10,
    programType: ''
  }),
  computed: {
    ...mapGetters(['classList', 'programs']),
    allClasses() {
      let temp = this.classList.data
      if (this.keyword !== '' && this.keyword) {
        temp = temp.filter(item => {
          return item.name.toUpperCase().includes(this.keyword.toUpperCase())
        })
      }
      return temp
    }
  },
  created() {
    if (!this.$route.query.page) {
      this.$router.push({ path: '/daftar-kelas', query: { page: 1 } })
      this.currentPage = 1
    } else {
      this.currentPage = parseInt(this.$route.query.page)
      this.getClasses(this.searchKeyword)
    }
    this.getProgramList()
  },
  watch: {
    $route(val) {
      if (val.query.page) {
        this.currentPage = parseInt(val.query.page)
        this.getClasses(this.searchKeyword)
      } else {
        this.$router.push({ path: '/daftar-kelas', query: { page: 1 } })
        this.currentPage = 1
      }
    }
  },
  methods: {
    ...mapActions(['getClassList', 'deleteCLass', 'showLoading', 'hideLoading', 'getPrograms']),
    search() {
      // this.keyword = this.searchKeyword
      this.getClasses(this.searchKeyword)
    },
    filterByProgram() {
      if (this.currentPage !== 1) {
        this.$router.push({ path: '/daftar-kelas', query: { page: 1 } })
      } else {
        this.searchKeyword = ''
        this.getClasses()
      }
    },
    getProgramList() {
      this.getPrograms({
        success: () => {
          const all = {
            code: 'all',
            name: 'Semua Program'
          }
          this.programs.unshift(all)
          this.programType = this.programs[0]
        }
      })
    },
    getClasses(searchKeyword = '') {
      let selectedProgram = ''
      if (this.programType) {
        selectedProgram = this.programType.code === 'all' ? '' : this.programType.name
      }
      this.showLoading()
      this.getClassList({
        params: {
          page: parseInt(this.currentPage) - 1,
          size: this.pageSize,
          name: searchKeyword,
          program: selectedProgram
        },
        success: res => {
          this.totalData = res.totalRows
          this.hideLoading()
        }
      })
    },
    goToEditPage(id) {
      this.$router.push('/daftar-kelas/edit/' + id)
    },
    deleteCLassById(id) {
      this.$confirm({
        message: `Are you sure want to delete this class?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.deleteCLass({
              id: id,
              success: () => {
                this.getClasses(this.searchKeyword)
              }
            })
          }
        }
      })
    },
    onPageChange(page) {
      this.$router.push({ path: '/daftar-kelas', query: { page: page } })
      this.currentPage = page
    }
  }
}
