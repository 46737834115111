import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {}
const actions = {
  createProgramContent: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(config.api.programContent, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  editProgramContent: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .put(config.api.programContent, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  homePageProgram: ({ commit }, { programName, params, success, failed }) => {
    Vue.http
      .get(`${config.api.homePageProgram}/${programName}${serializeQueryParams(params)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data.classGroups)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}
const mutations = {}
const getters = {}

export default {
  state,
  getters,
  actions,
  mutations
}
