import ImageUploadMixin from '@/mixins/image-upload'
import VueSingleSelect from 'vue-single-select'
export default {
  name: 'training',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    buttonOptions: ['primary', 'secondary']
  }),
  computed: {
    sequenceOptions() {
      return Array.from(
        { length: this.payload.trainingHomePageRequests.length },
        (_, i) => i + 1
      )
    }
  },
  methods: {
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage(item, idx) {
      this.$refs['filetraining' + idx][0].click()
    }
  }
}
