import VueSingleSelect from 'vue-single-select'

export default {
  name: 'business',
  components: {
    VueSingleSelect
  },
  data: () => ({
    banners: [],
    statusOptions: ['PUBLISH', 'Open', 'ACTIVE'],
    status: 'PUBLISH'
  }),
  methods: {
    addMoreBanner() {
      const newItem = {
        imageUrl: '',
        title: '',
        slug: ''
      }
      this.banners.push(newItem)
    },
    deleteBanner(idx) {
      this.banners.splice(idx, 1)
    }
  }
}
