/* eslint-disable no-unused-expressions */
import { mapActions } from 'vuex'

const GroupingClass = () => import(/* webpackChunkName: "grouping-class" */ '@/components/GroupingClass')
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'all-marketplace',
  components: {
    GroupingClass,
    PageHeader,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    Search: () => import(/* webpackChunkName: "search" */ '@/components/icons/Search'),
    ButtonNewClass: () => import(/* webpackChunkName: "buttonNewClas" */ '@/components/button/ButtonNewClass')
  },
  data: () => ({
    isTableVisible: true,
    isReload: false,
    keyword: '',
    keywordStatus: '',
    perPage: 10,
    currentPage: 1,
    isKeywordValid: false,
    isSearchKeyword: false,
    dataOfTable: [],
    totalRecords: 0,
    clientList: []
  }),

  computed: {
    columns() {
      return [
        { field: 'marketplaceName', label: 'Marketplace Name' },
        { field: 'url', label: 'URL' },
        { field: 'pendingRequest', label: 'Pending Request' },
        { field: 'lastUpdatedDate', label: 'Last Update' },
        { field: 'btn', label: 'Action' }
      ]
    }
  },

  methods: {
    ...mapActions(['getAllMarketplaceList']),
    onFocus() {
      this.isSearchKeyword = true
    },
    onBlur() {
      if (this.keyword === '' && !this.isKeywordValid) {
        this.isSearchKeyword = false
      }
    },
    toDetail(id) {
      this.$router.push('/all-marketplace/' + id)
    },
    toDateManipulate(string, type) {
      if (type) return this.moment(string).format('DD MMM YYYY')
      return this.moment(string).format('HH:mm')
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    },
    fetchAllMarketPlace() {
      this.getAllMarketplaceList({
        success: res => {
          this.dataOfTable = res
        },
        failed: err => {
          ths.showToast('is-top', 'is-danger', err)
        }
      })
    },
    showToast(position, type, text) {
      this.$toast.success(text, {
        position: position,
        type: type,
        queue: true
      })
    }
  },
  mounted() {
    this.fetchAllMarketPlace()
  }
}
