var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"short-course"},[_vm._m(0),_c('div',{staticClass:"bg-white p-4 shadow-small rounded-lg card"},[_c('div',{staticClass:"flex items-center mb-7"}),(_vm.isTableVisible)?_c('vue-good-table',{attrs:{"mode":"remote","pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        setCurrentPage: _vm.currentPage
      },"totalRows":_vm.totalRecords,"rows":_vm.dataOfTable,"columns":_vm.columns,"sort-options":{
        enabled: false
      },"compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'btn')?_c('div',{staticClass:"flex flex-col gap-2"},[_c('ButtonNewClass',{staticClass:"mr-2",attrs:{"type":"tertiary","size":"icon","forIcon":{ color: 'yellow' },"icon":function () { return import(/* webpackChunkName: 'icon' */ '@/components/icons/Pencil'); },"tooltip":{ show: true, position: '100%', text: 'Detail' }},on:{"action":function($event){return _vm.toDetail(props.row.id)}}})],1):(props.column.field === 'id')?_c('div',{staticClass:"cursor-pointer hover:text-yellow",on:{"click":function($event){return _vm.toDetail(props.row.id)}}},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'transactionTime')?_c('div',{staticClass:"w-36"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'status')?_c('div',{staticClass:"w-56"},[_c('div',{staticClass:"w-max font-bold p-2 rounded-lg",style:(_vm.customStyle(props.row[props.column.field]))},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]):(props.column.field === 'customer_name')?_c('div',{staticClass:"text-yellow w-36"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'userName')?_c('div',{staticClass:"w-56"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):_vm._e(),(props.column.field === 'lastUpdatedDate')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.toDateManipulate(props.row[props.column.field], true)))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.toDateManipulate(props.row[props.column.field])))])]):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}}],null,false,298901523)}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6 flex justify-between items-center"},[_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v("All Marketplace")])])}]

export { render, staticRenderFns }