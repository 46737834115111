import Vue from 'vue'
import Vuex from 'vuex'
import homepage from './modules/homepage'
import prakerja from './modules/prakerja'
import beasiswa from './modules/beasiswa'
import classes from './modules/class'
import instructor from './modules/instructor'
import testimony from './modules/testimony'
import common from './modules/common'
import marketplace from './modules/marketplace'
import programs from './modules/programs'
import order from './modules/order'
import admin from './modules/admin'
import notification from './modules/notification'
import seo from './modules/seo'
import campaign from './modules/campaign'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homepage,
    prakerja,
    beasiswa,
    classes,
    instructor,
    testimony,
    common,
    marketplace,
    programs,
    order,
    admin,
    notification,
    seo,
    campaign
  }
})
