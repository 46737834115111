import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {
  homePageResponse: {}
}
const actions = {
  getHomePageContent: ({ commit }, { success }) => {
    Vue.http.get(config.api.homePage).then(response => {
      success && success(response.body.data)
      commit('setHomePageResponse', response.body.data)
    })
  },
  saveHomePageContent: ({ commit }, { reqBody, success }) => {
    Vue.http
      .put(config.api.homePage, reqBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setHomePageResponse', response.body.data)
      })
  },
  getPopUpContent: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.popup}${serializeQueryParams(params)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  savePopUpContent: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(`${config.api.popup}`, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  updatePopUpContent: ({ commit }, { id, payload, success, failed }) => {
    Vue.http
      .put(`${config.api.popup}/${id}`, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  deletePopUpContent: ({ commit }, { id, success, failed }) => {
    Vue.http
      .delete(`${config.api.popup}/${id}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}
const mutations = {
  setHomePageResponse(state, value) {
    state.homePageResponse = value
  }
}
const getters = {
  homePageResponse: state => {
    return state.homePageResponse
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
