import { mapGetters, mapActions } from 'vuex'
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
const PageCore = () => import(/* webpackChunkName: "page-core" */ '@/components/PageCore')
const PagePrakerja = () => import('@/components/PagePrakerja')
const PageJaringanKerjasama = () => import('@/components/PageJaringanKerjasama')
const PagePrakerjaClass = () => import('@/components/PrakerjaClass')

export default {
  name: 'prakerja',
  components: {
    PageHeader,
    PageCore,
    PagePrakerja,
    PageJaringanKerjasama,
    PagePrakerjaClass
  },
  data: () => ({
    corePayload: {},
    tentangPrakerjaPayload: {},
    jaringanKerjasamaPayload: {
      title: '',
      items: []
    },
    prosesKartuPrakerjaPayload: {},
    kelasRekomendasiPrakerjaPayload: {
      title: '',
      items: []
    },
    prakerjaPayload: {
      jaringanKerjaSamaPrakerja: {},
      kelasRekomendasiPrakerja: {}
    },
    prakerjaClasses: [],
    prakerjaPageData: {},
    searchResult: []
  }),
  computed: {
    ...mapGetters(['prakerjaResponse']),
    kelasRekomendasi() {
      return this.kelasRekomendasiPrakerjaPayload.items
    }
  },
  mounted() {
    this.fetchPrakerjaContent()
  },
  methods: {
    ...mapActions(['getPrakerjaContent', 'getClassList', 'savePrakerjaContent', 'showLoading', 'hideLoading']),
    fetchPrakerjaContent() {
      this.showLoading()
      this.getPrakerjaContent({
        success: res => {
          this.prakerjaPageData = res
          this.getClasses(res)
          this.hideLoading()
        }
      })
    },
    getClasses(prakerjaData) {
      this.getClassList({
        params: {
          page: 0,
          size: 1000,
          program: 'Prakerja'
        },
        success: res => {
          this.prakerjaClasses = res.data.filter(item => item.type === 'Prakerja')
          this.distributePrakerjaPayload(prakerjaData, res.data)
        }
      })
    },
    distributePrakerjaPayload(res, classes) {
      this.corePayload = {
        slug: res.slug,
        statusId: 'PUBLISH',
        subTitle: res.subTitle,
        title: res.title,
        url: res.url,
        seoDescription: res.seoDescription
      }
      this.tentangPrakerjaPayload = res.tentangPrakerja
      this.jaringanKerjasamaPayload.title = res.jaringanKerjaSamaPrakerja.title
      var items = []
      for (let i = 0; i < res.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas.length; i++) {
        items.push({
          id: res.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas[i].id,
          title: res.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas[i].title,
          url: res.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas[i].url,
          name: res.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas[i].name
        })
      }
      this.jaringanKerjasamaPayload.items = items
      this.prosesKartuPrakerjaPayload = res.prosesKartuPrakerja
      this.kelasRekomendasiPrakerjaPayload.title = res.kelasRekomendasiPrakerja.title
      const classItems = res.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas.map(function (item) {
        item.coreClass = classes.filter(el => {
          return el.id === item.coreClass.id
        })[0]
        return item
      })
      this.kelasRekomendasiPrakerjaPayload.items = classItems
    },
    savePrakerjaData() {
      let self = this
      this.prakerjaPayload.slug = this.corePayload.slug
      this.prakerjaPayload.statusId = this.corePayload.statusId
      this.prakerjaPayload.subTitle = this.corePayload.subTitle
      this.prakerjaPayload.title = this.corePayload.title
      this.prakerjaPayload.url = this.corePayload.url
      this.prakerjaPayload.seoDescription = this.corePayload.seoDescription
      this.prakerjaPayload.tentangPrakerja = this.tentangPrakerjaPayload
      this.prakerjaPayload.jaringanKerjaSamaPrakerja.title = this.jaringanKerjasamaPayload.title
      this.prakerjaPayload.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas = this.jaringanKerjasamaPayload.items
      // resolve sequence
      if (this.prakerjaPayload.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas.length !== 0) {
        for (let i = 1; i <= this.prakerjaPayload.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas.length; i++) {
          this.prakerjaPayload.jaringanKerjaSamaPrakerja.jaringanKerjaSamaItemPrakerjas[i - 1].seqNo = i
        }
      }
      this.prakerjaPayload.kelasRekomendasiPrakerja.title = this.kelasRekomendasiPrakerjaPayload.title
      this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas = this.kelasRekomendasiPrakerjaPayload.items
      // resolve sequence and data
      if (this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas.length !== 0) {
        for (let i = 1; i <= this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas.length; i++) {
          let temp = this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas[i - 1].coreClass
          delete this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas[i - 1].coreClass
          delete this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas[i - 1].id
          this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas[i - 1].seqNo = i
          this.prakerjaPayload.kelasRekomendasiPrakerja.kelasRekomendasiItemPrakerjas[i - 1].coreClassId = temp.id
        }
      }
      this.prakerjaPayload.prosesKartuPrakerja = this.prosesKartuPrakerjaPayload
      this.showLoading()
      this.savePrakerjaContent({
        reqBody: self.prakerjaPayload,
        success: res => {
          this.$toast.success('Your Prakerja Data is Successfully Updated!', {
            position: 'top'
          })
          this.hideLoading()
          this.fetchPrakerjaContent({
            success: res => { }
          })
        }
      })
    }
  }
}
