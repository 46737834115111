import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
import axios from 'axios'
export default {
  name: 'documents',
  components: {
    PageTitle: () => import(/* webpackChunkName: "page-title" */ '@/components/others/PageTitle'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/button/Button'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    FindingData: () => import(/* webpackChunkName: "finding-data" */ '@/components/others/FindingData'),
    TableList: () => import(/* webpackChunkName: "table-list" */ '@/components/others/TableList'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField')
  },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    companyName: '-',
    startDate: '',
    endDate: '',
    forEndDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    hide: true,
    enabled: true,
    isLoading: true,
    documentList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Status',
        options: [
          { id: '', name: 'All' },
          { id: 'NEED_APPROVAL', name: 'Need Approval' },
          { id: 'APPROVED', name: 'Approved' },
          { id: 'REJECTED', name: 'Rejected' }
        ]
      },
      {
        label: 'Sort By',
        options: [
          { id: '', name: 'All' },
          { id: 'updatedDate', name: 'Last Modified' },
          { id: 'firstCreated', name: 'First Created' },
          { id: 'lastCreated', name: 'Last Created' }
        ]
      }
    ],
    isVisibleAddDocument: false,
    fileList: [],
    fileName: '',
    typeFileOrImage: '',
    response: { name: '' },
    tmp: '',
    documentTypeOptions: [],
    selectedDocumentType: null,
    documentName: '',
    expiredDate: null,
    temporaryFileUrl: '',
    isVisibleDeleteConfirmation: false,
    selectedDocumentId: null,
    documentMode: '',
    isVisibleEditExpiredDate: false,
    selectedExpiredDate: null,
    newExpiredDate: null
  }),
  computed: {
    disableUpload() {
      return !this.selectedDocumentType || !this.documentName || !this.fileList.length
    },
    mitraStatus() {
      if (this.$route.params.category === 'active-clients') {
        return 'ACTIVE'
      } else if (this.$route.params.category === 'archived-clients') {
        return 'INACTIVE'
      }
    },
    mitraId() {
      return this.$route.params.id
    },
    tomorrow() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().split('T')[0]
    },
    documentType: function() {
      let typeArr = this.$route.params.type?.split('-') || []
      return typeArr[0] === 'KTP' ? typeArr.join('/') : typeArr.join(' ')
    },
    companyNameForPageDesc: function() {
      let forName = this.companyName !== '' ? this.companyName : 'alliance'
      let nameArr = forName.split(' ')
      const check = nameArr.join('-')
      return check.length > 10 ? `${check.substr(0, 8)}..` : check
    },
    forPageName: function() {
      let typeFix = 'All Documents'
      //   const type = this.$route.params.type.split('-')
      //   if (type.length > 1) {
      //     let typeArr = []
      //     for (let j = 0; j < type.length; j++) {
      //       const subLowerCase = type[j]
      //       const subCamelCase = subLowerCase.charAt(0).toUpperCase() + subLowerCase.slice(1)
      //       typeArr.push(subCamelCase)
      //     }
      //     typeFix = typeArr.join(' ')
      //   } else {
      //     typeFix = type[0].charAt(0).toUpperCase() + type[0].slice(1)
      //   }
      return typeFix
    },
    forUserFriendly: function() {
      return this.forOptions[0].options
    },
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          disabled: this.documentType !== 'NDA Document' && this.documentType !== 'MOU Document',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.forOptions[1].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        }
      ]
    },
    forInput: function() {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value)
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.forEndDate,
          writeValue: value => (this.forEndDate = value)
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchDocument(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.hide = true
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        this.filterSelect.forEach(element => {
          this.forOptions.forEach(item => {
            if (element.value !== item.options[0].id) {
              this.hide = false
            }
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getDocumentList()
    this.getDocumentTypeList()
    this.getMitraById()
  },
  methods: {
    ...mapActions(['getAllClientDocuments', 'getMitraDetail', 'updateExpiredDate', 'showLoading', 'hideLoading', 'getDocumentTypes', 'uploadDocument', 'deleteDocument']),
    getMitraById() {
      this.showLoading()
      this.getMitraDetail({
        id: this.$route.params.id,
        success: res => {
          this.companyName = res.companyName
        },
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
        }
      })
    },
    toggleAddDocument() {
      this.isVisibleAddDocument = !this.isVisibleAddDocument
      if (!this.isVisibleAddDocument) {
        this.fileList = []
        this.selectedDocumentType = null
        this.documentName = ''
        this.expiredDate = null
      }
    },
    toggleEditDocument(id) {
      this.selectedExpiredDate = this.documentList.filter(item => item.firstQuery === id)[0]?.fourthColumn
      this.isVisibleEditExpiredDate = !this.isVisibleEditExpiredDate

      if (this.isVisibleEditExpiredDate) {
        this.selectedDocumentId = id
        this.newExpiredDate = null
      }
    },
    toggleDeleteConfirmation(id) {
      this.isVisibleDeleteConfirmation = !this.isVisibleDeleteConfirmation
      if (this.isVisibleDeleteConfirmation) this.selectedDocumentId = id
    },
    changeExpiredDate() {
      this.toggleEditDocument()
      this.showLoading()
      this.updateExpiredDate({
        mitraId: this.mitraId,
        payloads: {
          expirationDate: `${this.newExpiredDate}T00:01:00.000`,
          partnerRegistrationDocumentId: this.selectedDocumentId
        },
        success: () => {
          this.hideLoading()
          notification.successMessage('New expired date is successfully updated!', 3000)
          this.getDocumentList()
        },
        failed: () => {
          this.hideLoading()
          notification.errorMessage('Failed to update the expiration date, please try again later!')
        }
      })
    },
    removeDocument() {
      this.toggleDeleteConfirmation()
      this.showLoading()
      this.deleteDocument({
        mitraId: this.mitraId,
        params: {
          partnerRegistrationDocumentId: this.selectedDocumentId
        },
        success: () => {
          this.hideLoading()
          notification.successMessage('Document is successfully deleted!', 3000)
          this.getDocumentList()
        },
        failed: () => {
          this.hideLoading()
          notification.errorMessage('Failed to delete the document, please try again later!')
        }
      })
    },
    hideModalAddDocument() {
      this.isVisibleAddDocument = false
      this.fileList = []
      this.selectedDocumentType = null
      this.documentName = ''
      this.expiredDate = null
    },
    addDocument() {
      const payloads = {
        temporaryFileUrl: this.temporaryFileUrl,
        registrationDocumentId: this.selectedDocumentType?.id,
        fileName: this.documentName,
        expirationDate: this.expiredDate ? `${this.expiredDate}T00:01:00.000` : ''
      }
      this.uploadDocument({
        mitraId: this.mitraId,
        payloads: payloads,
        success: () => {
          this.hideLoading()
          notification.successMessage('Document is successfully uploaded!', 3000)
          this.hideModalAddDocument()
          this.getDocumentList()
        }
      })
    },
    getDocumentTypeList() {
      this.getDocumentTypes({
        success: res => {
          this.documentTypeOptions = res
        }
      })
    },
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getDocumentList()
    },
    getDocumentList() {
      let sortBy = ''
      let direction = ''
      if (this.filterSelect.length > 0 && this.filterSelect[1]?.value === 'firstCreated') {
        sortBy = 'createdDate'
        direction = 'ASC'
      } else if (this.filterSelect.length > 0 && this.filterSelect[1]?.value === 'lastCreated') {
        sortBy = 'createdDate'
        direction = 'DESC'
      } else if (this.filterSelect.length > 0 && this.filterSelect[1]?.value === 'updatedDate') {
        sortBy = this.filterSelect[1]?.value
        direction = 'DESC'
      } else {
        sortBy = ''
        direction = ''
      }
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getAllClientDocuments({
          id: this.$route.params.id,
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            status: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            sort_by: sortBy,
            direction: direction,
            startDate: this.startDate,
            endDate: this.endDate,
            registrationDocumentName: this.documentType
          },
          success: res => {
            this.distributePayload(res.body.data)
          },
          failed: () => {
            notification.errorMessage('Failed to load data, please try again later!')
            this.hideLoading()
            this.$router.go(-1)
          }
        })
      } else {
        this.showLoading()
        this.getAllClientDocuments({
          id: this.$route.params.id,
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            status: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            sort_by: sortBy,
            direction: direction,
            registrationDocumentName: this.documentType
          },
          success: res => {
            this.distributePayload(res.body.data)
          },
          failed: res => {
            notification.errorMessage('Failed to load data, please try again later!')
            this.hideLoading()
            this.$router.go(-1)
          }
        })
      }
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getDocumentList()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      // this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.documentList = []
      res.data.forEach(doc => {
        this.documentList.push({
          firstQuery: doc.id,
          firstColumn: this.toDocumentName(doc.fileUrl),
          firstColumnParams: `${doc.id}`,
          secondColumn: doc?.registrationDocument?.name,
          fourthColumn: doc.expirationDate ? this.toUserFriendlyTime('date', converter.dateTimeView(doc.expirationDate), undefined) : '-',
          fourthColumnDesc: doc.expirationDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(doc.expirationDate), undefined) : undefined,
          thirdColumn: doc.createdDate ? this.toUserFriendlyTime('date', converter.dateTimeView(doc.createdDate), undefined) : '-',
          thirdColumnDesc: doc.createdDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(doc.createdDate), undefined) : undefined,
          fifthColumn: doc.updatedDate ? this.toUserFriendlyTime('date', converter.dateTimeView(doc.updatedDate), undefined) : '-',
          fifthColumnDesc: doc.updatedDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(doc.updatedDate), undefined) : undefined,
          sixthColumn: doc.updatedBy
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toDocumentName(url) {
      const urlArr = url.split('/')
      return urlArr[urlArr.length - 1]
    },
    toUserFriendly(id) {
      let status = '-'
      if (this.documentType === 'NDA Document' || this.documentType === 'MOU Document') {
        this.forUserFriendly.forEach(element => {
          if (element.id === id) {
            status = element.name
          }
        })
      }
      return status
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    searchDocument(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getDocumentList()
    },
    filter() {
      if (this.forEndDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getDocumentList()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        this.forFilterSelect[i].value = this.forOptions[i].options[0].id
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getDocumentList()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getDocumentList()
    },
    toPage(name, index) {
      switch (index) {
        case 2:
          this.$router.push(`/${this.$route.params.category}`)
          break
        case 4:
          this.$router.push(`/${this.$route.params.category}/detail-client/${this.$route.params.id}`)
          break
        case 6:
          this.isLoading = true
          this.maximalDate = converter.date(new Date())
          this.getDocumentList()
          break
        default:
          this.$router.push('/active-clients')
          break
      }
    },
    toDetailDocument(id) {
      this.$router.push({ path: `/${this.$route.params.category}/detail-client/${this.$route.params.id}/documents/${id}` })
    },
    createFile(file) {
      this.fileName = file.name
      this.typeFileOrImage = file.type
      let reader = new FileReader()
      reader.onload = e => {
        this.response.name = e.target.result
      }
      reader.readAsDataURL(file)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-yellow-200')
      }
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      this.createFile(this.$refs.file.files[0])
    },
    remove(i) {
      this.fileList.splice(i)
    },
    dragFile(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      this.fileList.push(droppedFiles[0])
      this.createFile(this.fileList[0])
    },
    cancelChangeFile() {
      this.fileList = []
    },
    async saveChangeFile() {
      if (!this.fileList.length) {
        alert('File harus di isi')
        return
      }
      this.showLoading()
      await this.upladImageControl(this.response.name)
      this.fileList = []
    },
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeFileOrImage}`
      })
      this.apiImg = data
      let binary = atob(url.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileOrImage })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      }).then(() => {
        this.temporaryFileUrl = `${this.apiImg.uploadUrl}/${this.apiImg.key}`
        this.addDocument()
      })

      // await axios
      //   .post(`${baseUrl}/v2/api/cms/mitra/ACTIVE/import`, payload)
      //   .then(res => {
      //     this.hideLoading()
      //     notification.successMessage('Import Document Success')
      //   })
      //   .catch(() => {
      //     this.hideLoading()
      //     notification.errorMessage('Import Document Failed')
      //   })
    }
  }
}
