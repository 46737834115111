import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'

Vue.use && Vue.use(VueResource)

const state = {
  beasiswaResponse: {}
}
const actions = {
  getBeasiswaContent: ({ commit }, { success }) => {
    Vue.http.get(config.api.beasiswa).then(response => {
      success && success(response.body.data)
      commit('setBeasiswaResponse', response.body.data)
    })
  },
  saveBeasiswaContent: ({ commit }, { reqBody, success }) => {
    Vue.http
      .put(config.api.beasiswa, reqBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setBeasiswaResponse', response.body.data)
      })
  }
}
const mutations = {
  setBeasiswaResponse(state, value) {
    state.beasiswaResponse = value
  }
}
const getters = {
  beasiswaResponse: state => {
    return state.beasiswaResponse
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
