import { mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'seo',
  data: () => ({
    filter: {
      q: ''
    },
    isTableVisible: true,
    isSearchKeyword: false,
    isModalActive: false,
    perPage: 10,
    params: {
      page: 0,
      size: 10
    },
    mainId: null,
    totalRecords: 0,
    currentPage: 1,
    dataOfTable: [],
    requestedDate: null,
    isDisabled: false,
    visibleReindexConfirmation: false
  }),
  computed: {
    columns() {
      return [
        { field: 'url', label: 'URL' },
        { field: 'page_name', label: 'Page Name' },
        { field: 'description', label: 'Description' },
        { field: 'lastUpdatedDate', label: 'Last Update' },
        { field: 'btn', label: 'Action' }
      ]
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "search" */ '@/components/icons/Search'),
    Info: () => import(/* webpackChunkName: "search" */ '@/components/icons/Info'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    ButtonNewClass: () => import(/* webpackChunkName: "buttonNewClas" */ '@/components/button/ButtonNewClass')
  },
  methods: {
    ...mapActions(['getSeoList', 'deleteSeo', 'showLoading', 'hideLoading', 'reIndex']),
    toggleReindexModal() {
      this.visibleReindexConfirmation = !this.visibleReindexConfirmation
    },
    reindex() {
      this.showLoading()
      this.toggleReindexModal()
      this.reIndex({
        payload: {
          params: {
            token: 'glptt-700fe5177837c4e8ca5217f133529c36c0c740ef',
            ref: process.env.VUE_APP_GITLAB_BRANCH,
            'variables[IS_PRERENDERED]': true
          },
          projectId: '28548013'
        },
        success: res => {
          this.hideLoading()
          const data = res.body
          if (data) {
            localStorage.setItem('last_request', data.created_at)
            this.requestedDate = this.formatTime(data.created_at)
          }
          this.$toast.success('Reindex is successfully requested!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.isDisabled = true
        },
        failed: () => {
          this.hideLoading()
          this.$toast.error('Reindex is failed to be executed! Please try again or contact the administrator.', {
            position: 'top',
            queue: true,
            type: 'error'
          })
          this.isDisabled = false
        }
      })
    },
    formatTime(value) {
      return dayjs(String(value)).format('DD-MM-YYYY HH:mm')
    },
    onDelete() {
      this.isModalActive = false
      this.showLoading()
      this.deleteSeo({
        id: this.mainId,
        success: () => {
          this.hideLoading()
          this.$toast.success('SEO successfully deleted!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.fetchSeoList()
        },
        failed: () => {
          this.hideLoading()
        }
      })
    },
    onPageChange(params) {
      this.currentPage = params.currentPage
      this.params.size = params.currentPerPage
      this.fetchSeoList()
    },
    fetchSeoList(search) {
      if (search) {
        this.currentPage = 1
      }
      this.showLoading()
      this.getSeoList({
        payload: {
          params: {
            direction: 'DESC',
            page: this.currentPage - 1,
            size: this.params.size,
            q: this.filter.q
          }
        },
        success: response => {
          this.dataOfTable = response.body.data.map(e => ({
            page_id: e.page_id,
            url: e.url,
            page_name: e.page_name,
            thumbnail_link: e.thumbnail_link,
            description: e.description,
            seeAll: false
          }))
          this.totalRecords = response.body.pagination.total
          this.hideLoading()
        },
        failed: response => {
          this.hideLoading()
        }
      })
    },
    toDetail(id) {
      this.$router.push('/seo/' + id)
    },
    toDelete(id) {
      this.mainId = id
      this.isModalActive = true
    },
    onFocus() {
      this.isSearchKeyword = true
    },
    onSeeAllText(text, seeAll) {
      if (!seeAll && text.length > 100) return text.substring(0, 100) + '...'
      else return text
    },
    toDateManipulate(string, type) {
      if (type) return this.moment(string).format('DD MMM YYYY')
      return this.moment(string).format('HH:mm')
    },
    onBlur() {
      if (this.keyword === '' && !this.isKeywordValid) {
        this.isSearchKeyword = false
      }
    }
  },
  mounted() {
    this.fetchSeoList()
    const lastRequest = localStorage.getItem('last_request')
    if (lastRequest) {
      this.requestedDate = this.formatTime(lastRequest)
      const diff = new Date() - new Date(lastRequest)
      const diffMins = Math.floor(diff / 1000 / 60)
      this.isDisabled = diffMins < 10
    }
  }
}
