import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {
  campaignList: [],
  campaignDetail: {}
}
const actions = {
  getCampaignList: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .get(config.api.campaign.list + serializeQueryParams(payload.params), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          commit('setCampaignList', response.body.data)
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  createCampaign: ({ _ }, { payload, success, failed }) => {
    Vue.http
      .post(config.api.campaign.list, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getCampaignDetail: ({ commit }, { campaignId, success, failed }) => {
    Vue.http
      .get(config.api.campaign.detail(campaignId), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
          commit('setCampaignDetail', response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  deleteCampaign: ({ _ }, { campaignId, success, failed }) => {
    Vue.http
      .delete(config.api.campaign.detail(campaignId), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  updateCampaign: ({ _ }, { campaignId, payloads, success, failed }) => {
    Vue.http
      .put(config.api.campaign.detail(campaignId), payloads, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  downloadReport: ({ _ }, { params, success, failed }) => {
    Vue.http
      .get(config.api.campaign.download + serializeQueryParams(params), {
        headers: config.getAuthHeader(),
        responseType: 'blob'
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}

const mutations = {
  setCampaignList(state, value) {
    state.campaignList = value
  },
  setCampaignDetail(state, value) {
    state.campaignDetail = value
  }
}
const getters = {
  campaignList: state => state.campaignList,
  campaignDetail: state => state.campaignDetail
}

export default {
  state,
  getters,
  actions,
  mutations
}
