export default {
  name: 'page-header',
  props: {
    lastModifiedDate: {
      type: String,
      default: ''
    },
    modifiedBy: {
      type: String,
      default: ''
    },
    pageTitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    isActionAvailable: {
      type: String,
      default: 'false'
    },
    isResetAvailable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    saveData: async function(event) {
      this.$emit('saveData')
    },
    clearData: async function(event) {
      this.$emit('clearData')
    }
  }
}
