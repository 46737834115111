<template>
  <div class="">
    <div class="flex justify-between items-center mb-4">
      <p class="text-3xl font-bold mb-4">Add New Client</p>
      <Button buttonText="Save" type="primary" @action="save()" />
    </div>
    <div class="p-5 border rounded-lg bg-white grid gap-12 grid-cols-2">
      <div class="">
        <div class="mb-4">
          <InputWithLabel
            type="text"
            labelText="Company Name"
            placeholder="Enter company name"
            :readValue="payload.companyName"
            :writeValue="value => (payload.companyName = value)"
            :errorValue="errors['companyName']"
            required
          />
        </div>
        <div class="mb-4">
          <InputWithLabel
            type="text"
            labelText="PIC Name"
            placeholder="Enter pic name"
            :readValue="payload.picName"
            :writeValue="value => (payload.picName = value)"
            :errorValue="errors['picName']"
            required
          />
        </div>
        <div class="mb-4">
          <InputWithLabel
            type="text"
            labelText="PIC Email"
            placeholder="Enter pic email"
            :readValue="payload.picEmail"
            :writeValue="value => (payload.picEmail = value)"
            :errorValue="errors['picEmail']"
            required
          />
        </div>
        <div class="mb-4">
          <InputWithLabel
            type="number"
            labelText="PIC Phone Number"
            placeholder="08xxxx"
            maxlength="15"
            autocomplete="off"
            :readValue="payload.phoneNumber"
            :writeValue="value => (payload.phoneNumber = value)"
            :errorValue="errors['phoneNumber']"
            showErrorValue
            required
          />
        </div>
        <div class="mb-4">
          <div class="source">
            <SelectWithLabel labelText="Source" :options="mitraSource" :readValue="source" :writeValue="value => (source = value)" />
          </div>
        </div>
        <div class="mb-4">
          <div class="description">
            <TextareaWithLabel labelText="Description" resize="resize-none" :readValue="description" :writeValue="value => (description = value)" />
          </div>
        </div>
      </div>
      <div class="">
        <label class="text-sm pl-2 text-custom-black-1">Cooperation Type (you can choose more than one)</label>
        <div class="mt-5">
          <div class="inline-grid grid-cols-2 gap-x-7">
            <div v-for="(type, index) in cooperation" :key="'type' + index">
              <label class="ctnr">
                {{ type.label }}
                <input type="checkbox" v-model="selectedCooperationType" :id="type.id" :value="type.id" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { isNotEmptyError } from '@/util/form-validator'
import { notification } from '@/util'
const CONFIG = {
  companyName: 'Company Name',
  picName: 'PIC Name',
  picEmail: 'PIC Email',
  phoneNumber: 'Phone Number'
}
export default {
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/forms/InputWithLabel'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/forms/SelectWithLabel'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/components/forms/TextareaWithLabel'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/button/Button')
  },
  data() {
    return {
      mitraSource: [],
      mitraSourceOriginal: [],
      source: 'Other',
      payload: {
        companyName: '',
        picName: '',
        picEmail: '',
        phoneNumber: ''
      },
      description: '',
      selectedCooperationType: [],
      cooperation: [
        {
          id: 'EDUCATION',
          label: 'Education'
        },
        {
          id: 'LMS',
          label: 'LMS'
        },
        {
          id: 'MANPOWER',
          label: 'Manpower'
        },
        {
          id: 'MARKETPLACE',
          label: 'Marketplace'
        },
        {
          id: 'PROJECT',
          label: 'Project'
        },
        {
          id: 'LP_CURATION',
          label: 'LP Curation'
        }
      ],
      errors: [],
      phoneInputType: 'number'
    }
  },
  computed: {
    mode() {
      return this.$route.meta.mode
    },
    phoneNumber() {
      return this.payload.phoneNumber
    }
  },
  watch: {
    phoneNumber(value) {
      if (value.length >= 15) {
        this.payload.phoneNumber = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value[0] !== '0' || value[1] !== '8') {
        this.errors['phoneNumber'] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.errors['phoneNumber'] = false
      }
    }
  },
  created() {
    this.getMitraSource()
  },
  methods: {
    ...mapActions(['getAllSource', 'createMitra', 'showLoading', 'hideLoading']),
    getMitraSource() {
      this.getAllSource({
        params: {
          sortBy: 'sourceName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraSource.push({
              id: item.source_name,
              name: item.source_name
            })
          })
          this.mitraSourceOriginal = res
        }
      })
    },
    save() {
      if (this.validForm()) {
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!regex.test(this.payload.picEmail)) {
          this.errors['picEmail'] = 'Your email format is invalid!'
          notification.errorMessage('Email format is invalid!')
        } else if (this.payload.phoneNumber[0] !== '0' || this.payload.phoneNumber[1] !== '8') {
          this.errors['phoneNumber'] = 'Must start with 08xxxx'
        } else if (this.payload.phoneNumber.length < 10) {
          this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
        } else {
          const payloads = {
            companyName: this.payload.companyName,
            mitraKerjasamas: this.selectedCooperationType.map(item => {
              return { name: item }
            }),
            contacts: [{
              picName: this.payload.picName,
              picEmail: this.payload.picEmail,
              phoneNumber: this.payload.phoneNumber,
              primaryContact: true
            }],
            phoneNumber: this.payload.phoneNumber,
            picEmail: this.payload.picEmail,
            picName: this.payload.picName,
            mitraDescription: this.description,
            sourceId: this.mitraSourceOriginal.filter(item => item.source_name === this.source)[0]?.id
          }
          this.showLoading()
          this.createMitra({
            payload: payloads,
            success: () => {
              notification.successMessage('Client is successfully added!')
              this.hideLoading()
              this.$router.push('/active-clients')
            },
            failed: () => {
              notification.errorMessage('Client is failed to be added! Please try again.')
              this.hideLoading()
            }
          })
        }
      } else {
        notification.errorMessage('Failed to add new client, please make sure all required information is provided!')
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payload)
      this.errors = error.reduce(function(map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
<style lang="scss" scoped>
.ctnr {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  --tw-text-opacity: 1;
  font-size: 0.875rem; /* 14px */
  line-height: 1.45rem; /* 20px */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctnr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  margin: 2px 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.ctnr:hover input ~ .checkmark {
  background-color: rgb(243, 244, 246);
}

/* When the checkbox is checked, add a blue background */
.ctnr input:checked ~ .checkmark {
  background-color: #f7931e;
  border: 1px solid #f7931e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ctnr input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ctnr .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
