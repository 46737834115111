<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="M386,30l9,1,6,6c0.425,19.888-5.381,50.639-16,61-7.919.155-10.588-1.273-15-4C365.692,77.668,374.539,38.568,386,30ZM259,50c40.24,0.138,47.24,66.384,71,87,9.294-3.306,18.9-11.217,34-8,20.306,4.326,43.123,25.608,34,55-4.581,14.758-16.044,19.077-25,29,8.987,18.253,73.571,101,30,112-28.564,7.215-44.669-42.247-54-58L257,110C242.713,86.4,230.03,68.1,259,50ZM474,95l9,1,6,6q-0.5,4.5-1,9c-3.148,7.053-44.217,32.775-54,29l-7-6c-0.213-9.09,1.514-10.367,5-15Zm-249,9c16.194,13.678,24.893,42.428,36,61l89,154c-40.927,4.784-78.374,13.745-112,27l-44,18v-2L109,216l34-26C172.777,167.149,204.454,135.875,225,104Zm217,86c19.567-.26,44.9,9.349,58,18q1,5,2,10l-4,7c-17.521,5.192-52.968-8.389-63-19q1-6.5,2-13ZM87,228c13.045,11.528,19.914,33.683,29,49,14.289,24.086,51.17,74.175,57,100-21.552,10.99-40.823,28.946-67,34C49.037,422-5.652,330.857,16,282,28.7,253.349,63.566,246.043,87,228ZM199,389c11.162,1.589,55.454,47.591,56,61-5.1,12.336-21.253,31.552-38,32-18.561-8.881-53.5-46.337-66-63l1-3Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
