const PageHeader = () =>
  import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
const PageCore = () =>
  import(/* webpackChunkName: "page-core" */ '@/components/PageCore')
const PageSlideBanner = () =>
  import(/* webpackChunkName: "slide-banner" */ '@/components/PageSlideBanner')
const PageBusiness = () =>
  import(/* webpackChunkName: "our-strenght" */ '@/components/PageBusiness')
const PageJaringanKerjasama = () =>
  import(
    /* webpackChunkName: "page-business-partner" */ '@/components/PageJaringanKerjasama')
const PageHiringPartner = () =>
  import(/* webpackChunkName: "testimoni" */ '@/components/HiringPartner')
export default {
  name: 'homepage',
  components: {
    PageHeader,
    PageCore,
    PageSlideBanner,
    PageBusiness,
    PageJaringanKerjasama,
    PageHiringPartner
  },
  data: () => ({
    slideBannerPayload: [],
    corePayload: {},
    jaringanKerjasamaPayload: {},
    homepagePayload: {}
  })
}
