import VueSingleSelect from 'vue-single-select'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
const ClassDescription = () => import(/* webpackChunkName: "ClassDescription" */ '@/components/ClassDescription')
const CurrencyInput = () => import(/* webpackChunkName: "CurrencyInput" */ '@/components/forms/CurrencyInput')
const ClassSyllabus = () => import(/* webpackChunkName: "ClassSyllabus" */ '@/components/ClassSyllabus')
export default {
  name: 'create-class',
  components: {
    VueSingleSelect,
    ClassDescription,
    CurrencyInput,
    ClassSyllabus,
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown')
  },
  data: () => ({
    maxResults: 100,
    classTypeOptions: ['Prakerja', 'Bootcamp', 'International', 'Regular'],
    layoutOptions: [
      {
        name: 'Event',
        value: 'EVENT_LAYOUT'
      },
      {
        name: 'Activity',
        value: 'CLASS_LAYOUT'
      }
    ],
    classType: '',
    payload: {
      name: '',
      location: '',
      type: '',
      price: '',
      slug: '',
      schedule: '',
      scheduleFrom: '',
      scheduleTo: '',
      durationText: '',
      shortDescriptionText: '',
      techStacksText: '',
      benefitText: '',
      categoryText: '',
      layoutType: ''
    },
    descriptionPayload: {
      classValue: '',
      description: '',
      prasyarat: '',
      note: '',
      url1: '',
      url2: '',
      knowledgeCompetence: '',
      skillCompetence: '',
      attitudeCompetence: '',
      targetParticipant: '',
      purpose: '',
      capacity: '',
      learningMethod: '',
      certificateClarification: '',
      activity: ''
    },
    syllabusPayload: [],
    syllabusDocument: '',
    historyAlumniClasses: [],
    scheduledPrices: [],
    instructorClasses: [],
    testimonyOptions: [],
    instructorOptions: [],
    promoPriceValue: false,
    promoPriceTime: false,
    promoPriceDate: false,
    customerTypeSelected: ''
  }),
  computed: {
    ...mapGetters(['instructorList', 'testimonyList', 'classDetail']),
    classId() {
      return this.$route.params.id
    },
    classTypeSelected() {
      return this.payload.type
    },
    getCustomerTypeName() {
      let name = null
      switch (this.customerTypeSelected) {
        case 1:
          name = 'G2 Academy'
          break
        case 2:
          name = 'Accelerice'
          break
        case 3:
          name = 'Clouducation'
          break
        default:
          break
      }
      return name
    }
  },
  watch: {
    scheduledPrices: {
      handler: function () {
        if (this.scheduledPrices.length > 0) {
          this.scheduledPrices.forEach(item => {
            if (item.withTime && (!item.startDate || !item.endDate || item.startDate === '' || item.endDate === '')) {
              this.promoPriceTime = true
            } else {
              this.promoPriceTime = false
            }
            if ((item.price && !this.payload.price) || item.price >= this.payload.price) {
              this.promoPriceValue = true
            } else {
              this.promoPriceValue = false
            }
            if (moment(item.startDate).isBefore(new Date()) || moment(item.startDate).isAfter(item.endDate)) {
              this.promoPriceDate = true
            } else {
              this.promoPriceDate = false
            }
          })
        } else {
          this.promoPriceTime = false
          this.promoPriceValue = false
          this.promoPriceDate = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getInstructor()
  },
  methods: {
    ...mapActions(['getInstructorList', 'getTestimonyList', 'getClassDetail', 'showLoading', 'hideLoading', 'updateClass']),
    minimalstartDate(index) {
      if (index === 0) {
        return moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD')
      } else {
        return moment(this.scheduledPrices[index - 1].endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    forDisableStartDate(index) {
      return index === 0 ? false : this.scheduledPrices[index - 1].endDate === ''
    },
    getClassDetailById(testimony, instructor) {
      this.showLoading()
      this.getClassDetail({
        id: this.classId,
        success: res => {
          this.distributePayload(res, testimony, instructor)
        }
      })
    },
    distributePayload(res, testimony, instructor) {

      const layoutType = this.layoutOptions.filter(item => item.value === res.layoutType)[0]

      this.payload = {
        name: res.name,
        location: res.location || '',
        type: res.type || '',
        price: res.price,
        slug: res.slug,
        schedule: res.schedule,
        scheduleFrom: res.scheduleFrom,
        scheduleTo: res.scheduleTo,
        durationText: res.durationText,
        shortDescriptionText: res.shortDescriptionText,
        techStacksText: res.techStacksText,
        benefitText: res.benefitText,
        categoryText: res.categoryText,
        layoutType: layoutType
      }
      this.syllabusDocument = res.syllabus
      this.descriptionPayload = {
        classValue: res.classValue,
        description: res.description,
        prasyarat: res.prasyarat,
        note: res.note,
        url1: res.url1,
        url2: res.url2,
        knowledgeCompetence: res.knowledgeCompetence,
        skillCompetence: res.skillCompetence,
        attitudeCompetence: res.attitudeCompetence,
        targetParticipant: res.targetParticipant,
        purpose: res.purpose,
        capacity: res.capacity,
        learningMethod: res.learningMethod,
        certificateClarification: res.certificateClarification,
        activity: res.activity
      }
      this.customerTypeSelected = res.customerId
      this.getInstructor(res.customerId)
      if (res.sylabusClasses) {
        this.syllabusPayload = res.sylabusClasses
      }

      const testimoni =
        res.historyAlumniClasses &&
        res.historyAlumniClasses.map(function (item) {
          item.testimony = testimony.filter(el => {
            return el.id === item.testimony.id
          })[0]
          return item
        })
      this.historyAlumniClasses = testimoni
      const prices =
        res.scheduledPrices &&
        res.scheduledPrices.map(function (item) {
          if (item.startDate && item.startDate !== '') item.startDate = moment(item.startDate).format('YYYY-MM-DD')
          if (item.endDate && item.endDate !== '') item.endDate = moment(item.endDate).format('YYYY-MM-DD')
          item.startDate && item.endDate && item.startDate !== '' && item.endDate !== '' ? (item.withTime = true) : (item.withTime = false)
          return item
        })
      this.scheduledPrices = prices
      const intructorObj =
        res.instructorClasses &&
        res.instructorClasses.map(function (item) {
          item.expert = instructor.filter(el => {
            return el.id === item.expert.id
          })[0]
          return item
        })
      this.instructorClasses = intructorObj
      this.hideLoading()
    },
    getInstructor(customerId) {
      this.getInstructorList({
        success: res => {
          if (customerId) {
            this.instructorOptions = []
            this.instructorOptions = res.filter(person => person.customer?.id === customerId)
            return
          }
          this.instructorOptions = res
          this.getTestimony(res)
        }
      })
    },
    getTestimony(intructorList) {
      this.getTestimonyList({
        success: res => {
          this.testimonyOptions = res
          this.getClassDetailById(res, intructorList)
        }
      })
    },
    addMoreItem(objContent, type) {
      const obj = objContent === undefined ? [] : objContent
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      let item = {}
      if (type === 'prices') {
        item = {
          coreClassId: this.classId,
          price: 0,
          startDate: '',
          endDate: '',
          active: true,
          withTime: false
        }
      } else {
        item = { seqNo: lastObj.seqNo + 1 }
        item[type] = ''
      }
      obj.push(item)
      if (objContent === undefined) {
        switch (type) {
          case 'testimony':
            this.historyAlumniClasses = obj
            break
          case 'prices':
            this.scheduledPrices = obj
            break
          default:
            this.instructorClasses = obj
            break
        }
      }
    },
    deleteItem(objContent, idx) {
      objContent.splice(idx, 1)
    },
    changeCheck(idx) {
      this.scheduledPrices[idx].withTime = !this.scheduledPrices[idx].withTime
      if (this.scheduledPrices[idx].withTime === false) {
        this.scheduledPrices[idx].startDate = ''
        this.scheduledPrices[idx].endDate = ''
      }
    },
    goBack() {
      this.$router.push('/daftar-kelas')
    },
    save() {
      this.showLoading()
      if (this.promoPriceTime) {
        this.$toast.error('Please fill the required field!', { position: 'top' })
      } else if (this.promoPriceValue) {
        this.$toast.error('Promo price higher or equal than listing price!', { position: 'top' })
      } else if (this.promoPriceDate) {
        this.$toast.error('Please set the date of promo price correctly!', { position: 'top' })
      } else {
        const requestBody = this.constructPayload()
        this.updateClass({
          id: this.classId,
          requestBody: requestBody,
          success: res => {
            if (res.code === 200) {
              this.$toast.success('Your Class Data is Successfully Updated!', { position: 'top' })
              this.goBack()
            } else {
              this.$toast.error('Please check the value and try again!', { position: 'top' })
            }
          }
        })
      }
      setTimeout(() => this.hideLoading(), 3500)
    },
    constructPayload() {
      let testimoni = []
      let instructor = []
      let prices = []
      if (this.historyAlumniClasses) {
        testimoni = JSON.parse(JSON.stringify(this.historyAlumniClasses))
        testimoni.map(function (item) {
          item.testimonyId = item.testimony.id
          delete item.testimony
          return item
        })
      }
      if (this.scheduledPrices) {
        prices = JSON.parse(JSON.stringify(this.scheduledPrices))
        prices.map(function (item) {
          item.startDate && item.startDate !== '' ? (item.startDate = moment(item.startDate + ' ' + '00:00:00').format('YYYY-MM-DDTHH:mm:ss+0700')) : (item.startDate = '')
          item.endDate && item.endDate !== '' ? (item.endDate = moment(item.endDate + ' ' + '23:59:59').format('YYYY-MM-DDTHH:mm:ss+0700')) : (item.endDate = '')
          delete item.withTime
          return item
        })
      }
      if (this.instructorClasses) {
        instructor = JSON.parse(JSON.stringify(this.instructorClasses))
        instructor.map(function (item) {
          item.expertId = item.expert.id
          delete item.id
          delete item.expert
          return item
        })
      }
      const constructedPayload = {
        customerId: this.customerTypeSelected,
        classValue: this.descriptionPayload.classValue,
        description: this.descriptionPayload.description,
        historyAlumniClasses: testimoni,
        scheduledPrices: prices,
        instructorClasses: instructor,
        name: this.payload.name,
        note: this.descriptionPayload.note,
        prasyarat: this.descriptionPayload.prasyarat,
        price: parseInt(this.payload.price),
        schedule: this.payload.schedule,
        scheduleFrom: this.payload.scheduleFrom,
        scheduleTo: this.payload.scheduleTo,
        location: this.payload.location,
        type: this.payload.type,
        seqNo: 0,
        slug: this.payload.slug,
        durationText: this.payload.durationText,
        shortDescriptionText: this.payload.shortDescriptionText,
        techStacksText: this.payload.techStacksText,
        benefitText: this.payload.benefitText,
        categoryText: this.payload.categoryText,
        syllabus: this.syllabusDocument,
        sylabusClasses: this.syllabusPayload,
        url1: this.descriptionPayload.url1,
        url2: this.descriptionPayload.url2,
        knowledgeCompetence: this.descriptionPayload.knowledgeCompetence,
        skillCompetence: this.descriptionPayload.skillCompetence,
        attitudeCompetence: this.descriptionPayload.attitudeCompetence,
        targetParticipant: this.descriptionPayload.targetParticipant,
        purpose: this.descriptionPayload.purpose,
        capacity: this.descriptionPayload.capacity,
        learningMethod: this.descriptionPayload.learningMethod,
        certificateClarification: this.descriptionPayload.certificateClarification,
        activity: this.descriptionPayload.activity,
        layoutType : this.payload.layoutType.value
      }
      return constructedPayload
    }
  }
}
