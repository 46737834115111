import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {}
const actions = {
  postMarketplace: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(config.api.marketplace, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  editMarketplace: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .put(config.api.marketplace, payload, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getMarketplaceBanners: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.marketplace}/banners${serializeQueryParams(params)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data.items)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getAllMarketplaceList: ({ commit }, { success, failed }) => {
    Vue.http
      .get(`${config.api.store}s`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getAllMarketplaceById: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.store}s/${params}/general-info`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getCategoryMarketplaceDetail: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.store}s/${params}/category-tree`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  sendCategoryStroreDetail: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .post(`${config.api.store}s/${payload.params}/categories`, payload.body, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  editCategoryDetail: ({ commit }, { payload, success, failed }) => {
    Vue.http
      .put(
        `${config.api.store}s/${payload.params}/categories`,
        { items: payload.body },
        {
          headers: config.getAuthHeader()
        }
      )
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getCategoryStrore: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.store}s/${params}/categories`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getAllStoreMarketplaceList: ({ commit }, { data, success, failed }) => {
    Vue.http
      .get(`${config.api.store}s/${data.params}/classes${serializeQueryParams(data.query)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getClassIdValue: ({ commit }, { data, success, failed }) => {
    Vue.http
      .get(`${config.api.store}s/${data.params}/class-id-value?categoryId=${data.query}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  changeStatusStoreApprove: ({ commit }, { params, success, failed }) => {
    Vue.http
      .post(
        `${config.api.store}-classes/${params}/approve`,
        {},
        {
          headers: config.getAuthHeader()
        }
      )
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  changeStatusStoreRejeect: ({ commit }, { params, success, failed }) => {
    Vue.http
      .post(
        `${config.api.store}-classes/${params}/reject`,
        {},
        {
          headers: config.getAuthHeader()
        }
      )
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  changeStatusStore: ({ commit }, { data, success, failed }) => {
    Vue.http
      .put(
        `${config.api.store}-classes/${data.params}/isPublished`,
        {
          isPublished: data.isPublished
        },
        {
          headers: config.getAuthHeader()
        }
      )
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getEducationalIstitution: ({ commit }, { success, failed }) => {
    Vue.http
      .get(config.api.educational, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getMarketplaceClassList: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.marketplace}/groups${serializeQueryParams(params)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body.data.items)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}
const mutations = {}
const getters = {}

export default {
  state,
  getters,
  actions,
  mutations
}
