import ImageUploadMixin from '@/mixins/image-upload'
import { checkDimension } from '@/util/image-validator'
export default {
  name: 'slide-banner',
  mixins: [ImageUploadMixin],
  data: () => ({
    image: ''
  }),
  props: {
    banners: {
      type: Array,
      default: () => []
    },
    dimensionText: {
      type: String,
      default: '1920x1080'
    },
    imageDimension: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    sequenceOptions() {
      return Array.from({ length: this.banners.length }, (_, i) => i + 1)
    }
  },
  methods: {
    addMoreBanner() {
      if (this.banners.length !== 0) {
        const item = this.banners[this.banners.length - 1]
        if (item.url === '' || item.title === '' || item.slug === '') {
          this.$toast.warning('Banner Image, Title and Slug Cannot Be Empty!', {
            position: 'top'
          })
          return
        }
      }
      const newItem = {
        url: '',
        title: '',
        slug: '',
        name: 'unnamed',
        seqNo: this.banners.length + 1
      }
      this.banners.push(newItem)
    },
    deleteBanner(idx) {
      this.banners.splice(idx, 1)
    },
    handleFileSelect(evt, item) {
      if (this.imageDimension === null) {
        this.uploadImage(evt, item)
        return
      }
      checkDimension(evt.target.files[0], this.imageDimension.width, this.imageDimension.height).then(res => {
        if (res.isValid) {
          this.uploadImage(evt, item)
        } else {
          this.$toast.warning(`Image dimension (${res.width}x${res.height}) is not valid! `, {
            position: 'top'
          })
        }
      })
    },
    changeImage(item, idx) {
      this.$refs['fileslide' + idx][0].click()
    }
  }
}
