import VueSingleSelect from 'vue-single-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'prakerja-class',
  components: {
    VueSingleSelect,
    SearchInput: () => import(/* webpackChunkName: "SearchInput" */ '@/components/forms/SearchInput')
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    },
    classList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isFetching: false,
      isSearching: false,
      classKeyword: '',
      debounce: null
    }
  },
  methods: {
    ...mapActions(['getClassList', 'showLoading', 'hideLoading']),
    addMoreItem() {
      const item = {
        seqNo: 0,
        coreClassId: ''
      }
      this.payload.items.push(item)
    },
    deleteItem(idx) {
      this.payload.items.splice(idx, 1)
    },
    async searchClass(keyword) {
      const result = await new Promise((resolve, reject) => {
        this.getClassList({
          params: {
            page: 0,
            size: 1000,
            program: 'Prakerja',
            name: keyword
          },
          success: res => {
            this.searchResult = res.data
            resolve(res.data)
          }
        })
      })
      return result
    }
  }
}
