<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0191 8.6544C18.9268 8.56083 18.875 8.43464 18.875 8.30316V3.5625C18.875 3.28636 18.6511 3.0625 18.375 3.0625H15.9375C15.6614 3.0625 15.4375 3.28636 15.4375 3.5625V5.34436C15.4375 5.43173 15.3333 5.47708 15.2694 5.41756L12.3419 2.69317C12.1494 2.51405 11.8511 2.5146 11.6593 2.69442L1.92242 11.8227C1.59174 12.1327 1.81112 12.6875 2.26439 12.6875H3.25C3.52614 12.6875 3.75 12.9114 3.75 13.1875V21.125C3.75 21.4011 3.97386 21.625 4.25 21.625H9.4375C9.71364 21.625 9.9375 21.4011 9.9375 21.125V15.25C9.9375 14.9739 10.1614 14.75 10.4375 14.75H13.5625C13.8386 14.75 14.0625 14.9739 14.0625 15.25V21.125C14.0625 21.4011 14.2864 21.625 14.5625 21.625H19.75C20.0261 21.625 20.25 21.4011 20.25 21.125V13.1875C20.25 12.9114 20.4739 12.6875 20.75 12.6875H21.8039C22.2478 12.6875 22.4716 12.1522 22.1598 11.8363L19.0191 8.6544Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
