// import { mapGetters, mapActions } from 'vuex'
import { mapActions, mapGetters } from 'vuex'
import { generateName } from '@/util/name-generator'

const PageHeader = () =>
  import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
const PageCore = () =>
  import(/* webpackChunkName: "page-core" */ '@/components/PageCore')
const ScholarshipG2 = () =>
  import(
    /* webpackChunkName: "page-scholarship-g2" */ '@/components/ScholarshipG2'
  )
const ScholarshipPartner = () =>
  import(
    /* webpackChunkName: "scholarship-partner" */ '@/components/ScholarshipPartner'
  )
export default {
  name: 'scholarship',
  components: {
    PageHeader,
    PageCore,
    ScholarshipG2,
    ScholarshipPartner
  },
  data: () => ({
    corePayload: {},
    beasiswaG2Payload: {
      title: '',
      items: [
        {
          registrationProcesses: [{
            icon: '',
            text: ''
          }]
        }
      ]
    },
    beasiswaMitraPayload: {
      title: '',
      items: [{
        registrationProcesses: [{
          icon: '',
          text: ''
        }]
      }]
    },
    beasiswaPayload: {
      beasiswaG2Request: {},
      beasiswaMitraRequest: {}
    }
  }),
  computed: {
    ...mapGetters(['beasiswaResponse'])
  },
  mounted() {
    this.fetchBeasiswaResponse()
  },
  methods: {
    ...mapActions([
      'getBeasiswaContent',
      'saveBeasiswaContent',
      'showLoading',
      'hideLoading'
    ]),
    fetchBeasiswaResponse() {
      this.getBeasiswaContent({
        success: res => {
          this.distributeBeasiswaPayload(res)
        }
      })
    },
    distributeBeasiswaPayload(res) {
      this.corePayload = {
        slug: res.slug,
        statusId: res.statusId,
        subTitle: res.subTitle,
        title: res.title,
        url: res.url,
        seoDescription: res.seoDescription
      }

      this.beasiswaG2Payload.title = res.beasiswaG2.title
      if (res.beasiswaG2.beasiswaG2ItemResponses === undefined) {
        this.beasiswaG2Payload.items = []
      } else {
        // resolve icon and text data from BE
        for (let i = 0; i < res.beasiswaG2.beasiswaG2ItemResponses.length; i++) {
          res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses = []
          if (res.beasiswaG2.beasiswaG2ItemResponses[i].icon1 !== '') {
            res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaG2.beasiswaG2ItemResponses[i].icon1,
              text: res.beasiswaG2.beasiswaG2ItemResponses[i].text1
            })
          }
          if (res.beasiswaG2.beasiswaG2ItemResponses[i].icon2 !== '') {
            res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaG2.beasiswaG2ItemResponses[i].icon2,
              text: res.beasiswaG2.beasiswaG2ItemResponses[i].text2
            })
          }
          if (res.beasiswaG2.beasiswaG2ItemResponses[i].icon3 !== '') {
            res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaG2.beasiswaG2ItemResponses[i].icon3,
              text: res.beasiswaG2.beasiswaG2ItemResponses[i].text3
            })
          }
          if (res.beasiswaG2.beasiswaG2ItemResponses[i].icon4 !== '') {
            res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaG2.beasiswaG2ItemResponses[i].icon4,
              text: res.beasiswaG2.beasiswaG2ItemResponses[i].text4
            })
          }
          if (res.beasiswaG2.beasiswaG2ItemResponses[i].icon5 !== '') {
            res.beasiswaG2.beasiswaG2ItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaG2.beasiswaG2ItemResponses[i].icon5,
              text: res.beasiswaG2.beasiswaG2ItemResponses[i].text5
            })
          }
        }

        this.beasiswaG2Payload.items =
          res.beasiswaG2.beasiswaG2ItemResponses
      }

      this.beasiswaMitraPayload.title = res.beasiswaMitra.title
      if (res.beasiswaMitra.beasiswaMitraItemResponses === undefined) {
        this.beasiswaMitraPayload.items = []
      } else {
        // resolve icon and text data from BE
        for (let i = 0; i < res.beasiswaMitra.beasiswaMitraItemResponses.length; i++) {
          res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses = []
          if (res.beasiswaMitra.beasiswaMitraItemResponses[i].icon1 !== '') {
            res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaMitra.beasiswaMitraItemResponses[i].icon1,
              text: res.beasiswaMitra.beasiswaMitraItemResponses[i].text1
            })
          }
          if (res.beasiswaMitra.beasiswaMitraItemResponses[i].icon2 !== '') {
            res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaMitra.beasiswaMitraItemResponses[i].icon2,
              text: res.beasiswaMitra.beasiswaMitraItemResponses[i].text2
            })
          }
          if (res.beasiswaMitra.beasiswaMitraItemResponses[i].icon3 !== '') {
            res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaMitra.beasiswaMitraItemResponses[i].icon3,
              text: res.beasiswaMitra.beasiswaMitraItemResponses[i].text3
            })
          }
          if (res.beasiswaMitra.beasiswaMitraItemResponses[i].icon4 !== '') {
            res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaMitra.beasiswaMitraItemResponses[i].icon4,
              text: res.beasiswaMitra.beasiswaMitraItemResponses[i].text4
            })
          }
          if (res.beasiswaMitra.beasiswaMitraItemResponses[i].icon5 !== '') {
            res.beasiswaMitra.beasiswaMitraItemResponses[i].registrationProcesses.push({
              icon: res.beasiswaMitra.beasiswaMitraItemResponses[i].icon5,
              text: res.beasiswaMitra.beasiswaMitraItemResponses[i].text5
            })
          }
        }

        this.beasiswaMitraPayload.items =
          res.beasiswaMitra.beasiswaMitraItemResponses
      }
    },
    saveScholarshipData() {
      let self = this
      // resolve core
      this.beasiswaPayload.slug = this.corePayload.slug
      this.beasiswaPayload.statusId = this.corePayload.statusId
      this.beasiswaPayload.subTitle = this.corePayload.subTitle
      this.beasiswaPayload.title = this.corePayload.title
      this.beasiswaPayload.url = this.corePayload.url
      this.beasiswaPayload.seqNo = 0
      this.beasiswaPayload.seoDescription = this.corePayload.seoDescription

      // resolve beasiswaG2Request
      for (let i = 0; i < this.beasiswaG2Payload.items.length; i++) {
        if (this.beasiswaG2Payload.items[i].registrationProcesses[0]) {
          this.beasiswaG2Payload.items[i].icon1 = this.beasiswaG2Payload.items[i].registrationProcesses[0].icon
          this.beasiswaG2Payload.items[i].text1 = this.beasiswaG2Payload.items[i].registrationProcesses[0].text
        } else {
          this.beasiswaG2Payload.items[i].icon1 = ''
          this.beasiswaG2Payload.items[i].text1 = ''
        }
        if (this.beasiswaG2Payload.items[i].registrationProcesses[1]) {
          this.beasiswaG2Payload.items[i].icon2 = this.beasiswaG2Payload.items[i].registrationProcesses[1].icon
          this.beasiswaG2Payload.items[i].text2 = this.beasiswaG2Payload.items[i].registrationProcesses[1].text
        } else {
          this.beasiswaG2Payload.items[i].icon2 = ''
          this.beasiswaG2Payload.items[i].text2 = ''
        }
        if (this.beasiswaG2Payload.items[i].registrationProcesses[2]) {
          this.beasiswaG2Payload.items[i].icon3 = this.beasiswaG2Payload.items[i].registrationProcesses[2].icon
          this.beasiswaG2Payload.items[i].text3 = this.beasiswaG2Payload.items[i].registrationProcesses[2].text
        } else {
          this.beasiswaG2Payload.items[i].icon3 = ''
          this.beasiswaG2Payload.items[i].text3 = ''
        }
        if (this.beasiswaG2Payload.items[i].registrationProcesses[3]) {
          this.beasiswaG2Payload.items[i].icon4 = this.beasiswaG2Payload.items[i].registrationProcesses[3].icon
          this.beasiswaG2Payload.items[i].text4 = this.beasiswaG2Payload.items[i].registrationProcesses[3].text
        } else {
          this.beasiswaG2Payload.items[i].icon4 = ''
          this.beasiswaG2Payload.items[i].text4 = ''
        }
        if (this.beasiswaG2Payload.items[i].registrationProcesses[4]) {
          this.beasiswaG2Payload.items[i].icon5 = this.beasiswaG2Payload.items[i].registrationProcesses[4].icon
          this.beasiswaG2Payload.items[i].text5 = this.beasiswaG2Payload.items[i].registrationProcesses[4].text
        } else {
          this.beasiswaG2Payload.items[i].icon5 = ''
          this.beasiswaG2Payload.items[i].text5 = ''
        }
        delete this.beasiswaG2Payload.items[i].registrationProcesses

        this.beasiswaG2Payload.items[i].name = generateName(this.beasiswaG2Payload.items[i].title)
      }

      this.beasiswaPayload.beasiswaG2Request.title = this.beasiswaG2Payload.title
      this.beasiswaPayload.beasiswaG2Request.beasiswaItemG2s = this.beasiswaG2Payload.items

      // resolve beasiswaMitraRequest
      for (let i = 0; i < this.beasiswaMitraPayload.items.length; i++) {
        if (this.beasiswaMitraPayload.items[i].registrationProcesses[0]) {
          this.beasiswaMitraPayload.items[i].icon1 = this.beasiswaMitraPayload.items[i].registrationProcesses[0].icon
          this.beasiswaMitraPayload.items[i].text1 = this.beasiswaMitraPayload.items[i].registrationProcesses[0].text
        } else {
          this.beasiswaMitraPayload.items[i].icon1 = ''
          this.beasiswaMitraPayload.items[i].text1 = ''
        }
        if (this.beasiswaMitraPayload.items[i].registrationProcesses[1]) {
          this.beasiswaMitraPayload.items[i].icon2 = this.beasiswaMitraPayload.items[i].registrationProcesses[1].icon
          this.beasiswaMitraPayload.items[i].text2 = this.beasiswaMitraPayload.items[i].registrationProcesses[1].text
        } else {
          this.beasiswaMitraPayload.items[i].icon2 = ''
          this.beasiswaMitraPayload.items[i].text2 = ''
        }
        if (this.beasiswaMitraPayload.items[i].registrationProcesses[2]) {
          this.beasiswaMitraPayload.items[i].icon3 = this.beasiswaMitraPayload.items[i].registrationProcesses[2].icon
          this.beasiswaMitraPayload.items[i].text3 = this.beasiswaMitraPayload.items[i].registrationProcesses[2].text
        } else {
          this.beasiswaMitraPayload.items[i].icon3 = ''
          this.beasiswaMitraPayload.items[i].text3 = ''
        }
        if (this.beasiswaMitraPayload.items[i].registrationProcesses[3]) {
          this.beasiswaMitraPayload.items[i].icon4 = this.beasiswaMitraPayload.items[i].registrationProcesses[3].icon
          this.beasiswaMitraPayload.items[i].text4 = this.beasiswaMitraPayload.items[i].registrationProcesses[3].text
        } else {
          this.beasiswaMitraPayload.items[i].icon4 = ''
          this.beasiswaMitraPayload.items[i].text4 = ''
        }
        if (this.beasiswaMitraPayload.items[i].registrationProcesses[4]) {
          this.beasiswaMitraPayload.items[i].icon5 = this.beasiswaMitraPayload.items[i].registrationProcesses[4].icon
          this.beasiswaMitraPayload.items[i].text5 = this.beasiswaMitraPayload.items[i].registrationProcesses[4].text
        } else {
          this.beasiswaMitraPayload.items[i].icon5 = ''
          this.beasiswaMitraPayload.items[i].text5 = ''
        }
        delete this.beasiswaMitraPayload.items[i].registrationProcesses

        this.beasiswaMitraPayload.items[i].name = generateName(this.beasiswaMitraPayload.items[i].title)
      }

      this.beasiswaPayload.beasiswaMitraRequest.title = this.beasiswaMitraPayload.title
      this.beasiswaPayload.beasiswaMitraRequest.beasiswaItemMitras = this.beasiswaMitraPayload.items

      this.showLoading()
      this.saveBeasiswaContent({
        reqBody: self.beasiswaPayload,
        success: res => {
          this.$toast.success('Your Beasiswa Data is Successfully Updated!', {
            position: 'top'
          })
          this.hideLoading()
          this.getBeasiswaContent({
            success: res => {
              this.distributeBeasiswaPayload(res)
            }
          })
        }
      })
    }
  }
}
