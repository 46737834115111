module.exports.checkDimension = async(file, width, height) => {
  const result = {
    isValid: false,
    width: 0,
    height: 0
  }

  const img = new Image()
  img.src = window.URL.createObjectURL(file)

  const testPromise = new Promise(resolve => {
    img.onload = () => {
      result.width = img.naturalWidth
      result.height = img.naturalHeight

      window.URL.revokeObjectURL(img.src)

      if (result.width === width && result.height === height) {
        result.isValid = true
        resolve(result)
      } else {
        result.isValid = false
        resolve(result)
      }
    }
  })

  const finalResult = await testPromise

  return finalResult

}
