import { mapActions } from 'vuex'
import ImageUploadMixin from '@/mixins/image-upload'

export default {
  data: () => ({
    general: {
      url: '',
      page_name: '',
      thumbnail_link: '',
      description: ''
    },
    isEdit: false,
    newName: '',
    typeFileDocument: '',

    fileDocument: '',
    descriptionMaxLength: 500
  }),
  mixins: [ImageUploadMixin],
  computed: {
    mainId() {
      return this.$route.params.id
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Label: () => import(/* webpackChunkName: "label" */ '@/components/icons/Label')
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'postSeo', 'getSeoById', 'putSeo']),
    onChange(evt, item) {
      // console.log(this.uploadImage(evt, item, 'thumbnail_link'))
    },

    onSubmit(edit) {
      if (edit) {
        this.updateSeo()
        return
      }
      this.createSeo()
    },
    updateSeo() {
      this.showLoading()
      this.putSeo({
        payload: {
          id: this.mainId,
          data: this.general
        },
        success: () => {
          this.$toast.success('SEO successfully updated!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.$router.push('/seo')
        },
        failed: res => {
          this.$toast.error(res.body.message, {
            position: 'top',
            queue: true,
            type: 'error'
          })
          this.hideLoading()
        }
      })
    },
    createSeo() {
      this.showLoading()
      this.postSeo({
        payload: {
          ...this.general
        },
        success: () => {
          this.$toast.success('SEO successfully created! ', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.$router.push('/seo')
        },
        failed: response => {
          if (response.status === 400) {
            this.$toast.error(response.body.message, {
              position: 'top',
              queue: true,
              type: 'error'
            })
            this.hideLoading()
          } else if (response.status === 422) {
            this.$toast.error(response.body.errors.error, {
              position: 'top',
              queue: true,
              type: 'error'
            })
            this.hideLoading()
          }
        }
      })
    },
    onCheckingTypePage() {
      if (this.mainId === 'new') this.isEdit = false
      else {
        this.showLoading()
        this.isEdit = true
        this.getSeoById({
          id: this.$route.params.id,
          success: response => {
            this.general = response.body.data
            this.hideLoading()
          },
          failed: response => {
            this.hideLoading()
          }
        })
      }
    },
    onWriteText(e) {
      this.general.description = e
    }
  },
  mounted() {
    this.onCheckingTypePage()
  }
}
