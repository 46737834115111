import ImageUploadMixin from '@/mixins/image-upload'
export default {
  name: 'our-strength',
  mixins: [ImageUploadMixin],
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    sequenceOptions() {
      return Array.from(
        { length: this.payload.ourStrengthItemHomePageResponses.length },
        (_, i) => i + 1
      )
    }
  },
  methods: {
    addMoreStrength() {
      const newItem = {
        name: '',
        title: '',
        description: '',
        url: '',
        seqNo: ''
      }
      this.payload.ourStrengthItemHomePageResponses.push(newItem)
    },
    deleteStrength(idx) {
      this.payload.ourStrengthItemHomePageResponses.splice(idx, 1)
    },
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage(item, idx) {
      this.$refs['filestrength' + idx][0].click()
    }
  }
}
