import { mapActions } from 'vuex'

const NotFoundPage = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
export default {
  name: 'all-marketplace-detail',
  data() {
    return {
      notFound: false,
      isEdit: false,
      isReload: false,
      isModalActive: false,
      notDubbleClick: false,
      general: {
        marketplaceName: '',
        url: ''
      },
      marketplaceName: 'Eduverse',
      selectedTab: 'General',
      form: {
        nama: '',
        child: '',
        reset: () => {
          this.form.nama = ''
          this.form.child = ''
        }
      },
      hideMoveTo: false,
      childId: 0,
      perPage: 10,
      totalRecords: 0,
      currentPage: 1,
      dataOfTable: [],
      categoryList: [],
      categoryOption: [],
      clientOptions: [],
      statusOptions: [
        {
          id: 'REQUESTED',
          name: 'Requested'
        },
        {
          id: 'APPROVED',
          name: 'Approved'
        },
        {
          id: 'REJECTED',
          name: 'Rejected'
        }
      ],
      isPublishOption: [
        {
          id: false,
          name: 'Not Published'
        },
        {
          id: true,
          name: 'Published'
        },
        {
          id: '',
          name: 'All'
        }
      ],
      isClientOption: [
        {
          id: 1,
          name: 'G2 Academy'
        },
        {
          id: 2,
          name: 'Accelerice'
        }
      ],
      totalRecords: 0,
      params: {
        page: 0,
        size: 10
      },
      filterSelected: {
        status: null,
        categoryId: null,
        customerId: null,
        isPublished: null
      }
    }
  },
  components: {
    NotFoundPage,
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Label: () => import(/* webpackChunkName: "label" */ '@/components/icons/Label'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    ButtonNewClass: () => import(/* webpackChunkName: "buttonNewClas" */ '@/components/button/ButtonNewClass'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown')
  },
  computed: {
    columns() {
      return [
        { field: 'className', label: 'Class Name' },
        { field: 'categoryBreadcrumb', label: 'Category' },
        { field: 'clientName', label: 'Client' },
        { field: 'status', label: 'Status' },
        { field: 'isPublished', label: 'Published' },
        { field: 'lastUpdatedDate', label: 'Last Update' },
        { field: 'btn', label: 'Action' }
      ]
    },
    mainId() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions([
      'getAllMarketplaceById',
      'getCategoryMarketplaceDetail',
      'sendCategoryStroreDetail',
      'getAllStoreMarketplaceList',
      'changeStatusStore',
      'changeStatusStoreApprove',
      'changeStatusStoreRejeect',
      'getEducationalIstitution',
      'getCategoryStrore',
      'editCategoryDetail',
      'showLoading',
      'hideLoading'
    ]),
    clear() {
      this.filterSelected = {
        status: null,
        categoryId: null,
        customerId: null,
        isPublished: null
      }
      this.classStore()
    },
    toDateManipulate(string, type) {
      if (type) return this.moment(string).format('DD MMM YYYY')
      return this.moment(string).format('HH:mm')
    },
    changeStatusPanding(id, actv) {
      if (actv) {
        this.changeStatusStoreApprove({
          params: id,
          success: () => {
            this.$toast.success('Approve Successfully', {
              position: 'top',
              queue: true,
              type: 'success'
            })
            this.classStore()
          },
          failed: () => {
            this.notFound = true
          }
        })
        return
      }
      this.changeStatusStoreRejeect({
        params: id,
        success: () => {
          this.$toast.success('Rejected Successfully', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.classStore()
        },
        failed: () => {
          this.notFound = true
        }
      })
    },
    changeStatusPublish(params, isPublished) {
      if (isPublished == false) return
      this.changeStatusStore({
        data: {
          params,
          isPublished: isPublished == true ? false : true
        },
        success: res => {
          if (res.isPublished === true) {
            this.$toast.success('Published Successfully', {
              position: 'top',
              queue: true,
              type: 'success'
            })
          } else if (res.isPublished === false) {
            this.$toast.success('Take Down Successfully', {
              position: 'top',
              queue: true,
              type: 'success'
            })
          }
          this.classStore()
        },
        failed: () => {
          this.notFound = true
        }
      })
    },
    checkingMainId() {
      this.getAllMarketplaceById({
        params: this.mainId,
        success: res => {
          this.general = res
          this.fetchCategory()
        },
        failed: () => {
          this.notFound = true
        }
      })
    },
    fetchCategoryMarketplace() {
      this.getCategoryMarketplaceDetail({
        params: this.mainId,
        success: res => {
          if (!res?.children) return
          this.categoryList = res.children.map(e => ({
            id: e.id,
            name: e.name,
            seqNo: e.seqNo,
            show: true,
            child: e?.children ? e.children : []
          }))
        },
        failed: () => {
          this.notFound = true
        }
      })
    },
    onPageChange(params) {
      this.params.page = params.currentPage - 1
      this.currentPage = params.currentPage
      this.params.size = params.currentPerPage
      this.classStore()
    },
    classStore(reset) {
      if (reset) {
        this.params.page = 0
        this.currentPage = 1
      }
      const query = {}
      Object.keys(this.filterSelected).forEach(key => {
        query[key] = this.filterSelected[key] == null ? '' : this.filterSelected[key].id != undefined ? this.filterSelected[key].id : this.filterSelected[key].code
      })
      this.getAllStoreMarketplaceList({
        data: {
          params: this.mainId,
          query: { ...query, page: this.params.page, size: this.params.size }
        },
        success: res => {
          this.totalRecords = res.totalRows
          this.dataOfTable = res.data
          this.params = {
            page: res.currentPage,
            size: res.size
          }
        },
        failed: err => {
          err
        }
      })
    },
    fetchEducationalIstitution() {
      this.getEducationalIstitution({
        success: res => {
          this.clientOptions = res
        },
        failed: err => {
          // this.showToast('is-top', 'is-danger', err)
        }
      })
    },
    fetchCategory() {
      this.categoryOption = []
      this.getCategoryStrore({
        params: this.mainId,
        success: res => {
          this.categoryOption = res
        },
        failed: err => {
          // this.showToast('is-top', 'is-danger', err)
        }
      })
    },
    selectTab(val) {
      this.selectedTab = val
    },
    dropdownParent(idx) {
      this.categoryList[idx].show = !this.categoryList[idx].show
    },
    ascending(idx, no) {
      if (no != null) {
        Array.prototype.move = function (from, to) {
          this.splice(to, 0, this.splice(from, 1)[0])
          return this
        }
        this.categoryList[no].child.move(idx, idx - 1)
        this.onAscending(this.categoryList[no].child[idx], this.categoryList[no].child[idx - 1])
        return
      }
      Array.prototype.move = function (from, to) {
        this.splice(to, 0, this.splice(from, 1)[0])
        return this
      }
      this.categoryList.move(idx, idx - 1)
      this.onAscending(this.categoryList[idx], this.categoryList[idx - 1])
    },
    descending(idx, no) {
      if (no != null) {
        Array.prototype.move = function (from, to) {
          this.splice(to, 0, this.splice(from, 1)[0])
          return this
        }
        this.categoryList[no].child.move(idx, idx + 1)
        this.onDescending(this.categoryList[no].child[idx], this.categoryList[no].child[idx + 1])
        return
      }
      Array.prototype.move = function (from, to) {
        this.splice(to, 0, this.splice(from, 1)[0])
        return this
      }
      this.categoryList.move(idx, idx + 1)
      this.onDescending(this.categoryList[idx], this.categoryList[idx + 1])
    },
    onModalActive(isEdit, idxParent, idxChild) {
      this.isModalActive = true
      this.isEdit = false
      this.form.reset()
      this.hideMoveTo = false
      if (!isEdit) {
        this.childId = 0
        return
      }
      this.isEdit = true
      const { id, name, child } = this.categoryList[idxParent]
      if (idxChild == null) {
        this.form.nama = name
        this.childId = id
        this.hideMoveTo = true
        return
      }
      this.childId = child[idxChild].id
      this.form.nama = child[idxChild].name
      this.form.child = this.categoryList[idxParent]
    },
    onAscending(datafrom, datato) {
      let from, to
      from = datafrom.seqNo
      to = datato.seqNo
      datafrom.seqNo = to
      datato.seqNo = from
      this.editCategoryDetail({
        payload: {
          params: this.mainId,
          body: [datafrom, datato]
        },
        uccess: () => { },
        failed: () => { }
      })
    },
    onDescending(datafrom, datato) {
      let from, to
      from = datafrom.seqNo
      to = datato.seqNo
      datafrom.seqNo = to
      datato.seqNo = from
      this.editCategoryDetail({
        payload: {
          params: this.mainId,
          body: [datafrom, datato]
        },
        success: () => { },
        failed: () => { }
      })
    },
    onSaveStroeCategory(edited) {
      const notValited = true
      let seqNo = 1
      const child = this.form.child?.id
      if (!this.form.nama) {
        this.showToastError('Category Name is required!')
        return
      }

      if (child) {
        seqNo = this.categoryList.find(item => item.id == child).child.length + 1
      } else {
        const data = this.categoryList
        seqNo = data[data.length - 1]?.seqNo + 1
      }
      if (edited) {
        this.editCategoryDetail({
          payload: {
            params: this.mainId,
            body: [
              {
                id: this.childId,
                name: this.form.nama,
                parentCategoryId: child ? child : null,
                seqNo
              }
            ]
          },
          success: () => {
            this.showLoading()
            if (notValited) {
              if (this.notDubbleClick) return
              this.fetchCategoryMarketplace()
              this.$toast.success('Edit Category Successfully!', {
                position: 'top',
                queue: true,
                type: 'success'
              })
              this.notDubbleClick = true
              setTimeout(() => {
                this.hideMoveTo = false
                this.isModalActive = false
                this.notDubbleClick = false
                this.hideLoading()
              }, 3000)
            }
          },
          failed: () => { }
        })
        return
      }
      this.sendCategoryStroreDetail({
        payload: {
          params: this.mainId,
          body: {
            name: this.form.nama,
            parentCategoryId: this.form.child?.id ? this.form.child?.id : null,
            seqNo: seqNo == undefined ? 0 : seqNo
          }
        },
        success: () => {
          this.showLoading()
          if (notValited) {
            if (this.notDubbleClick) return
            this.fetchCategoryMarketplace()
            this.$toast.success('Add Category Successfully!', {
              position: 'top',
              queue: true,
              type: 'success'
            })
            this.notDubbleClick = true
            setTimeout(() => {
              this.hideMoveTo = false
              this.isModalActive = false
              this.notDubbleClick = false
              this.hideLoading()
            }, 3000)
          }
        },
        failed: () => { }
      })
    },
    showToastError(message) {
      this.$toast.error(message, {
        position: 'top'
      })
    }
  },
  mounted() {
    this.checkingMainId()
    this.classStore()
    this.fetchEducationalIstitution()
    this.fetchCategoryMarketplace()
  }
}
