import { mapGetters, mapActions } from 'vuex'
const PageHeader = () =>
  import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'testimony',
  components: {
    PageHeader
  },
  data: () => ({
    keyword: '',
    searchKeyword: ''
  }),
  mounted() {
    this.getTestimony()
  },
  computed: {
    ...mapGetters(['testimonyList']),
    allTestimonies() {
      let temp = this.testimonyList
      if (this.keyword !== '' && this.keyword) {
        temp = temp.filter(item => {
          return item.userName
            .toUpperCase()
            .includes(this.keyword.toUpperCase())
        })
      }
      return temp
    }
  },
  methods: {
    ...mapActions([
      'getTestimonyList',
      'deleteTestimony',
      'showLoading',
      'hideLoading'
    ]),
    getTestimony() {
      this.showLoading()
      this.getTestimonyList({
        success: res => {
          this.hideLoading()
        }
      })
    },
    search() {
      this.keyword = this.searchKeyword
    },
    edit(id) {
      this.$router.push('/daftar-testimoni/edit/' + id)
    },
    deleteTestimonyById(id) {
      this.$confirm({
        message: `Are you sure want to delete this testimony?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.deleteTestimony({
              id: id,
              success: () => {
                this.getTestimony()
              }
            })
          }
        }
      })
    }
  }
}
