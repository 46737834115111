import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import TheLayout from '@/components/layout/TheLayout'
import store from '@/store'
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
const Homepage = () => import(/* webpackChunkName: "home" */ '@/views/Homepage')
const Class = () => import(/* webpackChunkName: "class" */ '@/views/Class')
const CreateClass = () => import(/* webpackChunkName: "create-class" */ '@/views/CreateClass')
const EditClass = () => import(/* webpackChunkName: "edit-class" */ '@/views/EditClass')
const Instructor = () => import(/* webpackChunkName: "instructor" */ '@/views/Instructor')
// const CreateInstructor = () => import(/* webpackChunkName: "create-instructor" */ '@/views/CreateInstructor')
const EditInstructor = () => import(/* webpackChunkName: "edit-instructor" */ '@/views/EditInstructor')
const Testimony = () => import(/* webpackChunkName: "testimony" */ '@/views/Testimony')
const CreateTestimony = () => import(/* webpackChunkName: "create-testimony" */ '@/views/CreateTestimony')
const EditTestimony = () => import(/* webpackChunkName: "edit-testimony" */ '@/views/EditTestimony')
const Prakerja = () => import(/* webpackChunkName: "prakerja" */ '@/views/Prakerja')
const Perusahaan = () => import(/* webpackChunkName: "perusahaan" */ '@/views/Perusahaan')
const Beasiswa = () => import(/* webpackChunkName: "scholarship" */ '@/views/Scholarship')
const Marketplace = () => import(/* webpackChunkName: "marketplace" */ '@/views/Marketplace')
const ShortCourse = () => import(/* webpackChunkName: "short-course" */ '@/views/ShortCourse')
const Redhat = () => import(/* webpackChunkName: "redhat" */ '@/views/Redhat')
const HomepagePopUp = () => import(/* webpackChunkName: "homepage-popup" */ '@/views/HomepagePopUp')
const Seo = () => import(/* webpackChunkName: "seo" */ '@/views/Seo')
const SeoDetail = () => import(/* webpackChunkName: "seo" */ '@/views/SeoDetail')
const AllOrder = () => import(/* webpackChunkName: "all-order" */ '@/views/AllOrder')
const OrderDetails = () => import(/* webpackChunkName: "order-details" */ '@/views/OrderDetails')
const AllMarketplace = () => import(/* webpackChunkName: "all-marketplace" */ '@/views/AllMarketplace')
const AllMarketplaceDetail = () => import(/* webpackChunkName: "marketplace" */ '@/views/AllMarketplaceDetail')
const Campaign = () => import(/* webpackChunkName: "campaign" */ '@/views/Campaign')
const CampaignDetail = () => import(/* webpackChunkName: "campaign-detail" */ '@/views/CampaignDetail')
const AllProgram = () => import(/* webpackChunkName: "all-program" */ '@/views/AllProgram/AllProgram')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: TheLayout,
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: 'Homepage',
        component: Homepage
      },
      {
        path: '/homepage-popup',
        name: 'Homepage Pop Up',
        component: HomepagePopUp
      },
      {
        path: '/daftar-kelas',
        name: 'Class',
        component: Class
      },
      {
        path: '/daftar-kelas/create',
        name: 'Create Class',
        component: CreateClass
      },
      {
        path: '/daftar-kelas/edit/:id',
        name: 'Edit Class',
        component: EditClass
      },
      {
        path: '/daftar-instruktur',
        name: 'Instructor',
        component: Instructor
      },
      // {
      //   path: '/daftar-instruktur/create',
      //   name: 'Create Instructor',
      //   component: CreateInstructor
      // },
      {
        path: '/daftar-instruktur/edit/:id',
        name: 'Edit Instructor',
        component: EditInstructor
      },
      {
        path: '/daftar-testimoni',
        name: 'Testimony',
        component: Testimony
      },
      {
        path: '/daftar-testimoni/create',
        name: 'Create Testimony',
        component: CreateTestimony
      },
      {
        path: '/daftar-testimoni/edit/:id',
        name: 'Edit Testimony',
        component: EditTestimony
      },
      {
        path: '/prakerja',
        name: 'Prakerja',
        component: Prakerja
      },
      {
        path: '/perusahaan',
        name: 'Perusahaan',
        component: Perusahaan
      },
      {
        path: '/pemerintah',
        name: 'Pemerintah',
        component: NotFound
      },
      {
        path: '/beasiswa',
        name: 'Beasiswa',
        component: Beasiswa
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        component: Marketplace
      },
      {
        path: '/redhat',
        name: 'Redhat',
        component: Redhat
      },
      {
        path: '/short-course',
        name: 'ShortCourse',
        component: ShortCourse
      },
      {
        path: '/all-order',
        name: 'AllOrder',
        component: AllOrder
      },
      {
        path: '/all-order/order-details/:orderId',
        name: 'Order Details',
        component: OrderDetails
      },
      {
        path: '/active-clients',
        name: 'Active Clients',
        component: () => import(/* webpackChunkName: "active-clients-new" */ '@/views/clients/ActiveClientsNew')
      },
      {
        path: '/active-clients/add',
        name: 'Add Client',
        meta: {
          mode: 'add'
        },
        component: () => import(/* webpackChunkName: "add-client" */ '@/views/clients/AddClient')
      },
      {
        path: '/archived-clients',
        name: 'Archived Clients',
        component: () => import(/* webpackChunkName: "archived-clients-new" */ '@/views/clients/ArchivedClientsNew')
      },
      {
        path: ':category/detail-client/:id',
        name: 'Detail Client',
        component: () => import(/* webpackChunkName: "detail-client" */ '@/views/clients/DetailClient')
      },
      {
        path: ':category/detail-client/:id/documents',
        name: 'All Documents',
        component: () => import(/* webpackChunkName: "all-documents" */ '@/views/clients/AllDocuments')
      },
      // {
      //   path: ':category/detail-client/:id/documents/:type',
      //   name: 'Documents',
      //   component: () => import(/* webpackChunkName: "documents" */ '@/views/clients/Documents')
      // },
      {
        path: ':category/detail-client/:clientId/documents/:id',
        name: 'Detail Document',
        component: () => import(/* webpackChunkName: "detail-document" */ '@/views/clients/DetailDocument')
      },
      {
        path: '/all-order',
        name: 'All Order',
        component: NotFound
      },
      {
        path: '/all-marketplace',
        redirect: '/all-marketplace',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '/',
            name: 'All Marketplace',
            component: AllMarketplace
          },
          {
            path: ':id',
            name: 'All MarketplaceDetail',
            component: AllMarketplaceDetail
          }
        ]
      },
      {
        path: '/seo',
        redirect: '/seo',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '/',
            name: 'SEO',
            component: Seo
          },
          {
            path: ':id',
            name: 'Seo Detail',
            component: SeoDetail
          },
          {
            path: '/new',
            name: 'Create Seo',
            component: SeoDetail
          }
        ]
      },
      {
        path: '/campaign',
        redirect: '/campaign',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '/',
            name: 'Campaign',
            component: Campaign
          },
          {
            path: ':id',
            name: 'Campaign Detail',
            component: CampaignDetail
          },
          {
            path: '/new',
            name: 'Create Campaign',
            component: CampaignDetail
          }
        ]
      },
      {
        path: '/all-programs',
        name: 'All Programs',
        component: AllProgram
      }
    ]
  },
  {
    path: '/server-down',
    name: 'ServerDown',
    component: () => import(/* webpackChunkName: "server-down" */ '@/views/ServerDown')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {
  store.dispatch('getCurrentUser')
  if (store.getters['userData'].token) {
    const tokenExpiredAt = parseJwt(store.getters['userData'].token).exp
    if (new Date().getTime() > new Date(tokenExpiredAt * 1000).getTime()) {
      store.dispatch('removeUserData')
      next({
        name: 'login'
      })
      return
    }
  }
  if (store.getters.isLoggedIn && to.name === 'login') {
    next({
      name: 'Homepage'
    })
    return
  }
  if (to.name === 'login') store.dispatch('checkServer')
  if (!store.getters.isLoggedIn && to.name !== 'login' && to.name !== 'ServerDown') {
    store.dispatch('checkServer')
    next({
      name: 'login'
    })
  }
  next()
})

router.afterEach((to, from) => {
  if (store.getters.isLoggedIn) {
    const userId = store.getters.userData?.id
    store.dispatch('notification/getNotifications', {
      userId: userId,
      params: {
        page: 0,
        size: 500,
        platform: 'PLATFORM_CENTER'
      }
    })
    store.dispatch('notification/getNotificationCount', {
      userId: userId,
      params: {
        platform: 'PLATFORM_CENTER'
      }
    })
  }
})

export default router
