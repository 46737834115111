const BASE_PATH = process.env.VUE_APP_API_URL
const BASE_V4 = process.env.VUE_APP_API_URL_BASE_V4
const BASE_PATH_V1 = process.env.VUE_APP_API_URL_V1
const BASE_PATH_V2 = process.env.VUE_APP_API_URL_V2
const BASE_PATH_V3 = process.env.VUE_APP_API_URL_V3

const HOMEID = '2c9040e6761cccce01761cdc91390000'
const PRAKERJAID = '2c92856e7626a115017626ca4b6c0000'
const BEASISWAID = '2c9f939c7673f50f017673fa0bae0005'

module.exports = {
  api: {
    checkServer: `${process.env.VUE_APP_API_URL_BASE}`,
    baseV4: `${BASE_V4}`,
    homePage: `${BASE_PATH}/homepage/${HOMEID}`,
    prakerja: `${BASE_PATH}/prakerja/${PRAKERJAID}`,
    beasiswa: `${BASE_PATH}/beasiswa/${BEASISWAID}`,
    class: `${BASE_PATH}/class`,
    instructor: `${BASE_PATH}/experts`,
    testimony: `${BASE_PATH}/testimonies`,
    user: {
      login: `${BASE_PATH}/auth/login`,
      changePassword: `${BASE_PATH}/user/change-password`,
      user: `${BASE_PATH_V1}/user`
    },
    educational: `${BASE_PATH}/educational-institutions`,
    marketplace: `${BASE_PATH_V2}/marketplace`,
    store: `${BASE_PATH_V3}/store`,
    image: `${BASE_PATH}/image`,
    programs: `${BASE_PATH}/programs`,
    customer: `${BASE_PATH}/customers`,
    popup: `${BASE_PATH_V2}/cms/popup`,
    programContent: `${BASE_PATH_V2}/cms/programs`,
    homePageProgram: `${BASE_PATH}/homepage/cms/programs`,
    digitalPlatforms: `${BASE_PATH}/digital-platforms`,
    order: `${BASE_PATH_V1}/order`,
    seo: `${BASE_PATH_V2}/cms/seo-management`,
    seoReindexing: projectId => `https://gitlab.com/api/v4/projects/${projectId}/trigger/pipeline`,
    client: `${BASE_PATH}/customers`,
    partnerRegistration: `${BASE_PATH_V1}/partner-registrations`,
    registrationDocuments: `${BASE_PATH_V1}/registration-documents`,
    mitraDocument: mitraId => `${BASE_PATH_V2}/cms/mitra/${mitraId}/documents`,
    mitra: `${BASE_PATH}`,
    mitraV2: `${BASE_PATH_V2}`,
    notification: userId => `${BASE_PATH}/notifications/${userId}`,
    notificationCount: userId => `${BASE_PATH}/notifications/count-new/${userId}`,
    updateSingleRead: (userId, notificationId) => `${BASE_PATH}/notifications/single-read/${userId}/${notificationId}`,
    updateAllRead: userId => `${BASE_PATH}/notifications/all-read/${userId}`,
    campaign: {
      list: `${BASE_PATH_V1}/marketing-campaign`,
      detail: campaignId => `${BASE_PATH_V1}/marketing-campaign/${campaignId}`,
      download: `${BASE_PATH_V1}/marketing-campaign/download`
    }
  },
  getAuthHeader() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'Access-Control-Allow-Origin': '*'
    }
  },
  getAuthAndMultipartHeader() {
    return {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'Access-Control-Allow-Origin': '*'
    }
  },
  setting: {
    fullPageList: ['/daftar-kelas', '/daftar-instruktur', '/daftar-testimoni', '/all-order']
  }
}
