import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { currencyFormat } from '@/util/currency'

export default {
  filters: {
    currencyFormat
  },
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    Search: () => import(/* webpackChunkName: "search" */ '@/components/icons/Search'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    TableComponent: () => import(/* webpackChunkName: "table" */ '@/components/table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "pagination" */ '@/components/pagination/Pagination'),
    CampaignForm: () => import(/* webpackChunkName: "CampaignForm" */ '@/components/CampaignForm')
  },
  data() {
    return {
      isVisibleAddModal: false,
      isVisibleEditModal: false,
      filteredData: [],
      startDate: '',
      keyword: '',
      endDate: '',
      selectedType: {
        label: 'All',
        value: ''
      },
      typeList: [
        {
          label: 'All',
          value: ''
        },
        {
          label: 'Ongoing',
          value: 'ONGOING'
        },
        {
          label: 'Past',
          value: 'PAST'
        },
        {
          label: 'Upcoming',
          value: 'UPCOMING'
        }
      ],
      columns: [
        { label: 'Name', field: 'campaignName' },
        { label: 'Description', field: 'description' },
        { label: 'Medium', field: 'medium' },
        { label: 'Source', field: 'source' },
        { label: 'Amount Spent', field: 'amountSpent' },
        { label: 'Start Time', field: 'startDate' },
        { label: 'End Time', field: 'endDate' },
        { label: 'Impression', field: 'impression' },
        { label: 'Click', field: 'actionClick' },
        { label: 'Cost Per Click', field: 'costPerClick' },
        { label: 'Registration', field: 'registration' },
        { label: 'Action', field: 'action' }
      ],
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      isEditMode: false,
      selectedCampaignId: null
    }
  },
  computed: {
    ...mapGetters(['campaignList']),
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    }
  },
  watch: {
    pageSize() {
      this.currentPage = 1
      this.getCampaign()
    }
  },
  created() {
    this.getCampaign()
  },
  methods: {
    ...mapActions(['getCampaignList', 'createCampaign', 'deleteCampaign', 'showLoading', 'hideLoading', 'updateCampaign', 'downloadReport']),
    clearFilter() {
      this.startDate = ''
      this.keyword = ''
      this.endDate = ''
      this.selectedType = {
        label: 'All',
        value: ''
      }
      this.getCampaign()
    },
    doDownload() {
      this.showLoading()
      this.downloadReport({
        params: {
          startDate: this.startDate ? `${dayjs(this.startDate).format('YYYY-MM-DD')}T00:00:00.000` : '',
          endDate: this.endDate ? `${dayjs(this.endDate).format('YYYY-MM-DD')}T23:59:59.999` : '',
          campaignType: this.selectedType.value,
          q: this.keyword || ''
        },
        success: res => {
          const url = URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'marketing_campaign.xlsx')
          document.body.appendChild(link)
          link.click()
          this.hideLoading()
          this.$toast.success('The campaign report is succesfully generated!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
        },
        failed: () => {
          this.hideLoading()
          this.$toast.success('The campaign report is failed to be downloaded!', {
            position: 'top',
            queue: true,
            type: 'error'
          })
        }
      })
    },
    formatDate(value) {
      return dayjs(value).format('DD-MM-YYYY')
    },
    editCampaign(data) {
      const valid = this.dateValidation(data)
      if (valid) {
        this.showLoading()
        this.updateCampaign({
          campaignId: data.campaignId,
          payloads: data.payloads,
          success: () => {
            this.hideLoading()
            this.toggleEditModal()
            this.$toast.success('The campaign is successfully updated!', {
              position: 'top',
              queue: true,
              type: 'success'
            })
            this.getCampaign()
          },
          failed: () => {
            this.hideLoading()
            this.toggleEditModal()
            this.$toast.error('The campaign is failed to be updated.', {
              position: 'top',
              queue: true,
              type: 'error'
            })
          }
        })
      } else {
        this.$toast.error("Start Date can't exceed the End Date", {
          position: 'top',
          queue: true,
          type: 'error'
        })
      }
    },
    deleteItem(campaignId) {
      this.showLoading()
      this.deleteCampaign({
        campaignId: campaignId,
        success: () => {
          this.hideLoading()
          this.toggleEditModal()
          this.$toast.success('The campaign is successfully deleted!', {
            position: 'top',
            queue: true,
            type: 'success'
          })
          this.getCampaign()
        },
        failed: () => {
          this.hideLoading()
          this.toggleEditModal()
          this.$toast.error('The campaign is failed to be deleted.', {
            position: 'top',
            queue: true,
            type: 'error'
          })
        }
      })
    },
    dateValidation(value) {
      return dayjs(value.payloads.startDate) <= dayjs(value.payloads.endDate)
    },
    addNew(value) {
      const valid = this.dateValidation(value)
      const constructedPayload = {
        campaignName: value.payloads.campaignName,
        description: value.payloads.description,
        amountSpent: value.payloads.amountSpent,
        medium: value.payloads.medium,
        source: value.payloads.source,
        startDate: `${dayjs(value.payloads.startDate).format('YYYY-MM-DD')}T00:00:00.000`,
        endDate: `${dayjs(value.payloads.endDate).format('YYYY-MM-DD')}T23:59:59.999`
      }
      if (valid) {
        this.showLoading()
        this.createCampaign({
          payload: constructedPayload,
          success: res => {
            this.hideLoading()
            this.toggleAddModal()
            this.$toast.success('The campaign is successfully added!', {
              position: 'top',
              queue: true,
              type: 'success'
            })
            this.getCampaign()
          },
          failed: () => {
            this.hideLoading()
            this.toggleAddModal()
            this.$toast.error('The campaign is failed to be added.', {
              position: 'top',
              queue: true,
              type: 'error'
            })
          }
        })
      } else {
        this.$toast.error("Start Date can't exceed the End Date", {
          position: 'top',
          queue: true,
          type: 'error'
        })
      }
    },
    toggleAddModal() {
      this.isVisibleAddModal = !this.isVisibleAddModal
    },
    toggleEditModal(id) {
      if (id) {
        this.selectedCampaignId = id
      } else {
        this.selectedCampaignId = null
      }
      this.isVisibleEditModal = !this.isVisibleEditModal
      if (this.isVisibleEditModal) {
        this.isEditMode = true
      } else {
        this.isEditMode = false
      }
    },
    applyFilter() {
      this.currentPage = 1
      this.getCampaign()
    },
    doSearch() {
      this.currentPage = 1
      this.getCampaign()
    },
    getCampaign() {
      this.showLoading()
      this.getCampaignList({
        payload: {
          params: {
            direction: 'DESC',
            page: this.currentPage - 1,
            size: this.pageSize,
            q: this.keyword || '',
            startDate: this.startDate ? `${dayjs(this.startDate).format('YYYY-MM-DD')}T00:00:00.000` : '',
            endDate: this.endDate ? `${dayjs(this.endDate).format('YYYY-MM-DD')}T23:59:59.999` : '',
            campaignType: this.selectedType.value,
            sortBy: ''
          }
        },
        success: response => {
          this.totalRecords = response.body.pagination.total
          this.hideLoading()
        },
        failed: () => {
          this.hideLoading()
        }
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.getCampaign()
    }
  }
}
