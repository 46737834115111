<template>
  <div class="search relative">
    <label v-if="label" class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">{{ label }}</label>
    <div class="relative">
      <input
        v-bind="$attrs"
        @input="doSearch()"
        :class="[{ 'is-invalid': hasErrors, 'bg-grey cursor-not-allowed': disabled, 'border-grey-field border': borderEnabled }]"
        class="pr-3 py-3 pl-11 focus:outline-none focus:ring focus:ring-yellow w-full rounded-lg text-sm"
        :disabled="disabled || disabledWhite"
        v-on:keyup.enter="enterKeyAction()"
        @focus="onFocus"
        v-model="keyword"
        @blur="onBlur"
      />
      <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
        <Search width="16" height="16" />
      </div>
    </div>
    <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearching">
      <template v-if="searchResult.length > 0 && !isFetching">
        <div @click="selectItem(result)" class="py-1 text-sm px-3 text-white cursor-pointer hover:bg-yellow hover:text-white font-normal last:pb-0" v-for="result in searchResult" :key="result.id">
          {{ result[optionLabel] }}
        </div>
      </template>
      <template v-if="searchResult.length === 0 && !isFetching">
        <p class="py-1 px-3 text-white font-normal text-center text-sm">Data is not found.</p>
      </template>
    </div>
    <span class="text-xs text-gray-500 leading-tight inline-block mt-1" v-if="notes">{{ notes }}</span>
    <transition name="fade" mode="out-in">
      <div class="text-red-500 text-left mt-1 text-xs" v-if="error">{{ error }}</div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  components: {
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/icons/Search')
  },
  props: {
    title: String,
    notes: String,
    disabled: Boolean,
    disabledWhite: {
      type: Boolean,
      default: false
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    smallSize: {
      type: Boolean,
      default: false
    },
    search: {
      type: Function,
      required: false,
      default: () => {}
    },
    optionLabel: String,
    optionObject: String,
    defaultValue: Object
  },
  data() {
    return {
      keyword: '',
      isFetching: false,
      isSearching: false,
      debounce: null,
      searchResult: []
    }
  },
  computed: {
    hasErrors() {
      return !!this.error.length
    }
  },
  mounted() {
    if (this.defaultValue) {
      this.selectItem(this.defaultValue)
    }
  },
  methods: {
    enterKeyAction() {
      this.$emit('enter')
    },
    onFocus() {
      this.$emit('onFocus')
    },
    onBlur() {
      this.$emit('onBlur')
    },
    doSearch() {
      if (this.keyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(async function() {
          if (this.keyword !== '') {
            const result = await new Promise((resolve, reject) => {
              self.isFetching = true
              self.search(self.keyword).then(res => resolve(res))
            })
            self.searchResult = result
            self.isSearching = true
            self.isFetching = false
          }
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectItem(item) {
      if (this.optionObject) {
        this.$emit('input', item[this.optionObject])
      } else {
        this.$emit('input', item)
      }
      this.keyword = item[this.optionLabel]
      this.isSearching = false
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
