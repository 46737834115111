import { mapActions, mapGetters } from 'vuex'

const GroupingClassMarketplace = () => import(/* webpackChunkName: "grouping-class-marketplace" */ '@/components/GroupingClassMarketplace')
const PageSlideBanner = () => import(/* webpackChunkName: "slide-banner" */ '@/components/PageSlideBanner')
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
export default {
  name: 'marketplace',
  components: {
    GroupingClassMarketplace,
    PageSlideBanner,
    PageHeader,
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Label: () => import(/* webpackChunkName: "label" */ '@/components/icons/Label'),
    Dropdown: () => import(/* webpackChunkName: "dropdown" */ '@/components/dropdown/Dropdown'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data: () => ({
    slideBanner: [],
    classGroups: [],
    classList: [],
    isModalActive: false,
    isModalActiveClass: false,
    isChild: '',
    isEditMarketplace: false,
    form: {
      category: '',
      className: ''
    },
    categoryList: [],
    classCategoryList: [],
    page: 0,
    size: 10,
    categoryOption: []
  }),
  computed: {
    ...mapGetters(['beasiswaResponse'])
  },
  mounted() {
    this.getMarketplaceData()
    this.fetchCategoryMarketplace()
  },

  methods: {
    ...mapActions([
      'postMarketplace',
      'getClassList',
      'editMarketplace',
      'getClassIdValue',
      'getMarketplaceBanners',
      'getMarketplaceClassList',
      'showLoading',
      'hideLoading',
      'getCategoryMarketplaceDetail',
      'getCategoryStrore'
    ]),
    searchClass(keyword, classGroupsIndex, classIndex) {
      this.classList = []
      this.getClasses(keyword)
      this.classGroups[classGroupsIndex].classes[classIndex].optionVisible = true
    },

    mouseLeave(classGroupsIndex, classIndex) {
      this.classGroups[classGroupsIndex].classes[classIndex].optionVisible = false
      if (this.classGroups[classGroupsIndex].classes[classIndex].id === null) {
        this.classGroups[classGroupsIndex].classes[classIndex].name = ''
      }
    },
    addClassChild(name, idx) {
      const data = this.categoryOption.find(item => item.name === name)
      this.isChild = idx
      this.fetchClassValue(data)
      this.isModalActive = true
      this.isModalActiveClass = true
    },
    fetchClassValue(e) {
      if (!e) return
      this.form.category = e
      this.form.className = ''
      setTimeout(() => {
        this.getClassIdValue({
          data: {
            params: 1,
            query: this.form.category?.id ? this.form.category?.id : ''
          },
          success: res => {
            this.classCategoryList = res
          },
          failed: () => {}
        })
      }, 200)
    },
    fetchCategoryMarketplace() {
      this.categoryOption = []
      this.getCategoryStrore({
        params: 1,
        success: res => {
          this.categoryOption = res
        },
        failed: err => {
          // this.showToast('is-top', 'is-danger', err)
        }
      })
    },
    selectedClass(classItem, classGroupsIndex, classIndex) {
      this.classGroups[classGroupsIndex].classes[classIndex] = classItem
    },

    loadMoreClassList() {},

    getClasses(name) {
      this.getClassList({
        params: {
          page: this.page,
          size: this.size,
          program: '',
          name
        },
        success: res => {
          this.classList = res.data
        }
      })
    },

    getMarketplaceData() {
      this.showLoading()
      const params = {
        page: this.page,
        size: this.size
      }
      this.getMarketplaceBanners({
        params,
        success: res => {
          if (res.content.length > 0) {
            this.isEditMarketplace = true
            res.content.map((banner, index) => {
              this.slideBanner.push({ ...banner, url: banner.imageUrl })
              delete this.slideBanner[index].imageUrl
            })
          }
          this.getMarketplaceClassList({
            params,
            success: res => {
              if (res.content.length > 0) {
                this.isEditMarketplace = true
                res.content.map((classItem, index) => {
                  this.classGroups.push(classItem)
                  this.classGroups[index].classes.map((item, itemIdx) => {
                    this.classGroups[index].classes[itemIdx].optionVisible = false
                    this.classGroups[index].classes[itemIdx].isClassSelected = true
                    delete this.classGroups[index].classes[itemIdx].imageUrl
                    delete this.classGroups[index].classes[itemIdx].slug
                  })
                })
              }
              this.hideLoading()
            },
            failed: () => {}
          })
        },
        failed: () => {}
      })
    },
    closeModal() {
      this.isModalActive = false
      this.isModalActiveClass = false
      this.classCategoryList = []
      this.form = {
        category: '',
        className: ''
      }
    },
    addNewGroup() {
      if ((!this.form.category || !this.form.className) && !this.isModalActiveClass) {
        return
      }
      if (this.isChild !== '') {
        const item = {
          id: this.form.className?.coreClassId,
          name: this.form.className?.className,
          seqNo: this.classGroups[this.isChild].classes.length + 1
        }
        this.classGroups[this.isChild].classes.push(item)
        this.isModalActive = false
        this.isModalActiveClass = false
        this.isChild = ''
        this.form.category = ''
        this.form.className = ''
        return
      }
      let result = []
      if (this.classGroups.length > 0) {
        result = this.classGroups[this.classGroups.length - 1].classes.filter(item => item.id === null)
      }
      if (result.length > 0) {
        this.$toast.success('Fill in the banner parameters correctly !', {
          position: 'top',
          type: 'warning'
        })
        return
      }
      const item = {
        classes: [
          {
            id: this.form.className?.coreClassId,
            name: this.form.className?.className,
            seqNo: 0
          }
        ],
        seqNo: this.classGroups.length + 1,
        title: this.form.category?.name
      }
      this.isModalActive = false
      this.isModalActiveClass = false
      this.classGroups.push(item)
      this.form = {
        category: '',
        className: ''
      }
    },
    deleteGroup(index) {
      this.classGroups.splice(index, 1)
    },
    removeDuplicate(index) {
      const activeClass = this.classGroups[index].classes
      const uniqueItem = Array.from(new Set(activeClass.map(a => a.id))).map(id => {
        return activeClass.find(a => a.id === id)
      })
      this.classGroups[index].classes = []
      this.classGroups[index].classes = uniqueItem
    },
    isDuplicate(arrayItem) {
      let valueArr = arrayItem.map(function(item) {
        return item.id
      })
      let isDuplicate = valueArr.some(function(item, idx) {
        return valueArr.indexOf(item) !== idx
      })
      return isDuplicate
    },

    checkClassDuplicate() {
      let status = false
      this.classGroups.map(item => {
        this.isDuplicate(item.classes) ? (status = true) : (status = false)
      })
      return status
    },
    checkBannerParameter() {
      let status = false
      this.slideBanner.map(banner => {
        status = Object.values(banner).includes('')
      })
      return status
    },
    saveData() {
      if (this.checkBannerParameter()) {
        return this.$toast.success('Fill in the banner parameters correctly !', {
          position: 'top',
          type: 'warning'
        })
      }
      if (this.checkClassDuplicate()) {
        this.classGroups.map((item, index) => {
          this.removeDuplicate(index)
        })

        return this.$toast.success('Duplicate Class !', {
          position: 'top',
          type: 'warning'
        })
      }
      const classGroups = this.classGroups.map(classItem => {
        return {
          ...classItem,
          classes: classItem.classes.map(item => {
            return { id: item?.id, seqNo: item.seqNo, price: item?.price, type: item?.price }
          })
        }
      })

      this.showLoading()
      const payload = {
        classGroups,
        slideBanner: this.slideBanner
      }
      this.isEditMarketplace ? this.editMarketplaceDate(payload) : this.saveMartketplaceData(payload)
    },
    saveMartketplaceData(payload) {
      this.postMarketplace({
        payload,
        success: res => {
          const content = res.body.data
          if (content.classGroups.length > 0) {
            this.classGroups = []
            this.isEditMarketplace = true
            content.classGroups.map((classItem, index) => {
              this.classGroups.push(classItem)
              this.classGroups[index].classes.map((item, itemIdx) => {
                delete this.classGroups[index].classes[itemIdx].imageUrl
                delete this.classGroups[index].classes[itemIdx].name
                delete this.classGroups[index].classes[itemIdx].slug
              })
            })
          }
          if (content.slideBanner.length > 0) {
            this.isEditMarketplace = true
            this.slideBanner = []
            content.slideBanner.map((banner, index) => {
              this.slideBanner.push({ ...banner, url: banner.imageUrl })
              delete this.slideBanner[index].imageUrl
            })
          }
          this.hideLoading()
          this.$toast.success('Your Marketplace Data is Successfully Created!', {
            position: 'top'
          })
        },
        failed: () => {
          this.hideLoading()
          this.$toast.success('Failed!', {
            position: 'top',
            type: 'error'
          })
        }
      })
    },
    editMarketplaceDate(payload) {
      this.editMarketplace({
        payload,
        success: res => {
          this.hideLoading()
          this.$toast.success('Your Marketplace Data is Successfully Updated!', {
            position: 'top'
          })
        },
        failed: () => {
          this.$toast.success('Failed!', {
            position: 'top',
            type: 'error'
          })
          this.hideLoading()
        }
      })
    }
  }
}
