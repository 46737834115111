export default {
  props: {
    icon: Function,
    buttonText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'regular'
    },
    additionalClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    buttonClass: {
      primary: 'bg-yellow text-white border-yellow hover:bg-yellow-300 focus:outline-none',
      secondary: 'bg-yellow-secondary border-yellow-secondary text-yellow hover:text-yellow-300 focus:outline-none',
      tertiary: 'bg-transparent border-yellow text-yellow hover:text-yellow-300 hover:border-yellow-300 focus:outline-none',
      delete: 'bg-system-error border-system-error text-white hover:text-white hover:border-system-error focus:outline-none'
    },
    buttonCLassSize: {
      regular: 'text-sm py-2 px-6',
      big: 'text-base py-3 px-6',
      full: 'text-sm py-2 px-6 w-full',
      small: 'text-xs py-1 px-3',
      icon: 'text-xs py-1 px-2',
      custom:'',
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonCLassSize[this.size]
    }
  },
  methods: {
    handleClick() {
      this.$emit('action')
    }
  }
}
