import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)
const state = {
  notifications: [],
  notificationCount: 0
}
const actions = {
  getNotifications: ({ commit }, { userId, params, success, failed }) => {
    Vue.http
      .get(`${config.api.notification(userId)}` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          commit('setNotifications', response.body.data.content)
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getNotificationCount: ({ commit }, { userId, params, success, failed }) => {
    Vue.http
      .get(`${config.api.notificationCount(userId) + serializeQueryParams(params)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          commit('setNotificationCount', response.body.data)
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  updateSingleRead: ({ _ }, { userId, notificationId, success, failed }) => {
    Vue.http
      .get(`${config.api.updateSingleRead(userId, notificationId)}`, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  updateAllRead: ({ _ }, { userId, params, success, failed }) => {
    Vue.http
      .get(`${config.api.updateAllRead(userId)}` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  }
}
const mutations = {
  setNotifications(state, value) {
    state.notifications = value
  },
  setNotificationCount(state, value) {
    state.notificationCount = value
  }
}
const getters = {
  notifications: state => state.notifications,
  notificationCount: state => state.notificationCount
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
