import { mapGetters, mapActions } from 'vuex'
import ImageUploadMixin from '@/mixins/image-upload'
import VueSingleSelect from 'vue-single-select'
export default {
  name: 'edit-instructor',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  data: () => ({
    instuctor: {
      certificates: [],
      description: '',
      educationBackground: '',
      email: '',
      name: '',
      phoneNumber: '',
      url: '',
      skills: ''
    },
    customerTypeSelected: '',
    descriptionMaxLength: 1000
  }),
  mounted() {
    this.getDetail()
  },
  computed: {
    ...mapGetters(['instructorDetail']),
    instructorId() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions(['getInstructorDetail', 'editInstructor', 'showLoading', 'hideLoading', 'getCustomerType']),
    getDetail() {
      this.showLoading()
      this.getInstructorDetail({
        id: this.instructorId,
        success: res => {
          this.hideLoading()
          this.instuctor = {
            certificates: [],
            description: res.description,
            educationBackground: res.educationBackground,
            email: res.email,
            name: res.name,
            phoneNumber: res.phoneNumber,
            url: res.photo,
            skills: res.skillLogo.join()
          }
          this.customerTypeSelected = res.customer.name
        }
      })
    },
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage() {
      this.$refs.filecore.click()
    },
    save() {
      const payload = {
        certificates: this.instuctor.certificates,
        description: this.instuctor.description,
        educationBackground: this.instuctor.educationBackground,
        email: this.instuctor.email,
        name: this.instuctor.name,
        phoneNumber: this.instuctor.phoneNumber,
        photo: this.instuctor.url,
        skills: this.instuctor.skills.split(','),
        customerId: this.customerTypeSelected.id
      }
      this.showLoading()
      this.editInstructor({
        id: this.instructorId,
        payload: payload,
        success: () => {
          this.$toast.success('Your Instructor Data is Successfully Updated!', {
            position: 'top'
          })
          this.hideLoading()
          this.goBack()
        }
      })
    },
    goBack() {
      this.$router.push('/daftar-instruktur')
    }
  }
}
