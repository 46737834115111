<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.8C12.0346 4.8 12.0688 4.7978 12.1022 4.79353C12.1755 4.78418 12.2457 4.76492 12.3114 4.73713C12.5985 4.61567 12.8 4.33137 12.8 4.00001H12.8V4C12.8 3.55817 12.4418 3.2 12 3.2C11.5582 3.2 11.2 3.55817 11.2 4C11.2 4.44183 11.5582 4.8 12 4.8ZM12 2C10.8954 2 10 2.89543 10 4L5 4C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4L14 4.00001V4C14 2.89543 13.1046 2 12 2ZM17.5575 9.50172C17.8346 9.19384 17.8096 8.71962 17.5017 8.44253C17.1938 8.16544 16.7196 8.19039 16.4425 8.49828L10.664 14.9189L8.52281 12.8373C8.22582 12.5485 7.75099 12.5552 7.46225 12.8522C7.17351 13.1492 7.1802 13.624 7.47719 13.9127L10.1772 16.5377C10.3234 16.6799 10.5212 16.7564 10.725 16.7496C10.9288 16.7428 11.1211 16.6533 11.2575 16.5017L17.5575 9.50172Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
