import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
export default {
  name: 'detail-document',
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/others/Modal'),
    PageTitle: () => import(/* webpackChunkName: "page-title" */ '@/components/others/PageTitle'),
    CrossCircle: () => import(/* webpackChunkName: "cross-circle" */ '@/components/icons/CrossCircle'),
    DocumentText: () => import(/* webpackChunkName: "document-text" */ '@/components/icons/DocumentText'),
    ButtonsGroup: () => import(/* webpackChunkName: "page-title" */ '@/components/button/ButtonsGroup')
  },
  data: () => ({
    currentName: '-',
    newName: null,
    date: '-',
    documentUrl: '',
    companyName: '',
    description: '',
    typeFileDocument: '',
    regDocId: null,
    fileDocument: null,
    historyDocumentList: [],
    maxFileDocument: 20000000,
    descriptionMaxLength: 1000,
    isDocumentNDAOrMoU: false,
    isDocumentChanged: false,
    forDocumentStatus: true,
    hideButton: true,
    isArchived: true,
    isFocus: false,
    isModalYesNo: false,
    isModalHistory: false,
    isModalChangeDocument: false,
    expirationDate: '',
    documentDetail: {}
  }),
  computed: {
    mitraStatus() {
      if (this.$route.params.category === 'active-clients') {
        return 'ACTIVE'
      } else if (this.$route.params.category === 'archived-clients') {
        return 'INACTIVE'
      }
    },
    companyNameForPageDesc: function() {
      let forName = this.companyName !== '' ? this.companyName : 'alliance'
      let nameArr = forName.split(' ')
      const check = nameArr.join('-')
      return check.length > 10 ? `${check.substr(0, 8)}..` : check
    },
    documentTypeForPageDesc: function() {
      const forDocumentType = this.$route.params.type
      return forDocumentType.length > 10 ? `${forDocumentType.substr(0, 8)}..` : forDocumentType
    },
    forPageName: function() {
      let typeFix = 'Document'
      // const type = this.$route.params.type.split('-')
      // if (type.length > 1) {
      //   let typeArr = []
      //   for (let j = 0; j < type.length; j++) {
      //     const subLowerCase = type[j]
      //     const subCamelCase = subLowerCase.charAt(0).toUpperCase() + subLowerCase.slice(1)
      //     typeArr.push(subCamelCase)
      //   }
      //   typeFix = typeArr.join(' ')
      // } else {
      //   typeFix = type[0].charAt(0).toUpperCase() + type[0].slice(1)
      // }
      return typeFix
    },
    firstButtons: function() {
      return [
        // {
        //   action: () => this.showModalChangeDocument(),
        //   text: 'Change',
        //   size: 'small',
        //   disabled: this.isArchived,
        //   hide: this.isDocumentChanged
        // },
        {
          action: () => this.showModalHistory(true),
          text: 'Review',
          size: 'small',
          disabled: this.isArchived,
          hide: !this.isDocumentChanged || !this.isDocumentNDAOrMoU
        },
        {
          action: () => this.showModalHistory(false),
          text: 'History',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.downloadDocument(),
          text: 'Download',
          type: 'primary',
          size: 'small'
        }
      ]
    },
    modalButtons: function() {
      return [
        {
          action: () => this.forGetUploadUrl(),
          text: 'Save',
          size: 'small',
          disabled: !this.fileDocument,
          hide: this.isModalHistory
        },
        {
          action: () => this.hideModalChangeDocument(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small',
          hide: this.isModalHistory
        },
        {
          action: () => this.toYesNoModal(true),
          text: 'Approve',
          size: 'small',
          hide: this.isModalChangeDocument || this.hideButton
        },
        {
          action: () => this.toYesNoModal(false),
          text: 'Dismiss',
          type: 'tertiary',
          size: 'small',
          hide: this.isModalChangeDocument || this.hideButton
        }
      ]
    },
    yesNoModalButtons: function() {
      return [
        {
          action: () => this.changeDocumentStatus(),
          text: 'Yes',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.hideModalYesNo(),
          text: 'No',
          type: 'tertiary',
          size: 'small'
        }
      ]
    }
  },
  mounted() {
    this.detailDocument()
    this.getMitraById()
  },
  methods: {
    ...mapActions(['getDetailDocument', 'getMitraDetail', 'getHistoryDocument', 'getUploadUrl', 'updateUploadFile', 'updateClientDocument', 'updateDocumentStatus', 'showLoading', 'hideLoading']),
    getMitraById() {
      this.showLoading()
      this.getMitraDetail({
        id: this.$route.params.clientId,
        success: res => {
          this.companyName = res.companyName
        },
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
        }
      })
    },
    downloadDocument() {
      const link = document.createElement('a')
      link.download = this.documentDetail?.registrationDocument?.name
      link.target = '_blank'
      link.href = this.documentDetail?.fileUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      let reader = new FileReader()
      reader.onload = e => {
        if (!e.target.result.includes('data:application/pdf') && (this.$route.params.type.split('-')[0] === 'NDA' || this.$route.params.type.split('-')[0] === 'MOU')) {
          notification.errorMessage('Please select a PDF file!')
        } else if (
          !e.target.result.includes('data:image/jpeg') &&
          !e.target.result.includes('data:image/jpg') &&
          !e.target.result.includes('data:image/png') &&
          this.$route.params.type.split('-')[0] !== 'NDA' &&
          this.$route.params.type.split('-')[0] !== 'MOU'
        ) {
          notification.errorMessage('Please select a JPG, JPEG, or PNG file!')
        } else if (e.target.result.length > this.maxFileDocument) {
          notification.errorMessage('File is larger than 20MB!')
        } else {
          this.newName = file.name
          this.typeFileDocument = file.type
          this.fileDocument = e.target.result
        }
      }
      reader.readAsDataURL(file)
      setTimeout(() => this.hideLoading(), 3500)
    },
    remove() {
      this.fileDocument = null
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-yellow')) {
        event.currentTarget.classList.remove('border-gray-300')
        event.currentTarget.classList.add('border-yellow')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('border-yellow')
      event.currentTarget.classList.add('border-gray-300')
    },
    drop(event) {
      if (!this.fileDocument) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()

        event.currentTarget.classList.add('border-gray-300')
        event.currentTarget.classList.remove('border-yellow')
      } else {
        this.showLoading()
        notification.errorMessage('Only 1 file can be upload!')
        setTimeout(() => this.hideLoading(), 3500)
      }
    },
    forGetUploadUrl() {
      this.showLoading()
      this.getUploadUrl({
        params: { contentType: this.typeFileDocument },
        success: res => this.uploadDocument(res),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          setTimeout(() => this.hideLoading(), 3500)
        }
      })
    },
    uploadDocument(response) {
      let binary = atob(this.fileDocument.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileDocument })
      this.updateUploadFile({
        url: response.uploadUrl,
        data: blobData,
        contentType: this.typeFileDocument,
        success: () => this.changeDocument(response.uploadUrl.split('?')[0]),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          setTimeout(() => this.hideLoading(), 3500)
        }
      })
    },
    changeDocument(documentUrl) {
      this.updateClientDocument({
        id: this.$route.params.clientId,
        regDocId: this.regDocId,
        requestBody: {
          comment: this.description,
          originalFileName: this.newName,
          partnerDocumentUrl: documentUrl
        },
        success: () => {
          notification.successMessage('Document changed successfully!')
          this.hideModalChangeDocument()
          this.$router.go(-1)
        },
        failed: () => notification.errorMessage('Failed to load data, please try again later!')
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    detailDocument() {
      this.showLoading()
      this.getDetailDocument({
        id: this.$route.params.clientId,
        documentId: this.$route.params.id,
        success: res => this.historyDocument(res),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
          this.$router.go(-1)
        }
      })
    },
    historyDocument(detail) {
      this.showLoading()
      this.getHistoryDocument({
        id: this.$route.params.clientId,
        regDocId: detail.registrationDocument.id,
        success: res => this.distributePayload(detail, res),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
          this.$router.go(-1)
        }
      })
    },
    async distributePayload(detail, history) {
      this.documentDetail = detail
      // this.companyName = detail.partner.companyName
      this.isArchived = this.mitraStatus === 'INACTIVE'
      this.isDocumentChanged = detail.status === 'NEED_APPROVAL'
      this.isDocumentNDAOrMoU = detail.registrationDocument.name === 'NDA Document' || detail.registrationDocument.name === 'MOU Document'
      this.currentName = this.toDocumentName(detail.fileUrl)
      this.date = converter.dateTimeView(detail.updatedDate)
      this.expirationDate = converter.dateTimeView(detail.expirationDate)
      this.documentUrl = detail.fileUrl
      this.regDocId = detail.registrationDocument.id
      this.historyDocumentList = [{}, {}]
      for (let i = 0; i < history.length; i++) {
        this.historyDocumentList.push({
          comment: history[i].comment,
          createdDate: converter.dateTimeView(history[i].createdDate),
          createdBy: history[i].createdBy,
          status: this.statusUserFriendly(history[i].status || ''),
          updatedDate: converter.dateTimeView(history[i].updatedDate)
        })
      }
      this.hideLoading()
    },
    changeDocumentStatus() {
      this.showLoading()
      this.updateDocumentStatus({
        id: this.$route.params.clientId,
        regDocId: this.regDocId,
        params: { status: this.forDocumentStatus ? 'APPROVED' : 'REJECTED' },
        success: () => {
          notification.successMessage(`Document ${this.forDocumentStatus ? 'approved' : 'rejected'} successfully!`)
          this.hideModalYesNo()
          this.hideModalHistory()
          this.$router.go(-1)
        },
        failed: () => notification.errorMessage('Failed to load data, please try again later!')
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    statusUserFriendly(value) {
      let newArr = []
      const statusArr = value.split('_')
      for (let i = 0; i < statusArr.length; i++) {
        newArr.push(statusArr[i].charAt(0).toUpperCase() + statusArr[i].slice(1).toLowerCase())
      }
      return newArr.join(' ')
    },
    toDocumentName(url) {
      const urlArr = url.split('/')
      return urlArr[urlArr.length - 1]
    },
    toYesNoModal(action) {
      this.forDocumentStatus = action
      this.isModalYesNo = true
    },
    toPage(name, index) {
      switch (index) {
        case 2:
          this.$router.push(`/${this.$route.params.category}`)
          break
        case 4:
          this.$router.push(`/${this.$route.params.category}/detail-client/${this.$route.params.clientId}`)
          break
        case 6:
        case 8:
          this.$router.push(`/${this.$route.params.category}/detail-client/${this.$route.params.clientId}/documents`)
          break
        default:
          this.$router.push('/active-clients')
          break
      }
    },
    showModalHistory(withButton) {
      withButton ? (this.hideButton = false) : (this.hideButton = true)
      this.isModalHistory = true
    },
    showModalChangeDocument() {
      this.isModalChangeDocument = true
    },
    hideModalYesNo() {
      this.isModalYesNo = false
    },
    hideModalHistory() {
      this.isModalHistory = false
    },
    hideModalChangeDocument() {
      this.remove()
      this.isModalChangeDocument = false
    }
  }
}
