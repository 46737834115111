<template>
  <div class="campaign-form">
    <p class="font-semibold mt-4 mb-9 text-2xl text-left">Add Campaign</p>
    <div class="flex gap-6 mb-4 border-b border-grey-field" :class="{ 'pb-4': !isEditMode }">
      <div class="flex-1">
        <div class="mb-4">
          <TextField borderEnabled type="text" label="Name" placeholder="Enter campaign name" v-model="payloads.campaignName" />
        </div>
        <div class="mb-4 leading-0">
          <label class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">Description</label>
          <textarea
            class="border-grey-field h-24 border leading-normal p-3 focus:outline-none w-full rounded-lg resize-none"
            placeholder="Enter campaign description"
            v-model="payloads.description"
          ></textarea>
        </div>
        <div class="mb-4">
          <label class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">Medium</label>
          <div class="relative">
            <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16"/></span>
            <select v-model="payloads.medium" class="border outline-none border-grey-field pl-3 py-3 w-full rounded-lg appearance-none bg-transparent relative z-1">
              <option value="null" disabled selected hidden style="color: #eee">Select medium</option>
              <option :value="item.value" v-for="item in mediumList" :key="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-6">
          <label class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">Source</label>
          <div class="relative">
            <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16"/></span>
            <select v-model="payloads.source" class="border outline-none border-grey-field pl-3 py-3 w-full rounded-lg appearance-none bg-transparent relative z-1">
              <option value="null" disabled selected hidden style="color: #eee">Select source</option>
              <option :value="item.value" v-for="item in sourceList" :key="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="mb-4">
          <TextField borderEnabled type="date" label="Start Date" placeholder="Select date" v-model="payloads.startDate" :max="payloads.endDate" />
        </div>
        <div class="mb-4">
          <TextField borderEnabled type="date" label="End Date" placeholder="Select date" v-model="payloads.endDate" :min="payloads.startDate" />
        </div>
        <div class="">
          <label class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">Amount Spent</label>
          <div class="relative">
            <TextField borderEnabled withIcon type="text" @keypress="NumbersOnly" maxlength="15" placeholder="Enter amount spent" v-model="payloads.amountSpent" />
            <div class="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-500 text-sm">Rp</div>
          </div>
        </div>
      </div>
      <div class="flex-1 text-left pl-6 border-l border-grey-field" v-if="isEditMode">
        <p class="text-lg font-semibold mb-4">Result</p>
        <div class="mb-4">
          <TextField borderEnabled type="number" label="Impression" placeholder="Enter impression" v-model.number="result.impression" />
        </div>
        <div class="mb-4">
          <TextField borderEnabled type="number" label="Click" placeholder="Enter click" v-model.number="result.actionClick" />
        </div>
        <div class="mb-4">
          <TextField borderEnabled type="number" label="Cost Per Click" placeholder="Enter cost per click" v-model.number="result.costPerClick" />
        </div>
        <div class="mb-4">
          <TextField borderEnabled type="number" label="Registration Complete" placeholder="Enter registration complete" v-model.number="result.registration" />
        </div>
      </div>
    </div>
    <div class="flex justify-center gap-4 items-center">
      <Button :buttonText="isEditMode ? 'Update' : 'Save'" type="primary" @action="submit()" :disabled="isDisabled" />
      <Button buttonText="Delete" type="delete" @action="deleteItem()" v-if="isEditMode" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/forms/TextField'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Chevron'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    campaignId: {
      type: [String, Number],
      default: null
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      payloads: {
        campaignName: null,
        description: null,
        startDate: null,
        endDate: null,
        amountSpent: null,
        source: null,
        medium: null
      },
      result: {
        impression: null,
        actionClick: null,
        costPerClick: null,
        registration: null
      },
      sourceList: [
        {
          label: 'LinkedIn',
          value: 'LINKEDIN'
        },
        {
          label: 'Google',
          value: 'GOOGLE'
        },
        {
          label: 'Meta',
          value: 'META'
        },
        {
          label: 'Blog',
          value: 'BLOG'
        },
        {
          label: 'Email',
          value: 'EMAIL'
        },
        {
          label: 'Event',
          value: 'EVENT'
        },
        {
          label: 'Other',
          value: 'OTHER'
        }
      ],
      mediumList: [
        {
          label: 'Post',
          value: 'POST'
        },
        {
          label: 'Photo',
          value: 'PHOTO'
        },
        {
          label: 'Video',
          value: 'VIDEO'
        },
        {
          label: 'Event',
          value: 'EVENT'
        },
        {
          label: 'Offline',
          value: 'OFFLINE'
        },
        {
          label: 'Other',
          value: 'OTHER'
        }
      ]
    }
  },
  mounted() {
    if (this.isEditMode) {
      this.getCampaignDetailInfo()
    }
  },
  computed: {
    isDisabled() {
      let disabled = false
      Object.keys(this.payloads).forEach(item => {
        if (!this.payloads[item]) {
          disabled = true
        }
      })
      return disabled
    }
  },
  methods: {
    ...mapActions(['getCampaignDetail']),
    NumbersOnly(evt) {
      evt = evt || window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    deleteItem() {
      this.$emit('delete', this.campaignId)
    },
    getCampaignDetailInfo() {
      this.getCampaignDetail({
        campaignId: this.campaignId,
        success: res => {
          const data = res.body.data
          this.payloads = {
            campaignName: data.campaignName,
            description: data.description,
            amountSpent: data.amountSpent,
            medium: data.medium || null,
            source: data.source || null,
            startDate: `${dayjs(data.startDate).format('YYYY-MM-DD')}`,
            endDate: `${dayjs(data.endDate).format('YYYY-MM-DD')}`
          }
          this.result = {
            impression: data.impression,
            actionClick: data.actionClick,
            costPerClick: data.costPerClick,
            registration: data.registration
          }
        },
        failed: () => {}
      })
    },
    submit() {
      const data = {
        campaignId: this.campaignId,
        payloads: {
          ...this.payloads,
          ...this.result
        }
      }
      this.$emit('submit', data)
    }
  }
}
</script>
<style lang="scss" scoped></style>
