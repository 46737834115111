import { mapActions } from 'vuex'
import moment from 'moment'
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
const PagePrakerja = () => import('@/components/PagePrakerja')
const PageJaringanKerjasama = () => import('@/components/PageJaringanKerjasama')
const PagePrakerjaClass = () => import('@/components/PrakerjaClass')
const PagePopup = () => import(/* webpackChunkName: "page-popup" */ '@/components/PagePopUp')

export default {
  name: 'homepage-popup',
  components: {
    PageHeader,
    PagePopup,
    PagePrakerja,
    PageJaringanKerjasama,
    PagePrakerjaClass
  },
  data: () => ({
    popUpPayload: {
      description: '',
      endDate: '',
      labelBtn1: '',
      labelBtn2: '',
      startDate: '',
      title: '',
      toUrl: '',
      url: ''
    },
    popUpId: null
  }),
  computed: {
    isResetAvailable() {
      let result = true
      if (this.popUpId === null) result = false
      return result
    }
  },
  mounted() {
    this.getPopUpData()
  },
  methods: {
    ...mapActions(['getPopUpContent', 'savePopUpContent', 'updatePopUpContent', 'deletePopUpContent', 'showLoading', 'hideLoading']),
    getPopUpData() {
      this.showLoading()
      const params = {
        page: 0,
        size: 1
      }

      this.getPopUpContent({
        params,
        success: res => {
          if (res.length > 0) {
            this.popUpPayload = {
              description: res[0].descs,
              startDate: this.utcToLocal(res[0].startDate) || '',
              endDate: this.utcToLocal(res[0].endDate) || '',
              labelBtn1: res[0].btnLabel1,
              labelBtn2: res[0].btnLabel2,
              url: res[0].image,
              title: res[0].title,
              toUrl: res[0].toUrl
            }
            this.popUpId = res[0].id
            return this.hideLoading()
          }
          this.hideLoading()
        },
        failed: () => {}
      })
    },

    savePopUp() {
      const checkDate = moment(this.popUpPayload.startDate).isSameOrAfter(this.popUpPayload.endDate)
      if (checkDate) {
        return this.$toast.success('Start date must be greater than end date!', {
          position: 'top',
          type: 'warning'
        })
      }

      const checkPayload = Object.values(this.popUpPayload).includes('')

      if (checkPayload) {
        return this.$toast.success('Fill in the pop up parameters correctly !', {
          position: 'top',
          type: 'warning'
        })
      }
      this.showLoading()
      const payload = { ...this.popUpPayload, image: this.popUpPayload.url }
      if (this.popUpId === null) {
        this.savePopUpContent({
          payload,
          success: res => {
            this.popUpId = res.data.id
            this.hideLoading()
            this.$toast.success('Data Saved Successfully !', {
              position: 'top',
              type: 'success'
            })
          },
          failed: () => {
            this.hideLoading()
            this.$toast.success('Failed !', {
              position: 'top',
              type: 'failed'
            })
          }
        })
        return
      }
      this.updatePopUpContent({
        id: this.popUpId,
        payload,
        success: () => {
          this.hideLoading()
          this.$toast.success('Data Updated Successfully !', {
            position: 'top',
            type: 'success'
          })
        },
        failed: () => {
          this.hideLoading()
          this.$toast.success('Failed !', {
            position: 'top',
            type: 'failed'
          })
        }
      })
    },
    clearData() {
      this.deletePopUpContent({
        id: this.popUpId,
        success: () => {
          this.getPopUpData()
          this.popUpId = null
          this.popUpPayload = {
            description: '',
            endDate: '',
            labelBtn1: '',
            labelBtn2: '',
            startDate: '',
            title: '',
            toUrl: '',
            url: ''
          }
        },
        failed: () => {}
      })
    },
    utcToLocal(dateSelected) {
      return moment.parseZone(dateSelected).format('YYYY-MM-DD')
    }
  }
}
