import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
export default {
  name: 'archived-clients-new',
  components: {
    PageTitle: () => import(/* webpackChunkName: "page-title" */ '@/components/others/PageTitle'),
    FindingData: () => import(/* webpackChunkName: "finding-data" */ '@/components/others/FindingData'),
    TableList: () => import(/* webpackChunkName: "table-list" */ '@/components/others/TableList')
  },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    keyword: '',
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    mitraList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Source',
        options: []
      },
      {
        label: 'Status',
        options: []
      },
      // {
      //   label: 'Cooperation Type',
      //   options: [
      //     { id: 'MANPOWER', name: 'Manpower' },
      //     { id: 'LMS', name: 'LMS & Prakerja' },
      //     { id: 'MARKETPLACE', name: 'Marketplace (Eduverse)' },
      //     { id: 'PROJECT', name: 'Project' },
      //     { id: 'EDUCATION', name: 'Education' },
      //     { id: 'LP_CURATION', name: 'Curation' }
      //   ]
      // },
      // {
      //   label: 'Role',
      //   options: [
      //     { id: '', name: 'All' },
      //     { id: 'ADMIN', name: 'LMS Admin' },
      //     { id: 'REGISTRANT', name: 'Talent Partner' },
      //     { id: 'LP_CURATION', name: 'LP Curation' }
      //   ]
      // },
      {
        label: 'Sort By',
        options: [
          { id: 'createdDate', name: 'Register Date' },
          { id: 'updatedDate', name: 'Last Update' }
        ]
      },
      {
        label: 'Order',
        options: [
          { id: 'DESC', name: 'Latest' },
          { id: 'ASC', name: 'Oldest' }
        ]
      }
    ],
    mitraSource: [],
    mitraStatus: []
  }),
  computed: {
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: i < 2 ? [] : this.forOptions[i]?.options[0]?.id || ''
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.mitraSource,
          mode: 'multiple-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0]?.value : this.forFilterSelect[0]?.value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.mitraStatus,
          mode: 'multiple-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1]?.value : this.forFilterSelect[1]?.value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        },
        // {
        //   label: this.forOptions[2].label,
        //   options: this.forOptions[2].options,
        //   mode: 'multiple-select',
        //   readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
        //   writeValue: response => (this.forFilterSelect[2].value = response)
        // },
        {
          label: this.forOptions[2].label,
          options: this.forOptions[2].options,
          mode: 'v-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
          writeValue: response => (this.forFilterSelect[2].value = response)
        },
        {
          label: this.forOptions[3].label,
          options: this.forOptions[3].options,
          mode: 'v-select',
          readValue: this.filterSelect.length > 0 ? this.filterSelect[3].value : this.forFilterSelect[3].value,
          writeValue: response => (this.forFilterSelect[3].value = response)
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchMitra(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        for (let i = 0; i < this.filterSelect.length; i++) {
          if (typeof this.filterSelect[i].value === 'object' && this.filterSelect[i].value.length > 0) {
            this.hide = false
            break
          }
        }
        // this.filterSelect[0].value.length > 0 ? (this.hide = false) : (this.hide = true)
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.getMitraList()
    this.getMitraSource()
    this.getMitraStatus()
  },
  methods: {
    ...mapActions(['getAllMitra', 'getAllSource', 'getAllStatus', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getMitraList()
    },
    getMitraSource() {
      this.getAllSource({
        params: {
          sortBy: 'sourceName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraSource.push({
              id: item.source_name,
              name: item.source_name
            })
          })
          this.forOptions[0].options = this.mitraSource
        }
      })
    },
    getMitraStatus() {
      this.getAllStatus({
        params: {
          sortBy: 'stageName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraStatus.push({
              id: item.stage_name,
              name: item.stage_name
            })
          })
          this.forOptions[1].options = this.mitraStatus
        }
      })
    },
    getMitraList() {
      this.showLoading()
      this.getAllMitra({
        type: 'INACTIVE',
        params: {
          page: this.currentPage,
          size: this.size,
          query: this.keyword,
          core_source_names: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
          core_stage_names: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
          // types: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
          types: '',
          // roles: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
          sortBy: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
          direction: this.filterSelect.length > 0 ? this.filterSelect[3].value : ''
        },
        success: res => this.distributePayload(res),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.clearSearch()
          this.hideLoading()
        }
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getMitraList()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.mitraList = []
      res.data.forEach(mitra => {
        this.mitraList.push({
          buttonCondition: mitra.needApproval,
          firstQuery: mitra.mitra_id,
          firstColumn: mitra.company_name,
          firstColumnDesc: '',
          secondColumn: mitra.pic_name,
          thirdColumn: mitra.pic_phone,
          fourthColumn: mitra.pic_email,
          fifthColumn: mitra.status,
          sixthColumn: mitra.sources,
          seventhColumn: converter.dateTimeView(mitra.updated_date)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(type, data) {
      let forReturn = '-'
      let array = []
      data.forEach(element => {
        switch (type) {
          case 'cooperationType':
            for (let j = 0; j < this.forOptions[0].options.length; j++) {
              if (this.forOptions[0].options[j].id === element.name) array.push(this.forOptions[0].options[j].name)
            }
            break
          case 'roles':
            for (let j = 0; j < this.forOptions[1].options.length; j++) {
              if (this.forOptions[1].options[j].id === element) array.push(this.forOptions[1].options[j].name)
            }
            break
        }
      })
      if (array.length > 0) forReturn = array.join(', ')
      return forReturn
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    searchMitra(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getMitraList()
    },
    filter() {
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getMitraList()
    },
    clearFilter() {
      this.hide = true
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        i < 2 ? (this.forFilterSelect[i].value = []) : (this.forFilterSelect[i].value = this.forOptions[i].options[0].id)
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getMitraList()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      // this.getMitraList()
    },
    toPage() {
      this.$router.push('/active-clients')
    },
    toDetailMitra(id) {
      this.$router.push({ path: `/archived-clients/detail-client/${id}` })
    }
  }
}
