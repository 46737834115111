import ImageUploadMixin from '@/mixins/image-upload'
import VueSingleSelect from 'vue-single-select'
export default {
  name: 'business-partner',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    iconOptions: [
      'beasiswa',
      'konsultasi',
      'magang',
      'project',
      'talent',
      'training'
    ]
  }),
  computed: {
    sequenceOptions() {
      return Array.from(
        { length: this.payload.cooperateNetItemHomePages.length },
        (_, i) => i + 1
      )
    }
  },
  methods: {
    addMoreCard() {
      const item = {
        url: '',
        title: '',
        seqNo: '',
        benefitHomePages: [
          {
            icon: '',
            slug: '',
            seqNo: 1
          }
        ]
      }
      this.payload.cooperateNetItemHomePages.push(item)
    },
    addMoreBenefit(idx) {
      const list = this.payload.cooperateNetItemHomePages[idx].benefitHomePages
      const length = list.length
      const lastItem = list[length - 1]
      const item = {
        icon: '',
        slug: '',
        seqNo: lastItem.seqNo + 1
      }
      this.payload.cooperateNetItemHomePages[idx].benefitHomePages.push(item)
    },
    deleteBenefit(idx, indexBenefit) {
      this.payload.cooperateNetItemHomePages[idx].benefitHomePages.splice(
        indexBenefit,
        1
      )
    },
    deleteCard(idx) {
      this.payload.cooperateNetItemHomePages.splice(idx, 1)
    },
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage(item, idx) {
      this.$refs['filebusiness' + idx][0].click()
    }
  }
}
