import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {
  classList: {},
  classDetail: {},
  programs: []
}
const actions = {
  getClassList: ({ commit }, { params, success }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(config.api.class + serializeQueryParams(params)).then(response => {
        success && success(response.body.data)
        commit('setClassList', response.body.data)
        resolve(response)
      })
    })
  },
  createClass: ({ commit }, { requestBody, success }) => {
    Vue.http
      .post(config.api.class, requestBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body)
      })
  },
  getClassDetail: ({ commit }, { id, success }) => {
    Vue.http.get(config.api.class + '/' + id).then(response => {
      success && success(response.body.data)
      commit('setClassDetail', response.body.data)
    })
  },
  getPrograms: ({ commit }, { success }) => {
    Vue.http.get(config.api.programs).then(response => {
      commit('setPrograms', response.body.data)
      success && success(response.body.data)
    })
  },
  updateClass: ({ commit }, { id, requestBody, success }) => {
    Vue.http
      .put(config.api.class + '/' + id, requestBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body)
        // commit('setClassDetail', response.body.data)
      })
  },
  deleteCLass: ({ commit }, { id, success }) => {
    Vue.http
      .delete(config.api.class + '/' + id, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        // commit('setClassDetail', response.body.data)
      })
  }
}
const mutations = {
  setClassList(state, value) {
    state.classList = value
  },
  setClassDetail(state, value) {
    state.classDetail = value
  },
  setPrograms(state, value) {
    state.programs = value
  }
}
const getters = {
  classList: state => {
    return state.classList
  },
  classDetail: state => {
    return state.classDetail
  },
  programs: state => {
    return state.programs
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
