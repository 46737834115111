import { mapGetters, mapActions } from 'vuex'
import ImageUploadMixin from '@/mixins/image-upload'
import VueSingleSelect from 'vue-single-select'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'edit-testimony',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect,
    quillEditor
  },
  data: () => ({
    classTypeOptions: ['Prakerja', 'Bootcamp', 'International', 'Regular'],
    option: {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['clean']]
      }
    },
    testimony: {
      message: '',
      placement: '',
      type: '',
      userName: '',
      userPhoto: ''
    }
  }),
  mounted() {
    this.getDetail()
  },
  computed: {
    ...mapGetters(['testimonyDetail']),
    testimonyId() {
      return this.$route.params.id
    }
  },
  methods: {
    ...mapActions(['getTestimonyDetail', 'editTestimony', 'showLoading', 'hideLoading']),
    getDetail() {
      this.getTestimonyDetail({
        id: this.testimonyId,
        success: res => {
          this.testimony = {
            message: res.message,
            placement: res.placement,
            type: res.type || '',
            userName: res.userName,
            userPhoto: res.userPhoto
          }
        }
      })
    },
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage() {
      this.$refs.filecore.click()
    },
    save() {
      const payload = {
        message: this.testimony.message,
        placement: this.testimony.placement,
        type: this.testimony.type,
        userName: this.testimony.userName,
        userPhoto: this.testimony.userPhoto
      }
      this.showLoading()
      this.editTestimony({
        id: this.testimonyId,
        payload: payload,
        success: () => {
          this.$toast.success('Your Testimony Data is Successfully Updated!', {
            position: 'top'
          })
          this.hideLoading()
          this.goBack()
        }
      })
    },
    goBack() {
      this.$router.push('/daftar-testimoni')
    }
  }
}
