import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/reset.css'
import VuePageTransition from 'vue-page-transition'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'tailwindcss/tailwind.css'
import VueGoodTablePlugin from 'vue-good-table'
import VueConfirmDialog from 'vue-confirm-dialog'
import moment from 'moment'
import VModal from 'vue-js-modal'

Vue.use(VueConfirmDialog)
Vue.use(VueGoodTablePlugin)
Vue.prototype.moment = moment
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VuePageTransition)
Vue.use(VueToast)
Vue.use(VModal)
Vue.config.productionTip = false
Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (response.status === 401 || response.status === 403 || response.status === 502 || response.status === 503 || response.status === 0) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_email')
      localStorage.removeItem('user_role')
      localStorage.removeItem('user_team')
      router.push({ name: response.status === 401 || response.status === 403 ? 'login' : 'ServerDown' })
    }
  })
})
Vue.http.headers.common['X-G2-TimeZone-Offset'] = String(new Date().getTimezoneOffset())

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
