import VueSingleSelect from 'vue-single-select'
import ImageUploadMixin from '@/mixins/image-upload'

export default {
  name: 'tentang-prakerja',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    statusOptions: ['ACTIVE', 'NONACTIVE']
  }),
  methods: {
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage() {
      this.$refs.fileInput.click()
    }
  }
}
