<template>
  <div>
    <div class="dropdown text-sm" :tabindex="tabindex" @blur="handleBlur($event)">
      <div
        class="selected block p-3 text-neutral-700"
        :class="[disabled ? 'cursor-not-allowed bg-gray-100 opacity-50' : 'cursor-pointer', { open: open }]"
        @click="toggleDropdown()"
        :style="isSmallSize ? 'height:44px' : ''"
      >
        <span v-if="placeholderText" class="placeholder">{{ placeholderText }}</span>
        <template v-else>
          <span class="option-label pr-6" :class="[additionalClass, { uppercase: uppercase, capitalize: capitalize }]" :title="selected[optionLabel]" v-if="selected && optionLabel">{{
            selected[optionLabel]
          }}</span>
          <span class="option-label pr-6" :class="[additionalClass, { uppercase: uppercase, capitalize: capitalize }]" :title="selected" v-else>{{ selected }}</span>
        </template>
        <span class="drop-arrow absolute right-3 top-1/2 transform -translate-y-1/2 leading-0" :class="{ expanded: open }">
          <Chevron direction="down" width="14" height="14" />
        </span>
      </div>
      <div class="content py-2" :class="{ selectHide: !open }">
        <div class="p-4 bg-neutral-200 shadow-border-b flex gap-2 items-center" v-if="enableSearch">
          <span class="flex-shrink-0"><Search width="16" height="16"/></span>
          <input type="text" class="bg-transparent outline-none border-none text-sm" placeholder="Search" v-on:keyup.enter="search()" v-model="searchKeyword" />
        </div>
        <template v-if="options.length > 0">
          <div
            v-for="(option, i) of options"
            :key="i"
            class="px-3 py-2 leading-tight text-neutral-700 hover:bg-yellow-primary cursor-pointer text-white"
            :class="{ 'bg-neutral': option === selected }"
            @click="selectItem(option)"
          >
            <span class="option-label" :class="{ uppercase: uppercase, capitalize: capitalize }" :title="option[optionLabel]" v-if="optionLabel">{{ option[optionLabel] }}</span>
            <span class="option-label" :class="{ uppercase: uppercase, capitalize: capitalize }" :title="option" v-else>{{ option }}</span>
          </div>
        </template>
        <div class="text-sm text-white text-center" v-else>- Data is not available -</div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Search')
  },
  props: {
    enableSearch: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Pilih'
    },
    optionLabel: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    default: {
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    isReload: {
      type: Boolean,
      default: false
    },
    isSmallSize: {
      type: Boolean,
      default: false
    },
    additionalClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: this.default ? this.default : null,
      open: false,
      placeholderText: this.default ? null : this.placeholder,
      searchKeyword: ''
    }
  },
  watch: {
    default() {
      this.placeholderText = this.default ? null : this.placeholder
      this.selected = this.default ? this.default : null
    },
    options() {
      this.placeholderText = this.default ? null : this.placeholder
      this.selected = this.default ? this.default : null
    },
    isReload() {
      this.selected = this.default ? this.default : null
      this.placeholderText = this.placeholder
    }
  },
  mounted() {
    this.$emit('input', this.selected)
  },
  methods: {
    handleBlur(event) {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.open = false
      } else {
        this.open = true
      }
    },
    selectItem(option) {
      this.selected = option
      this.open = false
      this.placeholderText = null
      this.$emit('input', option)
      this.$emit('change', option)
    },
    toggleDropdown() {
      if (this.disabled) {
        return
      }
      this.open = !this.open
    },
    search() {
      this.$emit('search', this.searchKeyword)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}
.dropdown .selected {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ced5de;
  position: relative;
  // cursor: pointer;
  user-select: none;
  z-index: 1;
  .drop-arrow {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
  }
  .expanded {
    transform: rotateZ(180deg) translateY(7px);
  }
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.selectHide {
  display: none;
}
.placeholder {
  color: #9ca3af;
}
.content {
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  z-index: 10;
  margin-top: -1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.p-3 {
  padding: 12px !important;
}
.option-label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
