import ImageUploadMixin from '@/mixins/image-upload'

export default {
  name: 'jaringan-kerjasama',
  mixins: [ImageUploadMixin],
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    addMoreBanner() {
      const newItem = {
        id: '',
        title: '',
        url: '',
        name: ''
      }
      this.payload.items.push(newItem)
    },
    deleteBanner(idx) {
      this.payload.items.splice(idx, 1)
    },
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage(item, idx) {
      this.$refs['fileInput' + idx][0].click()
    }
  }
}
