import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
Vue.use && Vue.use(VueResource)

const state = {
  instructorList: {},
  instructorDetail: {}
}
const actions = {
  getInstructorList: ({ commit }, { success }) => {
    Vue.http.get(`${config.api.instructor}/all`).then(response => {
      success && success(response.body.data)
      commit('setInstructorList', response.body.data)
    })
  },
  createInstructor: ({ commit }, { payload, success }) => {
    Vue.http
      .post(config.api.instructor, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  },
  getInstructorDetail: ({ commit }, { id, success }) => {
    Vue.http.get(config.api.instructor + '/' + id).then(response => {
      success && success(response.body.data)
      commit('setInstructorDetail', response.body.data)
    })
  },
  editInstructor: ({ commit }, { id, payload, success }) => {
    Vue.http
      .put(config.api.instructor + '/' + id, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  },
  deleteInstructor: ({ commit }, { id, success }) => {
    Vue.http
      .delete(config.api.instructor + '/' + id, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
  },
  getCustomerType: ({ commit }, { success }) => {
    Vue.http
      .get(config.api.customer, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response)
      })
  }
}
const mutations = {
  setInstructorList(state, value) {
    state.instructorList = value
  },
  setInstructorDetail(state, value) {
    state.instructorDetail = value
  }
}
const getters = {
  instructorList: state => {
    return state.instructorList
  },
  instructorDetail: state => {
    return state.instructorDetail
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
