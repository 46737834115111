import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import VueSingleSelect from 'vue-single-select'
import ImageUploadMixin from '@/mixins/image-upload'
export default {
  name: 'page-popup',
  mixins: [ImageUploadMixin],
  components: {
    VueSingleSelect,
    quillEditor
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    minDate() {
      return this.moment().format('YYYY-MM-DD')
    }
  },
  data: () => ({
    option : {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['clean']]
      }
    },
    statusOptions: ['PUBLISH', 'NOT PUBLISH'],
    disabledStatus: true
  }),
  methods: {
    handleFileSelect(evt, item) {
      this.uploadImage(evt, item)
    },
    changeImage() {
      this.$refs.fileInput.click()
    }
  }
}
