export default {
  name: 'grouping-class',
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => { }
    },
    listName: {
      type: String,
      default: 'Group'
    },
    classList: {
      type: Array,
      required: false,
      default: () => []
    },
    deleteGroup: {
      type: Function,
      required: false,
      default: () => []
    },
    loadMoreClassList: {
      type: Function,
      required: false,
      default: () => []
    },
    index: {
      type: Number,
      required: false,
      default: 0
    },
    length: {
      type: Number,
      required: false,
      default: 0
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showSequence: {
      type: Boolean,
      default: true
    },
    isFetching: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isResultVisible: false,
    isResultActive: false,
    searchKeyword: '',
    currentPage: 1,
    totalData: 0,
    pageSize: 10,
    programType: '',
    isResultListVisible: false,
    isResultLitsOver: false
  }),

  watch: {
    activeClassId() {
      if (this.isDuplicate(this.payload.classes)) {
        this.$emit('removeDuplicate', this.index)
        this.$toast.success('Duplicate Class !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }
    }
  },

  computed: {
    sequenceOptions() {
      return Array.from({ length: this.length }, (_, i) => i + 1)
    },
    activeClassId() {
      if (this.payload.classes.length === 0) return null
      return this.payload.classes[this.payload.classes.length - 1].id
    }
  },
  methods: {
    searchClass(keyword, classIndex) {
      this.isResultListVisible = true
      setTimeout(() => {
        this.$forceUpdate()
      }, 100)
      this.$emit('searchClass', keyword, this.index, classIndex)
    },
    mouseOver() {
      this.isResultLitsOver = true
    },
    inputLeave(classIndex) {
      setTimeout(() => {
        if (this.isResultListVisible && !this.isResultLitsOver) {
          setTimeout(() => {
            this.$forceUpdate()
            this.isResultListVisible = false
            this.isResultLitsOver = false
          }, 100)
          this.$emit('mouseLeave', this.index, classIndex)
        }
      }, 300)
    },

    mouseLeave(classIndex) {
      setTimeout(() => {
        this.$forceUpdate()
        this.isResultListVisible = false
        this.isResultLitsOver = false
      }, 100)
      this.$emit('mouseLeave', this.index, classIndex)
    },
    selectedClass(selectedClass, classIndex) {
      this.isResultListVisible = false
      this.isResultLitsOver = false
      const data = this.payload.classes.filter(item => item.id !== null)
      setTimeout(() => {
        this.$forceUpdate()
      }, 100)
      data.push(selectedClass)
      if (this.isDuplicate(data)) {
        this.$emit('removeDuplicate', this.index)
        this.$toast.success('Duplicate Class !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
        return
      }
      this.$emit('selectedClass', selectedClass, this.index, classIndex)
    },

    isDuplicate(payload) {
      let valueArr = payload.map(function (item) {
        return item.id
      })
      let isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      })
      return isDuplicate
    },
    async addMoreItem() {
      if (this.payload.title === '') {
        return this.$toast.success('Title Cannot Be Empty!', {
          position: 'top',
          type: 'warning',
          queue: true
        })
      }
      let result = false
      if (this.payload.classes.length > 0) {
        const classItem = this.payload.classes[this.payload.classes.length - 1]
        if (classItem.id === null) {
          return this.$toast.success('Choose Class Correctly!', {
            position: 'top',
            type: 'warning',
            queue: true
          })
        }

        if (this.payload.classes.length > 1) {
          result = await this.isDuplicate(this.payload.classes)
        }
      }

      if (result) {
        this.$emit('removeDuplicate', this.index)
        this.$toast.success('Duplicate Class !', {
          position: 'top',
          type: 'warning',
          queue: true
        })
        return
      }
      const item = {
        id: null,
        seqNo: this.payload.classes.length + 1
      }
      this.payload.classes.push(item)
    },
    deleteItem(idx) {
      this.payload.classes.splice(idx, 1)
    }
  }
}
