<template>
  <div>
    <Modal :name="'account'" :height="520" :visibility="isModalAccount" :closed="hideModal">
      <template slot="modal-content">
        <div class="py-5 px-12">
          <div class="flex justify-center items-center">
            <div class="flex justify-center items-center h-24 w-24 p-0.5 my-1 rounded-full border border-neutral-500">
              <div class="p-1 bg-yellow h-full w-full rounded-full border border-neutral-500 flex justify-center items-center">
                <p :class="`font-bold text-white ${name && name !== '' && forInitial(name).length > 1 ? 'text-4xl' : 'text-5xl'}`">{{ name && name !== '' ? forInitial(name) : 'A' }}</p>
              </div>
            </div>
          </div>
          <div class="py-2" v-for="(input, index) in inputs" :key="index + Math.random()">
            <InputWithLabel :labelText="input.label" :type="input.type" :disabled="input.disabled" :readValue="input.readValue" :writeValue="input.writeValue" />
          </div>
          <hr class="mt-2 bg-neutral-500" />
          <div class="py-2" v-for="(password, index) in passwords" :key="index + Math.random()">
            <InputWithLabel
              :labelText="password.label"
              :type="password.type"
              :minlength="password.minlength"
              :disabled="password.disabled"
              :readValue="password.readValue"
              :writeValue="password.writeValue"
            />
          </div>
          <div class="pt-2">
            <ButtonsGroup :justifyContent="'end'" :buttons="buttons" />
          </div>
        </div>
      </template>
    </Modal>
    <div class="bg-neutral h-14">
      <div class="flex justify-between px-5 items-center">
        <div class="h-14 flex justify-center items-center">
          <img src="~@/assets/images/Logo.svg" class="px-2 h-11" />
        </div>
        <div class="flex w-1/2 h-full justify-end items-center gap-4">
          <div class="text-white border-r border-white h-8 flex flex-col justify-center pr-4 relative">
            <div class="menu-item">
              <div class="flex justify-center items-center mb-1 cursor-pointer">
                <span class="inline-block relative">
                  <span class="bg-system-error text-white absolute -right-1 -top-1 text-10 w-4 h-4 flex justify-center items-center rounded-full">{{ notificationCount }}</span>
                  <Notification color="white" />
                </span>
              </div>
              <div class="submenu absolute right-0 shadow-sm w-screen max-w-md z-10">
                <NotificationMenu @refetch="refetchNotification()" />
              </div>
            </div>
          </div>
          <div class="flex flex-col ">
            <h1 class="font-semibold text-white text-sm text-right">{{ name && name !== '' ? name : 'Anonymous' }}</h1>
            <div @click="logout()" class="flex justify-end items-center cursor-pointer hover:opacity-80">
              <img src="@/assets/images/exit-outline.png" class="h-3 w-3 mr-1" />
              <h3 class="pt-0.5 font-normal text-xs text-red text-right">Logout</h3>
            </div>
          </div>
          <div @click="showModal()" class="h-full p-0.5 rounded-full border border-neutral-500 cursor-pointer hover:opacity-80">
            <div class="p-1 h-9 w-9 bg-yellow rounded-full border border-neutral-500 flex justify-center items-center">
              <p :class="`font-bold text-sm text-white ${name && name !== '' && forInitial(name).length > 1 ? '' : 'text-lg'}`">{{ name && name !== '' ? forInitial(name) : 'A' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { notification } from '@/util'
export default {
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/forms/InputWithLabel'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/others/Modal'),
    Notification: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Notification'),
    NotificationMenu: () => import(/* webpackChunkName: "NotificationMenu" */ '@/components/notification/NotificationMenu'),
    ButtonsGroup: () => import(/* webpackChunkName: "buttons-group" */ '@/components/button/ButtonsGroup')
  },
  data: () => ({
    isModalAccount: false,
    pass: false,
    disabled: false,
    name: '',
    email: '',
    oldPassword: { read: '', write: '' },
    newPassword: { read: '', write: '' }
  }),
  computed: {
    ...mapGetters(['userData']),
    ...mapGetters('notification', ['notifications', 'notificationCount']),
    inputs: function() {
      return [
        {
          label: 'Name',
          type: 'text',
          readValue: this.name,
          writeValue: value => this.forWriteValue(1, value),
          disabled: true
        },
        {
          label: 'Email',
          type: 'text',
          readValue: this.email,
          writeValue: value => this.forWriteValue(2, value),
          disabled: true
        }
      ]
    },
    passwords: function() {
      return [
        {
          label: 'Current Password',
          type: 'password',
          readValue: this.oldPassword.read,
          writeValue: value => this.forWriteValue(3, value)
        },
        {
          label: 'New Password',
          type: 'password',
          readValue: this.newPassword.read,
          writeValue: value => this.forWriteValue(4, value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.hideModal(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.save(),
          text: 'Save',
          type: 'secondary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    }
  },
  watch: {
    'newPassword.write': function(newValue) {
      if (newValue.length >= 4) this.pass = true
    }
  },
  mounted() {
    this.name = localStorage.getItem('user_name')
    this.email = localStorage.getItem('user_email')
  },
  methods: {
    ...mapActions(['removeUserData', 'changePassword', 'showLoading', 'hideLoading']),
    refetchNotification() {
      this.$store.dispatch('notification/getNotifications', {
        userId: this.userData.id,
        params: {
          page: 0,
          size: 500,
          platform: 'PLATFORM_CENTER'
        },
        success: () => {
          this.refetchNotificationCount()
        }
      })
    },
    refetchNotificationCount() {
      this.$store.dispatch('notification/getNotificationCount', {
        userId: this.userData.id,
        params: {
          platform: 'PLATFORM_CENTER'
        }
      })
    },
    forInitial(name) {
      const arr = name.split(' ')
      let newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    },
    forWriteValue(number, value) {
      switch (number) {
        case 1:
          this.name = value
          break
        case 2:
          this.email = value
          break
        case 3:
          this.oldPassword.write = value
          break
        case 4:
          this.newPassword.write = value
          break
      }
    },
    showModal() {
      this.isModalAccount = true
    },
    hideModal() {
      this.isModalAccount = false
    },
    async save() {
      this.oldPassword.read = this.oldPassword.write
      this.newPassword.read = this.newPassword.write
      this.disabled = true
      this.showLoading()
      if (this.oldPassword.write === '') {
        notification.errorMessage('Current password is required!')
      } else if (this.newPassword.write === '') {
        notification.errorMessage('New password is required!')
      } else {
        if (this.pass) {
          this.changePassword({
            reqBody: {
              currentPassword: this.oldPassword.write,
              email: this.email,
              newPassword: this.newPassword.write
            },
            success: () => {
              notification.successMessage('Change password successfully!')
              this.oldPassword = { read: '', write: '' }
              this.newPassword = { read: '', write: '' }
              this.hideModal()
            },
            failed: res => {
              if (res.errors.error[0].slice(0, 19) === 'INVALID_CREDENTIALS') {
                notification.errorMessage('Your current password is invalid, please check the value and then try again later!')
              } else {
                notification.errorMessage('Failed to change password, please try again later!')
              }
            }
          })
        } else {
          notification.errorMessage('Minimum 4 characters for the new password!')
        }
      }
      setTimeout(() => {
        this.hideLoading()
        this.disabled = false
      }, 3500)
    },
    async logout() {
      await localStorage.removeItem('access_token')
      await localStorage.removeItem('user_name')
      await localStorage.removeItem('user_email')
      await localStorage.removeItem('user_role')
      await localStorage.removeItem('user_team')
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
.user {
  &:hover .dropdown {
    display: flex;
    opacity: 1;
    padding-top: 15px;
  }
}
.submenu {
  visibility: hidden;
  opacity: 0;
  padding-top: 0;
  transition: all 300ms ease-out;
}
.menu-item:hover .submenu {
  visibility: visible;
  opacity: 1;
  padding-top: 8px;
}
</style>
