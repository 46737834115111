import { mapGetters, mapActions } from 'vuex'
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader')
const PageCore = () => import(/* webpackChunkName: "page-core" */ '@/components/PageCore')
const PageSlideBanner = () => import(/* webpackChunkName: "slide-banner" */ '@/components/PageSlideBanner')
const PageOurStrength = () => import(/* webpackChunkName: "our-strenght" */ '@/components/PageOurStrength')
const PageBusinessPartner = () => import(/* webpackChunkName: "page-business-partner" */ '@/components/PageBusinessPartner')
const PageTestimoni = () => import(/* webpackChunkName: "testimoni" */ '@/components/PageTestimoni')
const PageTraining = () => import(/* webpackChunkName: "prakerja" */ '@/components/PageTraining')
export default {
  name: 'homepage',
  components: {
    PageHeader,
    PageCore,
    PageSlideBanner,
    PageOurStrength,
    PageBusinessPartner,
    PageTraining,
    PageTestimoni
  },
  data: () => ({
    slideBannerPayload: [],
    corePayload: {},
    strengthPayload: {},
    businessPartnerPayload: {},
    trainingPayload: {},
    alumniPayload: [],
    youtubeLink: {},
    homepagePayload: {
      alumniHomePages: [
        {
          kelas: 'kelas',
          name: 'name',
          paragraph: 'paragraph',
          title: 'title',
          url: 'url'
        }
      ],
      cooperateNetHomePage: {
        cooperateNetItemHomePages: [
          {
            benefitHomePages: [
              {
                icon: 'icon',
                slug: 'slug'
              }
            ],
            title: 'title',
            url: 'url'
          }
        ],
        title: 'title'
      },
      ourStrengthHomePage: {
        description: 'description',
        name: 'name',
        ourStrengthItemHomePageRequests: [
          {
            description: 'description',
            name: 'name',
            title: 'title',
            url: 'url'
          }
        ],
        title: 'title'
      },
      seoDescription: 'seoDescription',
      slideBannerHomePages: [
        {
          name: 'Banner 1',
          slug: '/courses',
          title: 'Banner 1',
          url: 'url'
        }
      ],
      slug: 'slug',
      status: 'PUBLISH',
      subTitle: 'subTitle',
      title: 'title',
      trainingHomePageRequest: {
        title: 'title',
        trainingHomePageRequests: [
          {
            buttonCTA: 'buttonCTA',
            buttonSlug: 'buttonSlug',
            buttonType: 'buttonType',
            name: 'name',
            paragraph: 'paragraph',
            title: 'title',
            url: 'url'
          }
        ]
      },
      url: 'url',
      youTubeLinkHomePages: [
        {
          url: 'youtube bro'
        }
      ]
    }
  }),
  computed: {
    ...mapGetters(['homePageResponse'])
  },
  mounted() {
    this.getHomeContent()
  },
  methods: {
    ...mapActions(['getHomePageContent', 'saveHomePageContent', 'showLoading', 'hideLoading']),
    getHomeContent() {
      this.showLoading()
      this.getHomePageContent({
        success: res => {
          this.distributeHomepagePayload(res)
          this.hideLoading()
        }
      })
    },
    distributeHomepagePayload(res) {
      this.slideBannerPayload = res.slideBannerHomePages?.map(function(item) {
        delete item.id
        return item
      })
      this.corePayload = {
        slug: res.slug,
        statusId: res.status,
        subTitle: res.subTitle,
        title: res.title,
        url: res.url,
        seoDescription: res.seoDescription
      }
      delete res.ourStrengthHomePage.id
      this.strengthPayload = res.ourStrengthHomePage
      delete res.cooperateNetHomePage.id
      this.businessPartnerPayload = res.cooperateNetHomePage
      if (this.businessPartnerPayload.cooperateNetItemHomePages) {
        this.businessPartnerPayload.cooperateNetItemHomePages?.map(function(item) {
          delete item.id
          item.benefitHomePages?.map(function(element) {
            delete element.id
            return element
          })
          return item
        })
      } else {
        this.$set(this.businessPartnerPayload, 'cooperateNetItemHomePages', [])
        // this.businessPartnerPayload.cooperateNetItemHomePages = []
      }
      delete res.trainingHomePage.id
      this.trainingPayload = res.trainingHomePage
      this.trainingPayload.trainingItemHomePages?.map(function(item) {
        delete item.id
        return item
      })
      this.trainingPayload.trainingHomePageRequests = this.trainingPayload.trainingItemHomePages
      delete this.trainingPayload.trainingItemHomePages
      this.alumniPayload = res.alumniHomePages?.map(function(item) {
        delete item.id
        return item
      })
      delete res.youTubeLinkHomePages[0].id
      this.youtubeLink = res.youTubeLinkHomePages
    },
    saveHomepageData() {
      let self = this
      this.homepagePayload.slideBannerHomePages = this.slideBannerPayload
      this.homepagePayload.slug = this.corePayload.slug
      this.homepagePayload.statusId = this.corePayload.statusId
      this.homepagePayload.subTitle = this.corePayload.subTitle
      this.homepagePayload.title = this.corePayload.title
      this.homepagePayload.url = this.corePayload.url
      this.homepagePayload.seoDescription = this.corePayload.seoDescription
      this.homepagePayload.ourStrengthHomePage.title = this.strengthPayload.title
      this.homepagePayload.ourStrengthHomePage.ourStrengthItemHomePageRequests = this.strengthPayload.ourStrengthItemHomePageResponses
      this.homepagePayload.cooperateNetHomePage = this.businessPartnerPayload
      this.homepagePayload.trainingHomePageRequest = this.trainingPayload
      this.homepagePayload.alumniHomePages = this.alumniPayload
      this.homepagePayload.youTubeLinkHomePages = this.youtubeLink
      this.showLoading()
      this.saveHomePageContent({
        reqBody: self.homepagePayload,
        success: res => {
          this.$toast.success('Your Homepage Data is Successfully Updated!', {
            position: 'top'
          })
          this.hideLoading()
          this.getHomeContent()
        }
      })
    }
  }
}
