import ImageUploadMixin from '@/mixins/image-upload'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'class-description',
  mixins: [ImageUploadMixin],
  components: {
    quillEditor
  },
  data: () => ({
    descriptionMaxLength: 5000,
    desc: '',
    option: {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['clean']
        ]
      }
    }
  }),
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => {}
    },
    classTypeSelected: {
      type: String
    }
  },
  methods: {
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item) {
      this.$refs[item].click()
    }
  }
}
