import { mapActions } from 'vuex'
import { converter, notification, validator } from '@/util'
export default {
  name: 'detail-client',
  components: {
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/others/Modal'),
    PageTitle: () => import(/* webpackChunkName: "page-title" */ '@/components/others/PageTitle'),
    ButtonsGroup: () => import(/* webpackChunkName: "page-title" */ '@/components/button/ButtonsGroup'),
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/forms/InputWithLabel'),
    TextField: () => import(/* webpackChunkName: "input-with-label" */ '@/components/forms/TextField'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/components/forms/TextareaWithLabel'),
    Checkbox: () => import(/* webpackChunkName: "checkbox" */ '@/components/forms/Checkbox'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/forms/SelectWithLabel'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/button/Button'),
    Document: () => import(/* webpackChunkName: "Document" */ '@/components/icons/DocumentText'),
    Attachment: () => import(/* webpackChunkName: "Attachment" */ '@/components/icons/Attachment'),
    ButtonNewClass: () => import(/* webpackChunkName: "buttonNewClas" */ '@/components/button/ButtonNewClass')
  },
  data: () => ({
    mitraHistories: [],
    height: 335,
    note: '',
    date: ['', ''],
    id: ['', ''],
    companyName: ['', ''],
    name: ['', ''],
    email: ['', ''],
    phoneNumber: ['', ''],
    contacts: [],
    source: '',
    description: '',
    cooperationType: [
      {
        id: 'EDUCATION',
        label: 'Education',
        checked: false,
        disabled: true
      },
      {
        id: 'LMS',
        label: 'LMS & Prakerja',
        checked: false,
        disabled: true
      },
      {
        id: 'MANPOWER',
        label: 'Manpower',
        checked: false,
        disabled: true
      },
      {
        id: 'MARKETPLACE',
        label: 'Marketplace (Eduverse)',
        checked: false,
        disabled: true
      },
      {
        id: 'LP_CURATION',
        label: 'Curation',
        checked: false,
        disabled: true
      },
      {
        id: 'PROJECT',
        label: 'Project',
        checked: false,
        disabled: true
      }
    ],
    roles: [
      {
        id: 'ADMIN',
        label: 'LMS Admin',
        checked: false,
        disabled: true
      },
      {
        id: 'LP_CURATION',
        label: 'LP Curation',
        checked: false,
        disabled: true
      },
      {
        id: 'REGISTRANT',
        label: 'Talent Partner',
        checked: false,
        disabled: true
      }
    ],
    documents: [],
    resetRoles: null,
    isModalApprove: false,
    needApproval: false,
    restore: false,
    isModal: false,
    isView: true,
    mitraSource: [],
    mitraSourceResponse: [],
    mitraStatusList: [],
    selectedStatus: 'New',
    statusNotes: ''
  }),
  computed: {
    mitraStatus() {
      if (this.$route.params.category === 'active-clients') {
        return 'ACTIVE'
      } else if (this.$route.params.category === 'archived-clients') {
        return 'INACTIVE'
      }
    },
    mitraId() {
      return this.$route.params.id
    },
    forInput: function() {
      return [
        {
          label: 'Registration Date',
          readValue: this.date[0],
          writeValue: value => (this.date[1] = value),
          disabled: true
        },
        {
          label: 'Id',
          readValue: this.id[0],
          writeValue: value => (this.id[1] = value),
          disabled: true
        },
        {
          label: 'Company',
          readValue: this.companyName[0],
          writeValue: value => (this.companyName[1] = value),
          disabled: this.isView
        }
      ]
    },
    forTextarea: function() {
      return {
        label: 'Note',
        resize: 'resize-none',
        maxlength: 100,
        readValue: this.note,
        writeValue: value => (this.note = value)
      }
    },
    firstButtons: function() {
      return [
        {
          action: () => (this.needApproval ? this.showModalApprove() : this.changeStateIsView(false)),
          text: this.needApproval ? 'Approve' : 'Edit',
          size: 'small',
          hide: !this.isView
        },
        {
          action: () => this.showModal(),
          text: 'Archive',
          type: 'secondary',
          size: 'small',
          hide: !this.isView
        },
        {
          action: () => this.save(),
          text: 'Save',
          size: 'small',
          hide: this.isView
        },
        {
          action: () => this.changeStateIsView(true),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small',
          hide: this.isView
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.showModal(),
          text: 'Restore',
          hide: true
        },
        {
          action: () => this.showModal(),
          text: 'Restore',
          type: 'secondary',
          size: 'small'
        }
      ]
    },
    modalApproveButtons: function() {
      return [
        {
          action: () => this.hideModalApprove(),
          text: 'Cancel',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.approve(),
          text: 'Approve',
          type: 'tertiary',
          size: 'small'
        }
      ]
    },
    firstModalButtons: function() {
      return [
        {
          action: () => this.resetNote(),
          text: 'Cancel',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.changeStatusClient(),
          text: this.restore ? 'Restore' : 'Archive',
          type: 'tertiary',
          size: 'small',
          disabled: this.note === ''
        }
      ]
    },
    secondModalButtons: function() {
      return [
        {
          action: () => this.specialHideModal(),
          text: 'Cancel',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.updateRoles(),
          text: 'Update',
          type: 'tertiary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    isView: function(value) {
      value ? (this.height = 335) : (this.height = 210)
      this.cooperationType.forEach(element => (element.disabled = value))
      this.roles.forEach(element => (element.disabled = value))
    }
  },
  mounted() {
    this.getMitraById(true)
    this.getMitraSource()
    this.getAllHistory()
    this.getMitraStatus()
  },
  methods: {
    ...mapActions([
      'getMitraDetail',
      'getAllSource',
      'getAllStatus',
      'getMitraHistories',
      'updateMitraStatus',
      'getClientDocuments',
      'approveClient',
      'updateStatusClient',
      'editMitraRoles',
      'editMitra',
      'showLoading',
      'hideLoading'
    ]),
    getMitraById(full) {
      this.showLoading()
      this.getMitraDetail({
        id: this.$route.params.id,
        success: res => this.getAllDocuments(res, full),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
          this.restore ? this.$router.push({ path: '/archived-clients' }) : this.$router.push({ path: '/active-clients' })
        }
      })
    },
    getAllHistory() {
      this.getMitraHistories({
        mitraStatus: this.mitraStatus,
        mitraID: this.mitraId,
        params: {
          page: 0,
          size: 50,
          sortBy: 'createdDate',
          direction: 'DESC'
        },
        success: res => {
          this.mitraHistories = res.data
        },
        failed: () => {}
      })
    },
    getMitraSource() {
      this.getAllSource({
        params: {
          sortBy: 'sourceName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraSource.push({
              id: item.source_name,
              name: item.source_name
            })
          })
          this.mitraSourceResponse = res
        }
      })
    },
    getMitraStatus() {
      this.getAllStatus({
        params: {
          sortBy: 'stageName',
          direction: 'ASC'
        },
        success: res => {
          res.forEach(item => {
            this.mitraStatusList.push({
              id: item.id,
              name: item.stage_name
            })
          })
          this.selectedStatus = res.filter(item => item.stage_name === 'New')[0]?.id
        }
      })
    },
    getAllDocuments(res, full) {
      this.getClientDocuments({
        id: this.$route.params.id,
        success: docs => this.distributePayload(res, full, docs),
        failed: () => {
          notification.errorMessage('Failed to load data, please try again later!')
          this.hideLoading()
          this.restore ? this.$router.push({ path: '/archived-clients' }) : this.$router.push({ path: '/active-clients' })
        }
      })
    },
    async distributePayload(res, full, docs) {
      if (full) {
        this.date = [converter.dateTimeView(res.createdDate), '']
        this.id = [res.id, '']
        this.companyName = [res.companyName, '']
        this.source = res.source
        this.description = res.description || ''
        this.name = [res.picName, '']
        this.email = [res.picEmail, '']
        this.phoneNumber = [res.phoneNumber, '']
        this.needApproval = res.needApproval
        res.status === 'ACTIVE' ? (this.restore = false) : (this.restore = true)
        this.contacts = []
        res.contactResponses.forEach(contact => {
          this.contacts.push({
            id: contact.id,
            picName: contact.picName,
            picEmail: contact.picEmail,
            phoneNumber: contact.phoneNumber,
            primaryContact: contact.primaryContact
          })
        })
        this.documents = []
        docs.forEach(doc => {
          this.documents.push({
            firstText: doc.name,
            secondText: doc.count,
            disabled: doc.count < 1
          })
        })
        await this.cooperationType.forEach(element => (element.checked = false))
        for (let i = 0; i < this.cooperationType.length; i++) {
          for (let j = 0; j < res.mitraKerjasamas.length; j++) {
            if (this.cooperationType[i].id === res.mitraKerjasamas[j].name) this.cooperationType[i].checked = true
          }
        }
      }
      await this.roles.forEach(element => (element.checked = false))
      for (let k = 0; k < this.roles.length; k++) {
        for (let l = 0; l < res.roles.length; l++) {
          if (this.roles[k].id === res.roles[l]) this.roles[k].checked = true
        }
      }
      this.resetRoles = res.roles
      this.hideLoading()
    },
    showModalApprove() {
      this.isModalApprove = true
    },
    hideModalApprove() {
      this.isModalApprove = false
    },
    approve() {
      this.showLoading()
      this.approveClient({
        id: this.$route.params.id,
        success: () => {
          notification.successMessage('Approve client successfully!')
          this.hideModalApprove()
          this.getMitraById(true)
        },
        failed: () => notification.errorMessage('Approve client failed, please try again later!')
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    formatDate(value) {
      return converter.dateTimeView(value)
    },
    changeStatusClient() {
      this.showLoading()
      this.updateStatusClient({
        id: this.$route.params.id,
        params: { status: this.restore },
        requestBody: { notes: this.note },
        success: () => {
          notification.successMessage(`${this.restore ? 'Restore' : 'Archive'} client successfully!`)
          this.$router.push({ path: `/${this.restore ? 'archived' : 'active'}-clients` })
        },
        failed: () => notification.errorMessage(`${this.restore ? 'Restore' : 'Archive'} client failed, please try again later!`)
      })
      setTimeout(() => this.hideLoading(), 3500)
    },
    async updateRoles() {
      this.showLoading()
      let roles = []
      await this.roles.forEach(element => {
        if (element.checked) roles.push(element.id)
      })
      if (roles.length < 1) {
        notification.errorMessage('Please select roles at least 1 role!')
      } else {
        this.editMitraRoles({
          data: { id: this.$route.params.id, payload: { roles } },
          success: () => {
            notification.successMessage('Update client roles successfully!')
            this.getMitraById(false)
            this.isModal = false
          },
          failed: () => notification.errorMessage('Update client roles failed, please try again later!')
        })
      }
      setTimeout(() => this.hideLoading(), 3500)
    },
    async save() {
      let nameEdit = ''
      let emailEdit = ''
      let phoneEdit = ''
      await this.contacts.forEach((item) => {
        if (item.primaryContact) {
          nameEdit = item.picName
          emailEdit = item.picEmail
          phoneEdit = item.phoneNumber
        }
      })
      let payload = {
        companyName: this.companyName[1],
        picName: nameEdit,
        picEmail: emailEdit,
        phoneNumber: phoneEdit,
        contacts: this.contacts,
        description: this.description,
        sourceId: this.mitraSourceResponse.filter(item => item.source_name === this.source)[0]?.id
      }
      let cooperationType = []
      await this.cooperationType.forEach(element => {
        if (element.checked) cooperationType.push({ name: element.id })
      })
      payload.mitraKerjasamas = cooperationType
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      for (let i = 0; i < payload.contacts.length; i++) {
        const contact = payload.contacts[i]
        if (contact.picName === '') {
          notification.errorMessage('Name is required!')
          return
        } else if (!emailPattern.test(contact.picEmail)) {
          notification.errorMessage('Incorrect email format!')
          return
        } else if (contact.phoneNumber === '') {
          notification.errorMessage('Phone number is required!')
          return
        } else if (validator.phone(contact.phoneNumber)) {
          notification.errorMessage('Phone number is invalid!')
          return
        }
      }
      if (this.companyName[1] === '') {
        notification.errorMessage('Company name is required!')
      } else if (cooperationType.length < 1) {
        notification.errorMessage('Choose cooperation type at least 1 type!')
      } else {
        this.showLoading()
        this.editMitra({
          id: this.$route.params.id,
          payload,
          success: () => {
            this.hideLoading()
            notification.successMessage('Update client profile successfully!')
            // this.$router.push({ path: '/active-clients' })
            this.changeStateIsView(true)
          },
          failed: () => {
            notification.errorMessage('Update client profile failed!')
            this.hideLoading()
            this.changeStateIsView(true)
          }
        })
      }
    },
    updateStatus() {
      this.showLoading()
      this.updateMitraStatus({
        mitraStatus: this.mitraStatus,
        mitraID: this.mitraId,
        payload: {
          new_stage_id: this.selectedStatus,
          new_stage_notes: this.statusNotes
        },
        success: () => {
          notification.successMessage('Update client status successfully!')
          this.getAllHistory()
          this.statusNotes = ''
          this.hideLoading()
        },
        failed: () => {
          this.hideLoading()
          notification.errorMessage('Update client status failed!')
        }
      })
    },
    async changeStateIsView(value) {
      if (await value) this.getMitraById(true)
      this.isView = !this.isView
    },
    resetNote() {
      this.note = ''
      this.generalHideModal()
    },
    showModal() {
      this.isModal = true
    },
    generalHideModal() {
      this.isModal = false
    },
    async specialHideModal() {
      await this.roles.forEach(element => (element.checked = false))
      for (let k = 0; k < this.roles.length; k++) {
        for (let l = 0; l < this.resetRoles.length; l++) {
          if (this.roles[k].id === this.resetRoles[l]) this.roles[k].checked = true
        }
      }
      this.isModal = false
    },
    toPage(name) {
      if (name === 'Archived Clients') {
        this.$router.push('/archived-clients')
      } else {
        this.$router.push('/active-clients')
      }
    },
    viewDocumentList() {
      this.$router.push(`${this.$route.fullPath}/documents`)
    },
    toDocumentList(type) {
      const forType = type.split('/').join('-')
      const documentType = forType.split(' ').join('-')
      this.$router.push({ path: `/${this.$route.params.category}/detail-client/${this.$route.params.id}/documents/${documentType}` })
    },
    changeCheck(id, type) {
      const companyName = this.companyName[1]
      const name = this.name[1]
      const phoneNumber = this.phoneNumber[1]
      this.companyName = null
      this.name = null
      this.phoneNumber = null
      this.companyName = [companyName, companyName]
      this.name = [name, name]
      this.phoneNumber = [phoneNumber, phoneNumber]
      switch (type) {
        case 'roles':
          for (let i = 0; i < this.roles.length; i++) {
            if (id === this.roles[i].id) {
              this.roles[i].checked = !this.roles[i].checked
              this.isModal = true
            }
          }
          break
        case 'cooperationType':
          for (let j = 0; j < this.cooperationType.length; j++) {
            if (id === this.cooperationType[j].id) this.cooperationType[j].checked = !this.cooperationType[j].checked
          }
          break
        case 'primaryContact':
          let count = 0
          for (let k = 0; k < this.contacts.length; k++) {
            if (id === this.contacts[k].id) this.contacts[k].primaryContact = !this.contacts[k].primaryContact
            if (this.contacts[k].primaryContact) count++
            if (count > 1) {
              notification.errorMessage('Please select only one Primary Contact!')
              break
            }
          }
      }
    },
    addContact() {
      let item = {
        picName: '',
        picEmail: '',
        phoneNumber: '',
        primaryContact: false
      }
      this.contacts.push(item)
    },
    deleteContact(index) {
      this.contacts.splice(index, 1)
    },
    updateContactField(index, field, value) {
      this.contacts[index][field] = value;
    }
  }
}
