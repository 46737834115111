import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {
  userData: {
    token: null
  },
  refreshPage: 0,
  disable: false,
  isLoading: false
}
const actions = {
  checkServer: ({ commit }) => Vue.http.get(`${config.api.checkServer}/`),
  checkServerWithResponse: ({ commit }, { success, failed }) => {
    Vue.http.get(`${config.api.checkServer}/`).then(
      response => {
        if (response.body.code === 200) {
          success && success(response.body.data)
        } else {
          failed && failed(response.body)
        }
      },
      response => {
        failed && failed(response)
      }
    )
  },
  getCurrentUser: ({ commit }) => {
    const data = {
      token: localStorage.getItem('access_token'),
      name: localStorage.getItem('user_name'),
      role: localStorage.getItem('user_role'),
      team: localStorage.getItem('user_team'),
      id: localStorage.getItem('user_id'),
      user: JSON.parse(localStorage.getItem('user'))
    }
    commit('setUserData', data)
  },
  removeUserData: ({ commit }) => {
    localStorage.clear()
    commit('setUserData', { token: null })
  },
  setRefreshPage: ({ commit }, { value }) => {
    commit('setRefreshPage', value)
  },
  login: ({ commit }, { reqBody, success, failed }) => {
    Vue.http.post(config.api.user.login, reqBody).then(
      response => {
        if (response.body.code === 200) {
          success && success(response.body.data)
          commit('setUserData', response.body.data)
        } else {
          failed && failed(response.body)
        }
      },
      response => {
        failed && failed(response)
      }
    )
  },
  changePassword: ({ commit }, { reqBody, success, failed }) => {
    Vue.http
      .post(config.api.user.changePassword, reqBody, {
        headers: config.getAuthHeader()
      })
      .then(
        response => {
          if (response.body.code === 200) {
            success && success(response.body.data)
            // commit('setUserData', response.body.data)
          } else {
            failed && failed(response.body)
          }
        },
        response => {
          failed && failed(response)
        }
      )
  },
  getUploadUrl: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.baseV4}/uploads` + serializeQueryParams(params))
      .then(response => {
        success && success(response.body)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateUploadFile: ({ commit }, { url, data, contentType, success, failed }) => {
    Vue.http
      .put(url, data, { headers: { 'Content-Type': `${contentType}` } })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  uploadImageAsset: ({ commit }, { data, success, failed }) => {
    Vue.http
      .post(config.api.image, data, {
        headers: config.getAuthAndMultipartHeader()
      })
      .then(
        response => {
          success && success(response.body)
        },
        response => {
          failed && failed(response)
        }
      )
  },
  showLoading: ({ commit }) => {
    commit('showLoading')
  },
  hideLoading: ({ commit }) => {
    commit('hideLoading')
  }
}
const mutations = {
  setDisableStatus(state, value) {
    state.disable = value
  },
  showLoading(state) {
    state.isLoading = true
  },
  hideLoading(state) {
    state.isLoading = false
  },
  setUserData(state, value) {
    state.userData = value
  },
  setRefreshPage(state, value) {
    state.refreshPage = value
  }
}
const getters = {
  getDisable: state => {
    return state.disable
  },
  isLoading: state => {
    return state.isLoading
  },
  userData: state => {
    return state.userData
  },
  isLoggedIn: state => {
    return state && state.userData ? state.userData.token !== null : false
  },
  refreshPage: state => {
    return state.refreshPage
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
