<template>
  <main class="relative" style="background: rgba(203, 203, 210, 0.15); font-family: 'Montserrat';">
    <TheHeader />
    <TheSideBar style="height: calc(100vh - 3.5rem);" class="absolute" />
    <div style="max-height: calc(100vh - 3.5rem);" class="content ml-64 bg-neutral-100 overflow-y-auto">
      <div style="min-height: calc(100vh - 3.5rem);" class="p-4">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <TheFooter />
    </div>
    <Loading v-if="isLoading" />
  </main>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ '@/components/illustration/Loading'),
    TheHeader: () => import('@/components/layout/TheHeader'),
    TheSideBar: () => import('@/components/layout/TheSideBar'),
    TheFooter: () => import('@/components/layout/TheFooter')
  },
  computed: {
    ...mapGetters(['isLoading'])
  }
}
</script>
