<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.25C6.61547 2.25 2.25 6.61547 2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M12 2.25C9.278 2.25 6.71863 6.61547 6.71863 12C6.71863 17.3845 9.278 21.75 12 21.75C14.7221 21.75 17.2814 17.3845 17.2814 12C17.2814 6.61547 14.7221 2.25 12 2.25Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M5.49988 5.49984C7.29238 6.7725 9.54894 7.5314 12 7.5314C14.4511 7.5314 16.7077 6.7725 18.5002 5.49984M18.5002 18.5002C16.7077 17.2275 14.4511 16.4686 12 16.4686C9.54894 16.4686 7.29238 17.2275 5.49988 18.5002"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M21.75 12H2.25M12 2.25V21.75V2.25Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
