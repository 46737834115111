import Vue from 'vue'
import store from '@/store'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

const notification = {
  defaultMessage: message => {
    store.commit('setDisableStatus', true)
    return Vue.$toast.open({
      message,
      position: 'top',
      type: 'default',
      onDismiss: () => {
        store.commit('setDisableStatus', false)
      }
    })
  },
  errorMessage: message => {
    store.commit('setDisableStatus', true)
    return Vue.$toast.open({
      message,
      position: 'top',
      type: 'error',
      onDismiss: () => {
        store.commit('setDisableStatus', false)
      }
    })
  },
  infoMessage: message => {
    store.commit('setDisableStatus', true)
    return Vue.$toast.open({
      message,
      position: 'top',
      type: 'info',
      onDismiss: () => {
        store.commit('setDisableStatus', false)
      }
    })
  },
  successMessage: (message, duration = 3000) => {
    store.commit('setDisableStatus', true)
    return Vue.$toast.open({
      message,
      position: 'top',
      type: 'success',
      duration: duration,
      onDismiss: () => {
        store.commit('setDisableStatus', false)
      }
    })
  },
  warningMessage: message => {
    store.commit('setDisableStatus', true)
    return Vue.$toast.open({
      message,
      position: 'top',
      type: 'warning',
      onDismiss: () => {
        store.commit('setDisableStatus', false)
      }
    })
  }
}

export default notification
