import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=2a3326da&scoped=true&"
import script from "./js/order-details.js?vue&type=script&lang=js&"
export * from "./js/order-details.js?vue&type=script&lang=js&"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=2a3326da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a3326da",
  null
  
)

export default component.exports