import { render, staticRenderFns } from "./DetailClient.vue?vue&type=template&id=6144baa8&scoped=true&"
import script from "./js/detail-client.js?vue&type=script&lang=js&"
export * from "./js/detail-client.js?vue&type=script&lang=js&"
import style0 from "./DetailClient.vue?vue&type=style&index=0&id=6144baa8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6144baa8",
  null
  
)

export default component.exports