/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import VueResource from 'vue-resource'
import config from '@/config'
import { serializeQueryParams } from '@/util/query'

Vue.use && Vue.use(VueResource)

const state = {
  mitraList: {},
  mitraSource: [],
  mitraStatus: [],
  mitraDetail: {},
  mitraHistories: []
}

const mutations = {
  setMitraList(state, value) {
    state.mitraList = value
  },
  setMitraSource(state, value) {
    state.mitraSource = value
  },
  setMitraStatus(state, value) {
    state.mitraStatus = value
  },
  setMitraDetail(state, value) {
    state.mitraDetail = value
  },
  setMitraHistories(state, value) {
    state.mitraHistories = value
  }
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  getAllMitra: ({ commit }, { type, params, success, failed }) => {
    Vue.http
      .get(`${config.api.mitraV2}/cms/mitra/${type}/client-list` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setMitraList', response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getMitraHistories: ({ commit }, { mitraStatus, mitraID, params, success, failed }) => {
    Vue.http
      .get(`${config.api.mitraV2}/cms/mitra/${mitraStatus}/client/${mitraID}/histories` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setMitraHistories', response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateMitraStatus: ({ commit }, { mitraStatus, mitraID, payload, success, failed }) => {
    Vue.http
      .put(`${config.api.mitraV2}/cms/mitra/${mitraStatus}/client/${mitraID}/histories`, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  createMitra: ({ _ }, { payload, success, failed }) => {
    Vue.http
      .post(`${config.api.mitraV2}/cms/mitra/ACTIVE/create`, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getAllSource: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.mitraV2}/cms/mitra/all-sources` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setMitraSource', response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  importClient: ({ _ }, { payload, success, failed }) => {
    Vue.http
      .post(`${config.api.mitraV2}/cms/mitra/ACTIVE/import`, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getAllStatus: ({ commit }, { params, success, failed }) => {
    Vue.http
      .get(`${config.api.mitraV2}/cms/mitra/all-stages` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setMitraStatus', response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getMitraDetail: ({ commit }, { id, success, failed }) => {
    Vue.http
      .get(`${config.api.mitra}/mitra/` + id, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
        commit('setMitraDetail', response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getAllClientDocuments: ({ commit }, { id, params, success, failed }) => {
    Vue.http
      .get(`${config.api.partnerRegistration}/${id}` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getClientDocuments: ({ commit }, { id, success, failed }) => {
    Vue.http
      .get(`${config.api.partnerRegistration}/list-type/${id}`, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getDocumentTypes: ({ commit }, { success, failed }) => {
    Vue.http
      .get(`${config.api.registrationDocuments}`, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  uploadDocument: ({ _ }, { mitraId, payloads, success, failed }) => {
    Vue.http
      .post(`${config.api.mitraDocument(mitraId)}`, payloads, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  deleteDocument: ({ _ }, { mitraId, params, success, failed }) => {
    Vue.http
      .delete(`${config.api.mitraDocument(mitraId)}` + serializeQueryParams(params), {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateExpiredDate: ({ _ }, { mitraId, payloads, success, failed }) => {
    Vue.http
      .put(`${config.api.mitraDocument(mitraId)}`, payloads, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateClientDocument: ({ commit }, { id, regDocId, requestBody, success, failed }) => {
    Vue.http
      .post(`${config.api.partnerRegistration}/${id}/${regDocId}/replace-document`, requestBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateDocumentStatus: ({ commit }, { id, regDocId, params, success, failed }) => {
    Vue.http
      .put(
        `${config.api.partnerRegistration}/${id}/${regDocId}/status` + serializeQueryParams(params),
        {},
        {
          headers: config.getAuthHeader()
        }
      )
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getDetailDocument: ({ commit }, { id, documentId, success, failed }) => {
    Vue.http
      .get(`${config.api.partnerRegistration}/${id}/${documentId}`, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  getHistoryDocument: ({ commit }, { id, regDocId, success, failed }) => {
    Vue.http
      .get(`${config.api.partnerRegistration}/${id}/registration-documents/${regDocId}/histories`, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  updateStatusClient: ({ commit }, { id, params, requestBody, success, failed }) => {
    Vue.http
      .put(`${config.api.mitra}/mitra/${id}/is-active` + serializeQueryParams(params), requestBody, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  approveClient: ({ commit }, { id, success, failed }) => {
    Vue.http
      .put(
        `${config.api.mitra}/mitra/${id}/approve`,
        {},
        {
          headers: config.getAuthHeader()
        }
      )
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  editMitra: ({ commit }, { id, payload, success, failed }) => {
    Vue.http
      .put(`${config.api.mitra}/mitra/${id}`, payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  },
  editMitraRoles: ({ commit }, { data, success, failed }) => {
    Vue.http
      .put(`${config.api.mitra}/mitra/${data.id}/roles`, data.payload, {
        headers: config.getAuthHeader()
      })
      .then(response => {
        success && success(response.body.data)
      })
      .catch(response => {
        failed && failed(response)
      })
  }
}

const getters = {
  mitraSource: state => state.mitraSource
}

export default {
  state,
  mutations,
  actions,
  getters
}
